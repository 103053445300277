import RefinementListSelect from '../../common/RefinementListSelect'
import MenuSelect from '../../../entries/MenuSelect'
import NumericMenu from '../../common/NumericMenu'
import { LabeledInput, LabeledSelect, LabeledCheckbox } from '../../common'
import { SortBy, HitsPerPage, RangeInput, ClearRefinements } from 'react-instantsearch'
import { sortingOptions } from '../../../cars/config'
import { bootstrapColours } from '../../common'

const colorOptions = ['none', 'primary', 'secondary', 'light', 'dark']

export const CustomisationsAndSorting = ({ state, syncedStateUpdate, templateType }) => (
  <div className={`${templateType === 'search' ? 'd-none' : ''} pt-3`}>
    <h6>Customisations & Sorting</h6>
    {/* This px-1 makes the row properly align. Something to do with the checkbox styling. */}
    <div className="row mt-3 px-1">
      <div className="col-6 col-lg-4">
        <div className="pl-3">
          <LabeledCheckbox
            controlled={false}
            item={state}
            itemName="hideSearch"
            label="Hide Search Input"
            updateItem={syncedStateUpdate}
          />
        </div>
      </div>
      <div className="col-6 col-lg-4">
        <div className="pl-3">
          <LabeledCheckbox
            controlled={false}
            item={state}
            itemName="hideButton"
            label="Hide View Cars Button"
            updateItem={syncedStateUpdate}
          />
        </div>
      </div>
    </div>
    <div className="row">
      {state.heading && (
        <div className="col-md-6">
          <LabeledInput
            controlled={false}
            item={state}
            itemName="heading"
            label="Heading"
            updateItem={syncedStateUpdate}
          />
        </div>
      )}
      {state.background && (
        <div className="col-md-6">
          <LabeledSelect
            controlled={false}
            item={state}
            itemName="background"
            label="Background Color"
            updateItem={syncedStateUpdate}
            options={Array.from(colorOptions)}
          />
        </div>
      )}
      <div className="col-md-6">
        <LabeledSelect
          controlled={false}
          item={state}
          itemName="buttonColor"
          label="Button Color"
          updateItem={syncedStateUpdate}
          options={Array.from(bootstrapColours)}
        />
      </div>
    </div>

    <div className="row">
      <div className="col">
        <p className="mb-2"># Cars Per Page</p>
        <HitsPerPage
          items={[
            { value: 3, label: 'Show 3 cars', default: true },
            { value: 6, label: 'Show 6 cars' },
            { value: 9, label: 'Show 9 cars' },
            { value: 12, label: 'Show 12 cars' },
            { value: 15, label: 'Show 15 cars' },
            { value: 18, label: 'Show 18 cars' },
          ]}
        />
      </div>
      <div className="col">
        <p className="mb-2">Sorting Type</p>
        <SortBy items={sortingOptions} />
      </div>
    </div>
  </div>
)

// !MULTISELECT
export const MultiSelectFilters = ({ dateFilter, onDateFilterChange, dateOptions }) => (
  <>
    <div className="row">
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="car_type" placeholder="Car Types..." />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="make" placeholder="Makes..." limit={60} />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="model" placeholder="Models..." limit={60} />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="badge" placeholder="Badges..." limit={60} />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect
          attribute="simple_body"
          placeholder="Bodies..."
          buttonText="Body"
          limit={60}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="tag" placeholder="Tags..." />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="location_name" placeholder="Locations..." limit={60} />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect
          attribute="carsales_account"
          placeholder="Carsales Accounts..."
          limit={60}
        />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="simple_fuel" placeholder="Fuel Type..." />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <MenuSelect attribute="certified" seeAllOption="Certified" />
      </div>
      {dateFilter && (
        <div className="col-12 col-md-6 col-lg-4 mb-3">
          <NumericMenu
            dateFilter={dateFilter}
            setDateFilter={onDateFilterChange}
            dateOptions={dateOptions}
          />
        </div>
      )}
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="status" />
      </div>
    </div>
    <div className="row">
      <div className="col-12 mb-3 d-flex align-items-end">
        <div className="mr-2">
          <p className="mb-1">Kilometers Range</p>
          <RangeInput
            attribute="km"
            classNames={{
              label: 'mb-0',
            }}
            translations={{
              separatorElementText: '-',
              submitButtonText: 'Apply',
            }}
          />
        </div>
        <ClearRefinements includedAttributes={['km']} translations={{ resetButtonText: 'Reset' }} />
      </div>
      <div className="col-12 d-flex align-items-end">
        <div className="mr-2">
          <p className="mb-1">Year Range</p>
          <RangeInput
            attribute="year"
            classNames={{
              label: 'mb-0',
            }}
            translations={{
              separatorElementText: '-',
              submitButtonText: 'Apply',
            }}
          />
        </div>
        <ClearRefinements
          includedAttributes={['year']}
          translations={{ resetButtonText: 'Reset' }}
        />
      </div>
    </div>
  </>
)

// !SINGLESELECT
export const DefaultFilters = ({ data, dateFilter, onDateFilterChange, dateOptions }) => (
  <>
    <div className="row">
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="car_type" />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <MenuSelect attribute="make" seeAllOption="All Makes" />
      </div>
      {/* I need to migrate from MenuSelect to RefinementListSelect for models */}
      {/* This is to allow a user to select multiple models, rather than just one */}
      {/* However the issue is supporting backwards compatibility */}
      <div className={data.searchState?.menu?.model ? 'col-12 col-md-6 col-lg-4 mb-3' : 'd-none'}>
        <MenuSelect attribute="model" seeAllOption="All Models" />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <RefinementListSelect attribute="model" />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <MenuSelect attribute="badge" seeAllOption="All Badges" />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <MenuSelect attribute="simple_body" seeAllOption="All Bodies" />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <MenuSelect attribute="tag" seeAllOption="All Tags" />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <MenuSelect attribute="location_name" seeAllOption="All Locations" />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <MenuSelect attribute="carsales_account" seeAllOption="All Carsales Accounts" />
      </div>
      <div className="col-12 col-md-6 col-lg-4 mb-3">
        <MenuSelect attribute="simple_fuel" seeAllOption="All Fuel Types" />
      </div>
      {dateFilter && (
        <div className="col-12 col-md-6 col-lg-4 mb-3">
          <NumericMenu
            dateFilter={dateFilter}
            setDateFilter={onDateFilterChange}
            dateOptions={dateOptions}
          />
        </div>
      )}
    </div>
  </>
)
