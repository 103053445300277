import { capitalise } from '../../entries/utils'
import Tooltip, { TooltipVariants } from './Tooltip'
import { Info } from './Icons'
import { generateRandomId } from './Utils'

const InputLabel = ({ label, labelClassname, htmlFor, itemName, tooltip }) => (
  <div className="d-flex align-items-center mb-2">
    <label htmlFor={htmlFor ?? itemName} className={labelClassname ?? 'form-label mb-0'}>
      {label ? label : capitalise(itemName)}
    </label>
    {tooltip &&
      // Allow multiple tooltips to be passed in as an array
      (Array.isArray(tooltip) ? (
        tooltip.map((tooltipVal, i) => {
          if (tooltipVal === undefined) return
          return (
            <Tooltip
              key={`tooltip-${i}-${generateRandomId(3)}`}
              width={tooltipVal?.width}
              tooltipTrigger={
                tooltipVal?.trigger ?? (
                  <div
                    className="d-inline-flex justify-content-center align-items-center text-primary ml-1"
                    style={{ cursor: 'pointer' }}
                  >
                    <Info
                      size="20px"
                      stroke={
                        tooltipVal?.variant ? TooltipVariants[tooltipVal?.variant] : 'currentColor'
                      }
                    />
                  </div>
                )
              }
              tooltipContent={tooltipVal?.content}
              variant={tooltipVal?.variant}
            />
          )
        })
      ) : (
        <Tooltip
          width={tooltip?.width}
          tooltipTrigger={
            tooltip?.trigger ?? (
              <div
                className="d-inline-flex justify-content-center align-items-center text-primary ml-1"
                style={{ cursor: 'pointer' }}
              >
                <Info
                  size="20px"
                  stroke={tooltip?.variant ? TooltipVariants[tooltip?.variant] : 'currentColor'}
                />
              </div>
            )
          }
          tooltipContent={tooltip?.content}
          variant={tooltip?.variant}
        />
      ))}
  </div>
)

export default InputLabel
