import { FC, useEffect, useRef } from 'react'
import { API } from '@editorjs/editorjs'
import { JSONEditor, JSONEditorPropsOptional } from 'vanilla-jsoneditor'
import { handleEmbeddedEditorActions } from './Utils'

// React wrapper for the JSON Editor
const JSONEditorReact: FC<JSONEditorPropsOptional & { id?: string; editorApi: API }> = ({
  editorApi,
  ...props
}) => {
  const refContainer = useRef<HTMLDivElement>(null)
  const refEditor = useRef<JSONEditor | null>(null)

  useEffect(() => {
    // create editor
    refEditor.current = new JSONEditor({
      target: refContainer.current!,
      props: {},
    })

    // Prevent events bubbling up to the parent editor
    handleEmbeddedEditorActions(refContainer.current, editorApi)

    // destroy editor
    return () => {
      if (refEditor.current) {
        refEditor.current.destroy()
        refEditor.current = null
      }
    }
  }, [])

  useEffect(() => {
    // update props
    if (refEditor.current) {
      refEditor.current?.updateProps(props)
    }
  }, [props, refEditor])

  return <div ref={refContainer} />
}

export default JSONEditorReact
