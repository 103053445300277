const GridCardSection = ({
  className = 'mt-5 container pt-5 pb-3',
  heading,
  description,
  rowClass = 'row d-flex align-items-stretch justify-content-around',
  children,
}) => (
  <section className={className}>
    <div className="row">
      <div className="mx-auto col-lg-10">
        {heading && <h3 className="text-center mb-3">{heading}</h3>}
        {description && <p className="text-center mx-lg-4">{description}</p>}
      </div>
    </div>
    <div className={rowClass}>{children}</div>
  </section>
)

export default GridCardSection
