import { default as React, useCallback, useState } from 'react'
import { createRoot } from 'react-dom/client'

import Dialog from '../../common/Dialog'

import { ItemOption, LabeledInput, LabeledSelect, EmptyPlaceHolder, ToolHeader } from '../../common'
import { renderEditSettingsButton, renderHiddenModalButton } from '../../common/Utils'
import { useActions } from '../../common/ActionButtons'
import { unique } from '../../../entries/utils'

import { IconButtonPreview } from './IconButtonsPreview'
import { acceptedIcons, defaultSetup, defaultItem } from './utils'

export const IconButtonEditor = ({
  index,
  item,
  updateItem,
  deleteItem,
  moveUp,
  numItems,
  state,
}) => {
  return (
    <div className={`${index === numItems - 1 ? '' : 'mb-3'}`}>
      <ItemOption
        index={index}
        item={item}
        title="Button"
        moveUp={moveUp}
        deleteItem={numItems > 1 ? deleteItem : undefined} // Don't allow users to have an instance of the tool with no buttons
        hidePreview={true}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12">
                <LabeledSelect
                  item={item}
                  itemName="icon"
                  label="Button Icon"
                  options={acceptedIcons.map((obj) => obj.name)}
                  updateItem={updateItem}
                />
              </div>
              <div className="col-12">
                <LabeledInput
                  item={item}
                  itemName="text"
                  label="Subtitle"
                  updateItem={updateItem}
                />
              </div>
              <div className="col-12">
                <LabeledInput
                  item={item}
                  itemName="href"
                  label="Link URL"
                  updateItem={updateItem}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center justify-content-center">
              <div className="w-100">
                <div
                  className="d-flex flex-column align-items-center justify-content-center text-center"
                  key={item.id}
                >
                  <IconButtonPreview state={state} item={item} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ItemOption>
    </div>
  )
}

const RenderedIconButtonsComponent = ({
  data,
  toolInfo,
  onDataChange,
  getUpdatedData,
  uniqueId,
}) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState(data)
  const [items, setItems] = useState(data.buttons || [])

  const syncedStateUpdate = useCallback(
    (updatedState) => {
      setState({
        type: updatedState.type,
        style: updatedState.style,
        buttonsPerRow: updatedState.buttonsPerRow,
        horizontalAlignment: updatedState.horizontalAlignment,
        buttons: [...getUpdatedData().buttons],
      })
      onDataChange({
        type: updatedState.type,
        style: updatedState.style,
        buttonsPerRow: updatedState.buttonsPerRow,
        horizontalAlignment: updatedState.horizontalAlignment,
        buttons: [...getUpdatedData().buttons],
      })
    },
    [onDataChange]
  )

  const syncedItemsUpdate = useCallback(
    (updatedItems) => {
      setItems([...updatedItems])
      onDataChange({
        ...getUpdatedData(),
        buttons: [...updatedItems],
      })
    },
    [onDataChange]
  )

  const actions = useActions({
    items,
    defaultItem,
    syncedUpdate: syncedItemsUpdate,
  })

  return (
    <>
      <div
        className={`row ${
          state.type === 'minimal'
            ? `justify-content-${state.horizontalAlignment ?? 'start'} align-items-start`
            : 'justify-content-center align-items-center'
        }`}
      >
        {items?.map((item) => (
          <div
            className={`${
              state.type === 'minimal'
                ? `col-12 col-md-6 col-lg-${
                    state.buttonsPerRow && state.buttonsPerRow !== '' ? state.buttonsPerRow : '3'
                  }`
                : 'col-6 col-lg-3'
            } mb-3`}
            key={item.id}
          >
            <IconButtonPreview state={state} item={item} />
          </div>
        ))}
      </div>
      <Dialog title="CTA Buttons" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} addItem={items.length < 4 ? actions.addItem : undefined} />
        <div className="row">
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={state}
              itemName="type"
              label="Icon Button Type"
              updateItem={syncedStateUpdate}
              options={[
                { label: 'Default', value: 'default' },
                { label: 'Minimal', value: 'minimal' },
              ]}
            />
          </div>
          {state.type === 'minimal' && (
            <>
              <div className="col-12 col-md-6">
                <LabeledSelect
                  item={state}
                  itemName="style"
                  label="Icon Button Style"
                  updateItem={syncedStateUpdate}
                  options={[
                    { label: 'Fill', value: 'fill' },
                    { label: 'Outline', value: 'outline' },
                  ]}
                />
              </div>
              <div className="col-12 col-md-6">
                <LabeledSelect
                  item={state}
                  itemName="horizontalAlignment"
                  label="Horizontal Alignment"
                  updateItem={syncedStateUpdate}
                  options={[
                    { label: 'Left', value: 'start' },
                    { label: 'Center', value: 'center' },
                    { label: 'Right', value: 'end' },
                  ]}
                />
              </div>
              <div className="col-12 col-md-6">
                <LabeledSelect
                  item={state}
                  itemName="buttonsPerRow"
                  label="Buttons/Row"
                  updateItem={syncedStateUpdate}
                  options={[
                    { label: 'Automatic', value: '' },
                    { label: 1, value: 12 },
                    { label: 2, value: 6 },
                    { label: 3, value: 4 },
                    { label: 4, value: 3 },
                  ]}
                />
              </div>
            </>
          )}
        </div>
        {items.length > 0 ? (
          items.map((item, id) => (
            <IconButtonEditor
              key={item.id}
              index={id}
              item={item}
              numItems={items.length}
              state={state}
              {...actions}
            />
          ))
        ) : (
          <EmptyPlaceHolder itemName="button" />
        )}
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class IconButtonsTool {
  constructor({ config, data, api, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    const defaultData = {
      type: 'default', // 'default', 'minimal'
      buttonsPerRow: '',
      style: 'fill', // 'fill', 'outline'
      horizontalAlignment: 'start', // 'start', 'center', 'end'
      buttons: defaultSetup,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText: (
        <span>
          <span className="d-block mb-2">
            Icon buttons used as call-to-action links. *Note - Primary defaults to blue in our
            dashboard, but on your website it will be based on the primary colour of your theme.
          </span>
          <span className="small">***You can add a MAXIMUM of 4 Icon Buttons***</span>
        </span>
      ),
      itemName: 'Icon Button',
      hideToggle: true,
    }
  }

  static get toolbox() {
    return {
      title: 'Icon Buttons',
      icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
        <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
      </svg>
      `,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const getUpdatedData = () => {
      return this.data
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedIconButtonsComponent
        onDataChange={onDataChange}
        getUpdatedData={getUpdatedData}
        data={this.data}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default IconButtonsTool
