// Customised version of https://github.com/yrfandrew/editorjs-superscript/blob/master/src/index.js
import { API, InlineTool, SanitizerConfig } from '@editorjs/editorjs'

class Superscript implements InlineTool {
  public static isInline = true // Specifies Tool as Inline Toolbar Tool
  public static title = 'Superscript' // Title for hover-tooltip

  // private variables
  private api: API
  private button: HTMLButtonElement | null = null
  private readonly tag: string = 'SUP'
  private iconClasses: { base: string; active: string }

  constructor({ api }: { api: API }) {
    this.api = api
    this.button = null
    this.tag = 'SUP'
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    }
  }

  // Leave <sup> tag in the output
  public static get sanitize(): SanitizerConfig {
    return {
      sup: true, // or specify more detailed attributes if needed
    } as SanitizerConfig
  }

  // Button for the Inline Toolbar
  public render(): any {
    this.button = document.createElement('button')
    this.button.type = 'button'
    this.button.classList.add(this.iconClasses.base)
    this.button.innerHTML = this.toolboxIcon

    return this.button
  }

  // Called when the Inline Tool is clicked
  surround(range: Range): void {
    if (!range) {
      return
    }

    const toolWrapper = this.api.selection.findParentTag(this.tag)

    /**
     * If start or end of selection is in the highlighted block then unwrap block
     * else wrap selected block with toolWrapper
     */
    if (toolWrapper) {
      this.unwrap(toolWrapper)
    } else {
      this.wrap(range)
    }
  }

  // Wrap selected text with <sup> tag
  wrap(range: Range): void {
    // Create a wrapper
    const wrapperElement = document.createElement(this.tag)

    // Wrap the selected fragment with the wrapper
    wrapperElement.appendChild(range.extractContents())
    range.insertNode(wrapperElement)

    this.api.selection.expandToTag(wrapperElement)
  }

  // Unwrap the selected text
  unwrap(toolWrapper: HTMLElement): void {
    // Get the selection
    this.api.selection.expandToTag(toolWrapper)

    const sel = window.getSelection()
    const range = sel.getRangeAt(0)

    const unwrappedContent = range.extractContents()

    // Remove the empty tag
    toolWrapper.parentNode.removeChild(toolWrapper)

    // Reinsert the content
    range.insertNode(unwrappedContent)

    // Restore the selection
    sel.removeAllRanges()
    sel.addRange(range)
  }

  // Check/set the active state for the button
  checkState(): boolean {
    const toolTag = this.api.selection.findParentTag(this.tag)

    this.button.classList.toggle(this.iconClasses.active, !!toolTag)

    return !!toolTag
  }

  // Icon for the Inline Toolbar
  get toolboxIcon(): string {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-superscript">
        <path d="m4 19 8-8"/>
        <path d="m12 19-8-8"/>
        <path d="M20 12h-4c0-1.5.442-2 1.5-2.5S20 8.334 20 7.002c0-.472-.17-.93-.484-1.29a2.105 2.105 0 0 0-2.617-.436c-.42.239-.738.614-.899 1.06"/>
      </svg>`
  }

  static get pasteConfig() {
    return {
      tags: ['SUP'],
    }
  }

  static get shortcut() {
    return 'CMD+SHIFT+S'
  }
}

export default Superscript
