import { generateRandomId } from '../../common/Utils'
import { getPrimaryLocation, getErrorMessage } from '../templateUtils'

import { type WebsiteDetailsProps } from '../../types'
import { getFormTemplate } from '../../tools/FormBuilderTool/FormBuilderTemplates'

export function careersTemplate(websiteDetails: WebsiteDetailsProps) {
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  const primaryLocation = getPrimaryLocation(websiteDetails)

  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'default',
          existingCategory: 'default',
          existingFilters: {},
          image: {
            url: 'https://res.cloudinary.com/total-dealer/image/upload/v1712544519/td_next/careers',
          },
        },
        layout: {
          type: 'default',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Careers',
                level: 1,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 5,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: 'Career Opportunities',
        level: 1,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 5,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `${websiteDetails.name}`,
        level: 2,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'secondary',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'horizontalRule',
      data: {
        usePageTitle: true,
        height: 1,
        borderRadius: 5,
        opacity: 1,
        color: { colorType: 'primary', customColor: '000000' },
      },
      tunes: {
        container: { contain: false },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: { active: false, spacingTop: 0, spacingBottom: 0 },
          tablet: { active: false, spacingTop: 0, spacingBottom: 0 },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: { active: false, paddingTop: 0, paddingBottom: 0 },
          tablet: { active: false, paddingTop: 0, paddingBottom: 0 },
        },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: `At ${websiteDetails.name}, our success is driven by our people who are the backbone of our company.`,
        level: 5,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `Join ${websiteDetails.name} and become an important member of our professional and friendly team. Experience our world standard facilities, on-going training, great team spirit, plus amazing lifestyle. Be part of a company large enough to reward ability and personal enough to care.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `${websiteDetails.name} provides a variety of fantastic career opportunities across the business, from sales staff to management. Excellent opportunities also exist for students, graduates and apprentices.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: 'Our dealership prides itself on the tenure of its employees, and strives to continually support and engage staff development throughout their career. To find out more about our current vacancies, please complete the Careers Enquiry Form.',
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'none',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 3,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'customComponent',
      data: {
        uniqueComponentId: 'careers-positions-available-default',
        customComponentId: undefined,
      },
      tunes: {
        alignment: {
          alignment: 'left',
        },
        container: {
          contain: false,
        },
        screensize: {
          screenSize: 'all',
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'formBuilder',
      data: getFormTemplate('careers'),
      tunes: {
        backgroundColor: {
          backgroundColor: 'light',
          customBackgroundColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        padding: {
          paddingTop: 5,
          paddingBottom: 5,
          mobile: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
          tablet: {
            active: false,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'locations',
      data: {
        filter: 'primary',
        manufacturer: false,
        subtype: false,
        filteredLocations: [],
        primaryLocationId: primaryLocation?.id,
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
  ]
}
