import { generateRandomId } from '../../common/Utils'
import { getPrimaryLocation, getErrorMessage } from '../templateUtils'

import { type WebsiteDetailsProps } from '../../types'
import { getFormTemplate } from '../../tools/FormBuilderTool/FormBuilderTemplates'

export function fleetTemplate(websiteDetails: WebsiteDetailsProps) {
  if (websiteDetails === undefined) {
    return getErrorMessage()
  }

  const primaryLocation = getPrimaryLocation(websiteDetails)

  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'default',
          existingCategory: 'default',
          existingFilters: {},
          image: {
            url: 'https://res.cloudinary.com/total-dealer/image/upload/v1645834405/td_next/test-drive_dbog8u',
          },
        },
        layout: {
          type: 'default',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Image Banner',
                level: 2,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: {
        text: 'Fleet Services',
        level: 2,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: false,
        },
        backgroundColor: {
          backgroundColor: 'dark',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'light',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 5,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },

    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `${websiteDetails.name} have a dedicated fleet specialist ready to help you with your customised fleet plan to suit all of your business needs.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'dark',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'light',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 3,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: `With a huge range of new makes and models, ${websiteDetails.name} have the vehicles to help drive your business further. No orders too small and there is often no minimum when you purchase, so allow us to fulfil your fleet needs.`,
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        backgroundColor: {
          backgroundColor: 'none',
          customBackgroundColor: '#000000',
        },
        textColor: {
          textColor: 'secondary',
          customTextColor: '#000000',
        },
        margin: {
          spacingTop: 5,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'iconButtonsTool',
      data: {
        buttons: [
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Custom Fleet solutions',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Servicing and repairs',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Finance options',
            href: '',
          },
          {
            id: generateRandomId(10),
            icon: 'Check',
            text: 'Fast quotes',
            href: '',
          },
        ],
        type: 'minimal',
        buttonsPerRow: '',
        horizontalAlignment: 'center',
      },
      tunes: {
        alignment: {
          alignment: 'center',
        },
        container: {
          contain: true,
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 3,
          mobile: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
          tablet: {
            active: false,
            spacingTop: 0,
            spacingBottom: 0,
          },
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'formBuilder',
      data: getFormTemplate('fleet'),
      tunes: {
        backgroundColor: {
          backgroundColor: 'light',
          customBackgroundColor: '#000000',
        },
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        padding: {
          paddingTop: 5,
          paddingBottom: 5,
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'locations',
      data: {
        filter: 'primary',
        manufacturer: false,
        subtype: false,
        filteredLocations: [],
        primaryLocationId: primaryLocation?.id,
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        classname: {
          className: '',
        },
      },
    },
  ]
}
