import { acceptedIcons } from './utils'

const DefaultIconButtonPreview = ({ item }) => {
  const Icon = acceptedIcons.filter((obj) => obj.name === item.icon)[0]?.icon

  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center">
      <div
        className="d-flex align-items-center justify-content-center text-primary mx-auto"
        style={{ height: 100, width: 100, borderRadius: '50%', border: '2px solid var(--primary)' }}
      >
        <div className="w-100" style={{ height: 50, maxWidth: 50 }}>
          {Icon ? <Icon /> : <></>}
        </div>
      </div>
      <h5 className="mb-3 mb-md-0 mt-2">{item.text}</h5>
    </div>
  )
}

const MinimalIconButtonPreview = ({ item, state }) => {
  const Icon = acceptedIcons.filter((obj) => obj.name === item.icon)[0]?.icon

  return (
    <div
      className={`d-flex align-items-start justify-content-${
        state.horizontalAlignment ?? 'start'
      } text-left`}
    >
      <div>
        <div
          className={`d-flex align-items-center justify-content-center p-1 mr-2 ${
            state.style === 'outline'
              ? 'border border-primary text-primary'
              : 'bg-primary text-white'
          }`}
          style={{ width: 22, height: 22, borderRadius: '100px' }}
        >
          {Icon ? <Icon /> : <></>}
        </div>
      </div>
      <p className="mb-0">{item.text}</p>
    </div>
  )
}

export const IconButtonPreview = ({ state, item }) => {
  if (state.type === 'minimal') {
    return <MinimalIconButtonPreview item={item} state={state} />
  } else {
    return <DefaultIconButtonPreview item={item} />
  }
}
