import { default as React } from 'react'
import { createRoot } from 'react-dom/client'
import CarsViewer from './CarsViewer'
import { indexName } from '../../../cars/config'
import { unique } from '../../../entries/utils'
import { renderEditSettingsButton } from '../../common/Utils'

class CarsTool {
  static get toolbox() {
    return {
      title: 'Cars',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <!--! Font Awesome Pro 6.2.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
        <path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zm288 32c17.7 0 32-14.3 32-32s-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32z"/>
      </svg>`,
    }
  }

  constructor({ data, config, api, block }) {
    // Initialize the Cars tool
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    const defaultData = {
      multiSelect: true,
      searchState: {
        sortBy: `${indexName}:stocknum:asc`, // This needs to be set as a default
      },
      dateFilter: null,
      hideButton: false,
      hideSearch: false,
      buttonColor: 'dark',
      // Removing these as options from this point onwards
      // Want to use tunes/normal headings
      heading: undefined,
      background: undefined,
    }

    this.data = Object.keys(data).length ? data : defaultData

    // This adds the multiSelect value to cars instances created before the option was added
    if (this.data.multiSelect === undefined) {
      this.data.multiSelect = false
    }
    if (this.data.buttonColor === undefined) {
      this.data.buttonColor = 'dark'
    }

    // CSS class for the tool's wrapper
    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }
  }

  // Render the Cars tool
  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    // Callback function for data changes in CarsViewer
    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <CarsViewer
        onDataChange={onDataChange}
        data={this.data}
        uniqueId={this.uniqueId}
        templateType={this.config.templateType}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  /**
   * Saves the current tool data.
   *
   * @async
   * @returns {Object} - The current data object.
   */
  async save() {
    return this.data
  }
}

export default CarsTool
