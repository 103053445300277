import { ready } from './entries/utils'

function getAddressField(place, type, field = 'short_name') {
  let components = place.address_components.filter(ac => ac.types.includes(type))
  return components.length > 0 ? components[0][field] : ''
}

function initialize() {
  var input = document.getElementById('searchTextField');
  if (input) {
    var autocomplete = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      var place = autocomplete.getPlace();
      document.getElementById('address_name').value = `${place.name}`;
      document.getElementById('address_street').value = `${getAddressField(place, 'street_number')} ${getAddressField(place, 'route')}`;
      document.getElementById('address_city').value = getAddressField(place, 'locality');
      document.getElementById('address_postcode').value = getAddressField(place, 'postal_code')
      document.getElementById('address_state').value = getAddressField(place, 'administrative_area_level_1')
      document.getElementById('address_lat').value = place.geometry.location.lat();
      document.getElementById('address_lang').value = place.geometry.location.lng();
      document.getElementById('address_place_id').value = place.place_id;
      document.getElementById('address_place_url').value = place.url;
    });
  }
}

ready(function () {
  var input = document.getElementById('searchTextField');
  if (typeof google !== 'undefined' && input) {
    google.maps.event.addDomListener(window, 'load', initialize);
  }
})

let days_of_week = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
}

function locationSync() {
  var input = document.getElementById('location_google_place_name');
  if (input) {
    var autocomplete = new google.maps.places.Autocomplete(input);
    google.maps.event.addListener(autocomplete, 'place_changed', function () {
      var place = autocomplete.getPlace();
      let placeDetailsContainer = document.getElementById('place-details-container')
      placeDetailsContainer.innerHTML = ''
      placeDetailsContainer.insertAdjacentHTML('beforeend', `<p>Website = ${place.website}</p>`);
      placeDetailsContainer.insertAdjacentHTML('beforeend', `<p>Phone = ${place.formatted_phone_number}</p>`);
      let html_to_insert = ""
      place.opening_hours.periods.map(period => {
        let day_of_week = period.open.day
        html_to_insert += `<li><b>${days_of_week[period.open.day]}</b> - Open: ${period.open.time}. Close: ${period.close.time}</li>`
        document.getElementById(`location_contact_detail_attributes_opening_hours_attributes_${day_of_week}_open_4i`).value = period.open.hours < 10 ? `0${period.open.hours}` : `${period.open.hours}`;
        document.getElementById(`location_contact_detail_attributes_opening_hours_attributes_${day_of_week}_open_5i`).value = period.open.minutes < 10 ? `0${period.open.minutes}` : `${period.open.minutes}`;
        document.getElementById(`location_contact_detail_attributes_opening_hours_attributes_${day_of_week}_close_4i`).value = period.close.hours < 10 ? `0${period.close.hours}` : `${period.close.hours}`
        document.getElementById(`location_contact_detail_attributes_opening_hours_attributes_${day_of_week}_close_5i`).value = period.close.minutes < 10 ? `0${period.close.minutes}` : `${period.close.minutes}`;
        let status_select = document.getElementById(`location_contact_detail_attributes_opening_hours_attributes_${day_of_week}_status`)
        status_select.value = "Open";
        status_select.dispatchEvent(new Event('change'));
      })
      document.getElementById('location_contact_detail_attributes_phone').value = place.formatted_phone_number
      document.getElementById('location_website_url').value = place.website
      document.getElementById('location_google_place_id').value = place.place_id
      placeDetailsContainer.insertAdjacentHTML('beforeend', `<ul>${html_to_insert}</ul>`);
    });
  }
}

ready(function () {
  var input = document.getElementById('location_google_place_name');
  if (typeof google !== 'undefined' && input) {
    google.maps.event.addDomListener(window, 'load', locationSync);
  }
})