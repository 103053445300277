import { bootstrapColours } from '../common'
import { textDark, isValidHexColor } from '../common/Utils'
import { translateBootstrapColour } from '../common/constants'

export default class TextColorTune {
  static get isTune() {
    return true
  }

  constructor({ api, data, config, block }) {
    this.api = api
    this.block = block
    this.settings = config
    this.data = data
      ? {
          textColor: data.textColor ? data.textColor : 'none',
          customTextColor: data.customTextColor ? data.customTextColor : '#000000',
        }
      : { textColor: 'none', customTextColor: '#000000' }
  }

  render() {
    // Get the contain div (for applying styles)
    const currentBlockIndex = this.api.blocks.getCurrentBlockIndex()
    const currentBlockHolder = this.api.blocks.getBlockByIndex(currentBlockIndex).holder

    const wrapper = document.createElement('div')

    // Create the color select input element
    const colorSelect = document.createElement('select')
    colorSelect.id = 'colorSelect'
    colorSelect.className = `form-control form-control-sm bg-${this.data.textColor} text-${
      textDark.includes(this.data.textColor) ? 'dark' : 'white'
    }`

    // Create and append options to the select element
    function createOption(value, text, selected) {
      const option = document.createElement('option')
      option.value = value
      option.textContent = text
      if (selected) {
        option.selected = true
      }
      colorSelect.appendChild(option)
    }

    createOption('none', 'none', this.data.textColor === 'none')
    bootstrapColours.forEach((color) => {
      createOption(
        color.toLowerCase(),
        translateBootstrapColour(color),
        color.toLowerCase() === this.data.textColor
      )
    })
    createOption('custom', 'custom', this.data.textColor === 'custom')

    // Create the color icon element
    const colorIcon = document.createElement('div')
    colorIcon.className = 'ce-popover-item__icon'
    colorIcon.innerHTML = `
      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path d="M904 816H120c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8zm-650.3-80h85c4.2 0 8-2.7 9.3-6.8l53.7-166h219.2l53.2 166c1.3 4 5 6.8 9.3 6.8h89.1c1.1 0 2.2-.2 3.2-.5a9.7 9.7 0 0 0 6-12.4L573.6 118.6a9.9 9.9 0 0 0-9.2-6.6H462.1c-4.2 0-7.9 2.6-9.2 6.6L244.5 723.1c-.4 1-.5 2.1-.5 3.2-.1 5.3 4.3 9.7 9.7 9.7zm255.9-516.1h4.1l83.8 263.8H424.9l84.7-263.8z"></path>
      </svg>
    `

    // Create the color label element
    const colorLabel = document.createElement('div')
    colorLabel.className = 'ce-popover-item__title'
    colorLabel.textContent = 'Text Color'

    // Create the custom color input element
    const customColorInput = document.createElement('input')
    customColorInput.type = 'text'
    customColorInput.value = this.data.customTextColor
    customColorInput.className = 'mr-1 form-control form-control-sm'

    const customColorBlock = document.createElement('div')
    customColorBlock.className = 'ce-popover-item__icon mr-0'
    customColorBlock.style.background = `${this.data.customTextColor}`
    customColorBlock.style.width = '31px'
    customColorBlock.style.height = '31px'

    const customColorContainer = document.createElement('div')
    customColorContainer.className = `d-${this.data.textColor === 'custom' ? 'flex' : 'none'} mt-1`
    customColorContainer.appendChild(customColorInput)
    customColorContainer.appendChild(customColorBlock)

    // Handle custom text color input
    customColorInput.addEventListener('input', () => {
      let customColorValue = customColorInput.value

      // Check if the value does not start with #
      if (!customColorInput.value.startsWith('#')) {
        customColorValue = '#' + customColorInput.value // Prepend # to the input value
        customColorInput.value = customColorValue
      }

      if (isValidHexColor(customColorValue)) {
        this.data.customTextColor = customColorValue
        currentBlockHolder.style.color = `${this.data.customTextColor}`
        customColorBlock.style.background = `${this.data.customTextColor}`
        customColorInput.style.background = '#FFFFFF'
        this.settings.save()
        // Force trigger Block's onChange event
        this.block.dispatchChange()
      } else {
        customColorBlock.style.background = 'FFFFFF'
        customColorInput.style.background = '#FFCCCB'
        customColorInput.style.color = 'var(--dark)'
        currentBlockHolder.style.color = 'black'
      }
    })

    // Handle color select change event
    colorSelect.addEventListener('change', () => {
      const selectedColor = colorSelect.value
      this.data.textColor = selectedColor
      customColorContainer.className = `d-${this.data.textColor === 'custom' ? 'flex' : 'none'}`
      colorSelect.className = `mb-1 form-control form-control-sm bg-${selectedColor} text-${
        textDark.includes(selectedColor) ? 'dark' : 'white'
      }`

      if (selectedColor === 'custom') {
        currentBlockHolder.style.color = `${this.data.customTextColor}`
        customColorBlock.style.background = `${this.data.customTextColor}`
      } else {
        currentBlockHolder.style.color = `var(--${selectedColor})`
      }

      this.settings.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    // Create and append the elements to the wrapper div
    const labelRow = document.createElement('div')
    labelRow.className = 'd-flex flex-row align-items-center mb-1'
    labelRow.appendChild(colorIcon)
    labelRow.appendChild(colorLabel)

    const colorSelectRow = document.createElement('div')
    colorSelectRow.appendChild(colorSelect)

    const colorRow = document.createElement('div')
    colorRow.className = 'd-flex flex-column my-1'
    colorRow.appendChild(labelRow)
    colorRow.appendChild(colorSelectRow)
    colorRow.appendChild(customColorContainer)

    wrapper.appendChild(colorRow)

    return wrapper
  }

  save() {
    return this.data
  }
}
