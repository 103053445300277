export const frequencyOptions = [
  { label: 'Weekly', value: '52' },
  { label: 'Fortnightly', value: '26' },
  { label: 'Monthly', value: '12' },
  { label: 'Yearly', value: '1' },
]

/**
 * Applies slider rules to the state values before saving.
 * @param {*} sliderState The current state of the slider
 * @returns updated values for min, max, step, and defaultVal
 */
export function handleSliderRules(sliderState) {
  // Ensure min is not less than 0
  const min =
    parseInt(sliderState.min) < 0 || parseInt(sliderState.min) == 0
      ? '0'
      : parseInt(sliderState.min)
  // Ensure max is not less than min
  const max =
    parseInt(sliderState.max) < parseInt(sliderState.min)
      ? parseInt(sliderState.min)
      : parseInt(sliderState.max)
  // Ensure step is not less than 0.01
  const step = parseFloat(sliderState.step) < 0.01 ? 0.01 : parseFloat(sliderState.step).toFixed(2)
  // Ensure default is not less than min or greater than max
  const defaultVal =
    parseInt(sliderState.default) < parseInt(min) || parseInt(sliderState.default) > parseInt(max)
      ? (parseInt(min) + parseInt(max)) / 2
      : parseInt(sliderState.default)

  return { min, max, step, defaultVal }
}

/**
 * Converts a color object to a css color value.
 * @param {*} color the color object to handle
 * @returns a css color value
 */
export function handleColorType(color) {
  return color.colorType === 'custom' ? `#${color.customColor}` : `var(--${color.colorType})`
}
