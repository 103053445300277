import { generateRandomId } from '../../common/Utils'
import { getFormTemplate } from '../../tools/FormBuilderTool/FormBuilderTemplates'

import { type WebsiteDetailsProps } from '../../types'

/**
 * Handle what form to return based on whether the website has
 * NEVID enabled or not.
 *
 * @param websiteDetails - An object containing the website details
 * @returns - A form or formBuilder block based on the website details
 */
export function handleServiceForm(websiteDetails: WebsiteDetailsProps) {
  return websiteDetails.nevdis
    ? {
        id: generateRandomId(10),
        type: 'formBuilder',
        data: getFormTemplate('service-nevdis'),
        tunes: {
          backgroundColor: {
            backgroundColor: 'light',
            customBackgroundColor: '#000000',
          },
          margin: {
            spacingTop: 0,
            spacingBottom: 0,
          },
          padding: {
            paddingTop: 5,
            paddingBottom: 5,
          },
          classname: {
            className: '',
          },
        },
      }
    : {
        id: generateRandomId(10),
        type: 'formBuilder',
        data: getFormTemplate('service-default'),
        tunes: {
          backgroundColor: {
            backgroundColor: 'light',
            customBackgroundColor: '#000000',
          },
          margin: {
            spacingTop: 0,
            spacingBottom: 0,
          },
          padding: {
            paddingTop: 5,
            paddingBottom: 5,
          },
          classname: {
            className: '',
          },
        },
      }
}
