import { LabeledNumberInput, LabeledSelect, objectFitOptions } from '../../../../common'
import CardsPreview from '../CardsPreview'
import { starters } from '../../cardSettingsConfig'
import {
  LayoutTypeOptionsTooltip,
  ImageLayoutOptionsTooltip,
  AspectRatioTooltip,
} from '../../CardsTooltips'

const ImageLayoutSettingsTab = ({ state, syncedStateUpdate, colPerRow }) => {
  const item = starters.demo.cards[0]

  const gutterSize = 30
  const containerWidth = 1440
  const netWidthAvailable = containerWidth - gutterSize * (colPerRow - 1)
  // Dynamic column width
  const cardWidth = netWidthAvailable / colPerRow

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-6 mt-2">
          <LabeledSelect
            controlled={false}
            item={state}
            itemName="layoutType"
            label="Image Layout Type"
            updateItem={syncedStateUpdate}
            options={[
              { label: 'Default', value: 'default' },
              { label: 'Custom Height and Width', value: 'customSize' },
              { label: 'Custom Aspect Ratio', value: 'customAspectRatio' },
            ]}
            tooltip={LayoutTypeOptionsTooltip}
          />
        </div>
        {(state.layoutType === 'customSize' || state.layoutType === 'customAspectRatio') && (
          <div className="col-12 col-md-6 mt-2">
            <LabeledSelect
              controlled={false}
              item={state}
              itemName="objectFit"
              label="Image Layout"
              updateItem={syncedStateUpdate}
              options={Array.from(objectFitOptions)}
              tooltip={ImageLayoutOptionsTooltip}
            />
          </div>
        )}
        {state.layoutType === 'customSize' && (
          <>
            <div className="col-12 col-md-6 mt-2">
              <LabeledNumberInput
                controlled={false}
                item={state}
                itemName="imageHeight"
                label={
                  <>
                    Image Height (px)
                    <br />
                    <small className="text-muted">
                      *Leave this blank if your images have the same aspect ratio
                    </small>
                  </>
                }
                placeholder="auto*"
                updateItem={syncedStateUpdate}
              />
            </div>
            <div className="col-12 col-md-6 mt-2">
              <LabeledNumberInput
                controlled={false}
                item={state}
                itemName="imageWidth"
                label={
                  <>
                    Image Width (px)
                    <br />
                    <small className="text-muted">
                      *Leave this blank if your images have the same aspect ratio
                    </small>
                  </>
                }
                placeholder="100%*"
                updateItem={syncedStateUpdate}
              />
            </div>
          </>
        )}
        {state.layoutType === 'customAspectRatio' && (
          <>
            <div className="col-6 mt-2">
              <LabeledNumberInput
                controlled={false}
                item={state}
                itemName="aspectRatioHeight"
                label="Image Aspect Ratio Height"
                placeholder="Height Value"
                updateItem={syncedStateUpdate}
                tooltip={AspectRatioTooltip}
              />
            </div>
            <div className="col-6 mt-2">
              <LabeledNumberInput
                controlled={false}
                item={state}
                itemName="aspectRatioWidth"
                label="Image Aspect Ratio Width"
                placeholder="Width Value"
                updateItem={syncedStateUpdate}
                tooltip={AspectRatioTooltip}
              />
            </div>
          </>
        )}
      </div>
      <div
        style={{
          maxWidth: `${cardWidth}px`,
          margin: 'auto',
        }}
      >
        <CardsPreview colPerRow={colPerRow} item={item} state={state} />
      </div>
    </div>
  )
}

export default ImageLayoutSettingsTab
