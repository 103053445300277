import React from 'react'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import {
  InstantSearch,
  Configure,
  Menu,
  RangeInput,
  RefinementList,
  SortBy,
  HitsPerPage,
} from 'react-instantsearch'
import { indexName, sortingOptions } from '../../../../cars/config'
import { htmlDecode } from '../../../common/Utils'

import Container from './Container'
import CarsPreview from '../../../tools/CarsTool/CarsPreview'

const CarsBlock = ({ block }) => {
  const meilisearchHost = document.getElementById('meilisearch-host').getAttribute('value')
  const meilisearchApiKey = document.getElementById('meilisearch-api-key').getAttribute('value')

  const { searchClient } = instantMeiliSearch(meilisearchHost, meilisearchApiKey)

  let filters = ''

  if (window.search_filters) {
    filters = search_filters.replaceAll('&#39;', '"')
    filters = htmlDecode(filters)
  }

  const editor = document.getElementById('editorjs')
  const created_at_options = editor.getAttribute('created_at_options')
  const dateOptions = JSON.parse(created_at_options)

  const convertedData = {
    ...block.data,
    searchState: {
      [`Car_${window.environment}`]: block.data.searchState,
    },
  }

  const { searchState, dateFilter } = convertedData

  if (dateFilter) {
    let maxDate = dateOptions.filter((d) => d.label === dateFilter)[0].start
    if (maxDate) {
      filters += ` AND created_at_timestamp < ${maxDate}`
    }
  }

  return (
    <Container tunes={block.tunes} isInRow>
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        onStateChange={({ uiState }) => {
          onSearchStateChange(uiState)
        }}
        initialUiState={searchState}
      >
        <Configure filters={filters} />
        <CarsPreview state={convertedData} />
        <div style={{ visibility: 'hidden', height: 0 }}>
          <HitsPerPage
            items={[
              { value: 3, label: 'Show 3 cars', default: true },
              { value: 6, label: 'Show 6 cars' },
              { value: 9, label: 'Show 9 cars' },
              { value: 12, label: 'Show 12 cars' },
              { value: 15, label: 'Show 15 cars' },
              { value: 18, label: 'Show 18 cars' },
            ]}
          />
          <SortBy items={sortingOptions} />
          {/* These 2 are included in the singleSelect */}
          <RefinementList attribute="car_type" />
          <RefinementList attribute="model" />
          {convertedData ? (
            <>
              <RefinementList attribute="make" />
              <RefinementList attribute="simple_body" />
              <RefinementList attribute="badge" />
              <RefinementList attribute="tag" />
              <RefinementList attribute="carsales_account" />
              <RefinementList attribute="location_name" />
              <RefinementList attribute="simple_fuel" />
              <RefinementList attribute="status" />
              <Menu attribute="certified" />
              <RangeInput attribute="year" />
              <RangeInput attribute="km" />
            </>
          ) : (
            <>
              <Menu attribute="car_type" />
              <Menu attribute="make" />
              <Menu attribute="model" />
              <Menu attribute="tag" />
              <Menu attribute="simple_body" />
              <Menu attribute="badge" />
              <Menu attribute="carsales_account" />
              <Menu attribute="location_name" />
              <Menu attribute="simple_fuel" />
            </>
          )}
        </div>
      </InstantSearch>
    </Container>
  )
}

export default CarsBlock
