const PreviewWarning = ({
  type = 'warning',
  message,
}: {
  type?: 'warning' | 'danger'
  message: string
}) => (
  <div className="my-2 text-left">
    <span className={`badge badge-${type}`}>Warning</span> <span className="small">{message}</span>
  </div>
)

export default PreviewWarning
