import React from 'react'
import { useHits } from 'react-instantsearch'

const Hit = ({ hit }) => {
  const truncatedDescription = hit.sanitized_description.substring(0, 200)

  return (
    <div className="col-md-6 col-xl-4 my-3">
      <div className="offer border rounded shadow bg-white overflow-hidden h-100 d-flex flex-column">
        <div className="image-column pt-3 pt-lg-4 px-3 px-lg-4 d-flex align-items-center flex-column overflow-hidden d-flex">
          <h4 className="h3 text-center">{hit.name}</h4>
          <img src={hit.image_url} className="car-thumb-photo" />
        </div>
        <div className="px-3 px-lg-4 mt-auto">
          {' '}
          <div
            dangerouslySetInnerHTML={{
              __html: truncatedDescription,
            }}
          />
        </div>
        <div className="px-3 px-lg-4 pb-3 mt-auto pt-2">
          {hit.model && hit.model.slug && (
            <a href={`#`} className="btn btn-outline-primary btn-block mb-2">
              Explore {hit.model.name}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

// TODO (Codemod generated): ensure your usage correctly maps the props from the connector to the hook
function connectHits(Component) {
  const Hits = (props) => {
    const data = useHits(props)

    return <Component {...props} {...data} />
  }

  return Hits
}

const Hits = ({ hits }) => {
  return (
    <div className="row">
      {hits.map((hit) => (
        <Hit hit={hit} key={hit.id} />
      ))}
    </div>
  )
}

const CustomHits = connectHits(Hits)

const SpecialOffersPreview = () => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <CustomHits />
      </div>
    </div>
  </div>
)

export default SpecialOffersPreview
