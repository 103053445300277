const LayoutTypeOptionsTooltip = {
  content: (
    <div className="small">
      <p>
        <strong>Default:</strong> Utilises the pre-defined card dimensions. This is a good fit for
        quick setups or when uniform card sizes are preferred.
      </p>
      <p>
        <strong>Custom Height and Width:</strong> Allows for specific height and width settings for
        your cards. Ideal for creating cards that need to fit unique space requirements on your
        page.
      </p>
      <p className="mb-0">
        <strong>Custom Aspect Ratio:</strong> Enables the specification of a custom aspect ratio for
        the card images, ensuring consistent dimensions across various screen sizes without altering
        the original aspect ratio of the images.
      </p>
    </div>
  ),
}

const ImageLayoutOptionsTooltip = {
  content: (
    <div className="small">
      <p>
        <strong>Cover:</strong> Scales the image to ensure it fully covers the card area. Parts of
        the image may be cropped to maintain the card's dimensions.
      </p>
      <p>
        <strong>Contain:</strong> Ensures the entire image is visible within the card, potentially
        resulting in white or black bars to accommodate any difference in aspect ratio.
      </p>
      <p className="mb-0">
        <strong>Fill:</strong> Stretches the image to cover the card, altering the image's aspect
        ratio if necessary. This option eliminates any empty space but can distort the image.
      </p>
    </div>
  ),
}

const AspectRatioTooltip = {
  content: (
    <div className="small">
      <p>
        <strong>Aspect Ratio Height:</strong> Refers to the height component of the aspect ratio for
        your card images. This value is used in conjunction with the Aspect Ratio Width to maintain
        the proportional dimensions of images.
      </p>
      <p>
        <strong>Aspect Ratio Width:</strong> Specifies the width component of the aspect ratio for
        your card images. By setting both the height and width aspect ratios, you define a specific
        proportional relationship between the width and height of images.
      </p>
      <p className="mb-0">
        Example: An aspect ratio of 16 (width) to 9 (height) creates a wide, landscape-oriented
        image layout.
      </p>
    </div>
  ),
}

export { LayoutTypeOptionsTooltip, ImageLayoutOptionsTooltip, AspectRatioTooltip }
