import { API, InlineTool, SanitizerConfig } from '@editorjs/editorjs'
import { bootstrapColours, translateBootstrapColour } from '../../common'
import { isValidColor, textDark } from '../../common/Utils'

class CustomTools implements InlineTool {
  public static isInline = true // Specifies Tool as Inline Toolbar Tool
  public static title = 'Custom Tools' // Title for hover-tooltip

  // Private variables
  private api: API
  private button: HTMLButtonElement | null = null
  private readonly tag: string = 'CUSTOM'
  private isActive: boolean
  private iconClasses: { base: string; active: string }

  // * Text Color Tool
  private defaultColor: string
  // Custom input elements
  private colorPickerContainer: HTMLElement | null
  private colorSelect: HTMLSelectElement | null
  private customColorContainer: HTMLElement | null
  private customColorInput: HTMLInputElement | null
  private customColorBlock: HTMLElement | null

  // * Classname Tool
  private classnameContainer: HTMLElement | null
  private customClassnameInput: HTMLInputElement | null

  constructor({ api }: { api: API }) {
    this.api = api
    this.button = null
    this.isActive = false
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      active: this.api.styles.inlineToolButtonActive,
    }
    // TextColor Tool
    this.defaultColor = 'none'
  }

  // Leave <span> tag in the output
  public static get sanitize(): SanitizerConfig {
    return {
      custom: true, // or specify more detailed attributes if needed
    } as SanitizerConfig
  }

  // Called when the Inline Tool is clicked
  surround(range: Range): void {
    if (!range) {
      return
    }

    const toolWrapper = this.api.selection.findParentTag(this.tag)

    /**
     * If start or end of selection is in the highlighted block then unwrap block
     * else wrap selected block with toolWrapper
     */
    if (toolWrapper) {
      this.unwrap(toolWrapper)
    } else {
      this.wrap(range)
    }
  }

  // Wrap selected text with <custom> tag
  wrap(range: Range): void {
    // Create a wrapper
    const wrapperElement = document.createElement(this.tag)

    // Wrap the selected fragment with the wrapper
    wrapperElement.appendChild(range.extractContents())
    range.insertNode(wrapperElement)

    this.api.selection.expandToTag(wrapperElement)
  }

  // Unwrap the selected text
  unwrap(toolWrapper: HTMLElement): void {
    // Get the selection
    this.api.selection.expandToTag(toolWrapper)

    const sel = window.getSelection()
    const range = sel.getRangeAt(0)

    const unwrappedContent = range.extractContents()

    // Remove the empty tag
    toolWrapper.parentNode.removeChild(toolWrapper)

    // Reinsert the content
    range.insertNode(unwrappedContent)

    // Restore the selection
    sel.removeAllRanges()
    sel.addRange(range)
  }

  // Check/set the active state for the button
  checkState(): boolean {
    const toolTag = this.api.selection.findParentTag(this.tag)

    this.isActive = !!toolTag
    this.button.classList.toggle(this.api.styles.inlineToolButtonActive, !!toolTag)
    this.button.classList.toggle(this.iconClasses.active, !!toolTag)

    if (this.isActive) {
      this.showActions(toolTag)
    } else {
      this.hideActions()
    }

    return !!toolTag
  }

  // Icon for the Inline Toolbar
  get toolboxIcon(): string {
    return `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-settings">
        <path d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z"/>
        <circle cx="12" cy="12" r="3"/>
      </svg>`
  }

  static get pasteConfig() {
    return {
      tags: ['CUSTOM'],
    }
  }

  // Button for the Inline Toolbar
  public render(): any {
    this.button = document.createElement('button')
    this.button.type = 'button'
    this.button.classList.add(this.iconClasses.base)
    this.button.innerHTML = this.toolboxIcon

    return this.button
  }

  // Render custom tool inputs
  // This only runs once when the tool is first selected
  // So we don't handle state/events here
  renderActions() {
    const customToolsContainer = document.createElement('div')
    customToolsContainer.className = 'd-flex flex-column w-100'

    // Render the custom tools
    customToolsContainer.appendChild(this.renderTextColorInput())
    customToolsContainer.appendChild(this.renderClassNameInput())

    return customToolsContainer
  }

  // Run every time the tool is selected OR if the tool is already in use
  // and the user reopens the inline toolbar
  showActions(span: HTMLSpanElement) {
    this.showTextColorInput(span)
    this.showClassNameInput(span)
  }

  hideActions() {
    this.hideTextColorInput()
    this.hideClassNameInput()
  }

  // * Text Color Tool
  renderTextColorInput() {
    const inputContainer = document.createElement('div')

    // Create the color select input element
    const colorSelect = document.createElement('select')
    colorSelect.value = this.defaultColor
    colorSelect.id = 'colorSelect'
    colorSelect.className = 'form-control form-control-sm'

    // Function to create and append options to the select element
    function createOption(value, text, selected) {
      const option = document.createElement('option')
      option.value = value
      option.textContent = text
      if (selected) {
        option.selected = true
      }
      colorSelect.appendChild(option)
    }

    // Create the options
    createOption('none', 'none', true) // none by default
    bootstrapColours.forEach((color) => {
      createOption(color.toLowerCase(), translateBootstrapColour(color), false)
    })
    createOption('custom', 'custom', false)

    // Create the custom color input element
    const customColorInput = document.createElement('input')
    customColorInput.type = 'text'
    customColorInput.placeholder = 'Hexcode/RGB/RGBA'
    customColorInput.className = 'mr-1 form-control form-control-sm'

    // Create the custom color block element
    const customColorBlock = document.createElement('div')
    customColorBlock.className = 'border rounded'
    customColorBlock.style.background = 'none'
    customColorBlock.style.width = '31px'
    customColorBlock.style.height = '31px'

    // Create the custom color container element
    const secondRow = document.createElement('div')
    secondRow.className = 'd-flex mt-1'
    secondRow.hidden = true
    secondRow.appendChild(customColorInput)
    secondRow.appendChild(customColorBlock)

    const firstRow = document.createElement('div')
    firstRow.className = 'd-flex w-100 align-items-center'
    const icon = document.createElement('div')
    icon.className = 'mr-1'
    icon.innerHTML = `<svg data-v-14c8c335="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-baseline lucide-icon customizable">
        <path d="M4 20h16"></path><path d="m6 16 6-12 6 12"></path>
        <path d="M8 12h8"></path>
      </svg>`
    firstRow.appendChild(icon)
    firstRow.appendChild(colorSelect)

    const inputRow = document.createElement('div')
    inputRow.className = 'd-flex flex-column mb-1'
    inputRow.appendChild(firstRow)
    inputRow.appendChild(secondRow)

    inputContainer.appendChild(inputRow)

    // Store in class variables
    this.colorPickerContainer = inputContainer
    this.colorSelect = colorSelect
    this.customColorContainer = secondRow
    this.customColorInput = customColorInput
    this.customColorBlock = customColorBlock

    return this.colorPickerContainer
  }

  showTextColorInput(span: HTMLSpanElement) {
    // Get the color of the span
    const { color } = span.style

    // If the color is not set, use the default color
    const colorValue = color ? color : this.defaultColor

    // Initialise state of all custom inputs
    if (isValidColor(colorValue)) {
      // Custom colors
      this.colorSelect.value = 'custom'
      this.colorSelect.className = 'form-control form-control-sm'
      this.customColorInput.value = colorValue
      this.customColorBlock.style.background = colorValue
      this.customColorContainer.hidden = false
    } else {
      // Bootstrap colors
      const bootstrapValue =
        colorValue === 'inherit' ? 'none' : colorValue.replace('var(--', '').replace(')', '')
      this.colorSelect.value = bootstrapValue
      this.colorSelect.className =
        bootstrapValue === 'none'
          ? 'form-control form-control-sm'
          : `form-control form-control-sm bg-${bootstrapValue} text-${
              textDark.includes(bootstrapValue) ? 'dark' : 'white'
            }`
      this.customColorInput.value = ''
      this.customColorBlock.style.background = 'none'
      this.customColorContainer.hidden = true
    }

    // Main color select input onChange event
    this.colorSelect.onchange = () => {
      this.customColorContainer.hidden = this.colorSelect.value === 'custom' ? false : true
      if (this.colorSelect.value === 'custom') {
        this.colorSelect.className = 'form-control form-control-sm'
        this.customColorBlock.style.background = 'none'
        span.style.color = 'inherit'
      } else {
        this.colorSelect.className = `form-control form-control-sm bg-${
          this.colorSelect.value
        } text-${textDark.includes(this.colorSelect.value) ? 'dark' : 'white'}`
        span.style.color = `var(--${this.colorSelect.value})`
      }
    }

    // Custom color input onInput event
    this.customColorInput.oninput = () => {
      const customColorValue = this.customColorInput.value

      // Check if the value is valid
      if (isValidColor(customColorValue)) {
        const customColorValue = this.customColorInput.value
        this.customColorBlock.style.background = `${customColorValue}`
        this.customColorInput.style.background = '#FFFFFF'
        span.style.color = customColorValue
      } else {
        this.customColorBlock.style.background = 'FFFFFF'
        this.customColorInput.style.background = '#FFCCCB'
        this.customColorInput.style.color = 'var(--dark)'
        span.style.color = 'inherit'
      }
    }

    // Unhide the color picker
    this.colorPickerContainer.hidden = false
  }

  hideTextColorInput() {
    // Remove onChange events
    this.colorSelect.onchange = null
    this.customColorInput.oninput = null

    // Hide the inputs
    this.colorPickerContainer.hidden = true
  }

  // * Classname Tool
  renderClassNameInput() {
    const inputContainer = document.createElement('div')
    inputContainer.className = 'd-flex w-100 align-items-center'
    const icon = document.createElement('div')
    icon.className = 'mr-1'
    icon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-square-dashed-bottom-code">
        <path d="M10 9.5 8 12l2 2.5"/>
        <path d="M14 21h1"/>
        <path d="m14 9.5 2 2.5-2 2.5"/>
        <path d="M5 21a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2"/>
        <path d="M9 21h1"/>
      </svg>`

    // Create the custom classname input element
    const customClassnameInput = document.createElement('input')
    customClassnameInput.type = 'text'
    customClassnameInput.placeholder = 'Classname'
    customClassnameInput.className = 'form-control form-control-sm'

    const inputRow = document.createElement('div')
    inputRow.appendChild(customClassnameInput)

    inputContainer.appendChild(icon)
    inputContainer.appendChild(inputRow)

    // Store in class variables
    this.classnameContainer = inputContainer
    this.customClassnameInput = customClassnameInput

    return this.classnameContainer
  }

  showClassNameInput(span: HTMLSpanElement) {
    // Initiliase the input with the current classnames
    this.customClassnameInput.value = span.className

    // Custom classname input onInput event
    this.customClassnameInput.oninput = () => {
      // Update the span with the new classnames
      span.className = this.customClassnameInput.value
    }

    // Unhide the classname input
    this.classnameContainer.hidden = false
  }

  hideClassNameInput() {
    // Remove onChange events
    this.customClassnameInput.oninput = null

    // Hide the inputs
    this.classnameContainer.hidden = true
  }
}

export default CustomTools
