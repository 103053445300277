import GeneralSettingsTab from './components/Tabs/GeneralSettingsTab'
import ImageLayoutSettingsTab from './components/Tabs/ImageLayoutSettingsTab'
import { unique } from '../../../entries/utils'
import { generateRandomId } from '../../common/Utils'

export const tabs = [
  { name: 'General', component: GeneralSettingsTab },
  { name: 'Image Layout', component: ImageLayoutSettingsTab },
]

export const starters = {
  empty: {
    heading: undefined, // ! Deprecated
    subheading: undefined, // ! Deprecated
    type: 'plain',
    rounded: false,
    btnType: 'fill',
    btnColour: 'primary',
    isCarousel: false,
    cards: [],
    cardCols: '',
    objectFit: 'fill',
  },
  demo: {
    heading: undefined, // ! Deprecated
    subheading: undefined, // ! Deprecated
    type: 'shadow',
    rounded: true,
    btnType: 'outline',
    btnColour: 'primary',
    isCarousel: false,
    cards: [
      {
        id: generateRandomId(10),
        heading: 'Need a Service?',
        headingColor: {
          colorType: 'primary',
          customColor: '000000',
        },
        description:
          '​​The next time you need to have your vehicle serviced, visit us and put your mind at ease.',
        descriptionColor: {
          colorType: 'secondary',
          customColor: '000000',
        },
        href: '/service',
        image: {
          url: 'https://res.cloudinary.com/total-dealer/image/upload/v1681367513/production/z5odmu0za8wtq9woamhqe25pwv5g',
        },
        linkText: 'Book a Service',
        backgroundColor: 'ffffff',
      },
      {
        id: generateRandomId(10),
        heading: 'Looking for a Car?',
        headingColor: {
          colorType: 'primary',
          customColor: '000000',
        },
        description: `Explore our wide range of quality and affordable used cars at our dealership.`,
        descriptionColor: {
          colorType: 'secondary',
          customColor: '000000',
        },
        href: '/search/used-cars',
        image: {
          url: 'https://res.cloudinary.com/total-dealer/image/upload/v1681367514/production/u4w1v0szywvi4d0u099kref7c7a4',
        },
        linkText: 'Search Our Stock',
        backgroundColor: 'ffffff',
      },
    ],
    cardCols: '',
  },
  blogCta: {
    heading: undefined, // ! Deprecated
    subheading: undefined, // ! Deprecated
    type: 'shadow',
    rounded: true,
    isCarousel: false,
    cards: [
      {
        id: generateRandomId(10),
        heading: 'Book a Service',
        href: '/service',
        image: {
          url: 'https://res.cloudinary.com/total-dealer/image/upload/v1681367513/production/z5odmu0za8wtq9woamhqe25pwv5g',
        },
      },
      {
        id: generateRandomId(10),
        heading: 'Search Our Stock',
        href: '/search/pre-owned',
        image: {
          url: 'https://res.cloudinary.com/total-dealer/image/upload/v1681367514/production/u4w1v0szywvi4d0u099kref7c7a4',
        },
      },
    ],
    cardCols: '',
  },
}

export const defaultItem = () => {
  return {
    id: unique(),
    heading: 'Want To Learn More?',
    headingColor: {
      colorType: 'primary',
      customColor: '000000',
    },
    description: 'We have a team available to help you with whatever you need.',
    descriptionColor: {
      colorType: 'secondary',
      customColor: '000000',
    },
    linkText: 'Contact Us',
    href: '/contact',
    backgroundColor: 'ffffff',
  }
}
