export default class ContainerTune {
  static get isTune() {
    return true
  }

  constructor({ api, data, config, block }) {
    this.api = api
    this.block = block
    this.settings = config
    this.data = data || { contain: false }
  }

  render() {
    const wrapper = document.createElement('div')

    const toggleButton = document.createElement('div')
    toggleButton.className = 'ce-popover-item w-100 mb-1'

    toggleButton.innerHTML = `
        <div class="d-flex flex-row align-items-center">
          <div class="ce-popover-item__icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25" />
            </svg>
          </div>
          <div class="ce-popover-item__title">Contain</div>
        </div>
      `
    if (this.data.contain) {
      toggleButton.classList.add('ce-popover-item--active')
      const currentBlockIndex = this.api.blocks.getCurrentBlockIndex()
      const currentBlockHolder = this.api.blocks.getBlockByIndex(currentBlockIndex).holder
      currentBlockHolder.classList.add('px-10')
    }
    // Handle toggling data and UI
    toggleButton.addEventListener('click', () => {
      this.data.contain = !this.data.contain
      toggleButton.classList.toggle('ce-popover-item--active')
      const currentBlockIndex = this.api.blocks.getCurrentBlockIndex()
      const currentBlockHolder = this.api.blocks.getBlockByIndex(currentBlockIndex).holder
      currentBlockHolder.classList.toggle('px-10')
      this.settings.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    wrapper.appendChild(toggleButton)

    return wrapper
  }

  save() {
    return this.data
  }
}
