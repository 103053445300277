const BannerLayoutTypeTooltip = {
  content: (
    <div className="small">
      <p className="mb-1">
        <strong>Default:</strong> Our default Image Banner layout used on the majority of pages.
      </p>
      <p className="mb-1">
        <strong>Full-Size:</strong> Fit the entire image on the page at all screen sizes.
      </p>
      <p className="mb-1">
        <strong>Custom Height:</strong> Set custom mobile and desktop heights that are adhered to on
        all screen sizes.
      </p>
      <p className="mb-0">
        <strong>Custom Aspect Ratio:</strong> Choose a custom aspect ratio (width and height) that
        is maintained at all screen sizes.
      </p>
    </div>
  ),
}

const ImageLayoutTypeTooltip = {
  content: (
    <div className="small">
      <p className="mb-1">
        <strong>Cover:</strong> Cover the entire banner with the image (may cause clipping of the
        image).
      </p>
      <p className="mb-0">
        <strong>Contain:</strong> Contain the entire image within the banner (may cause empty spaces
        to appear).
      </p>
    </div>
  ),
}

export { BannerLayoutTypeTooltip, ImageLayoutTypeTooltip }
