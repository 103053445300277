import { generateRandomId } from '../../common/Utils'

// Constants
export const MIN_FAQS = 1

// Default block values
export const defaultBlocks = [
  {
    id: generateRandomId(10),
    type: 'header',
    data: {
      text: 'Heading...',
      level: 2,
    },
    tunes: {
      alignment: {
        alignment: 'left',
      },
      classname: {
        className: '',
      },
    },
  },
  {
    id: generateRandomId(10),
    type: 'paragraph',
    data: {
      text: 'Text block...',
    },
    tunes: {
      alignment: {
        alignment: 'left',
      },
      classname: {
        className: '',
      },
    },
  },
]

/**
 * Updates the steps of the editor to account for the number of active questions.
 * @param {*} data The current data object.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} QuestionEditor The editor component for the questions.
 */
export function refreshSteps(data, getUpdatedData, setSteps, QuestionEditor) {
  const tempSteps = []
  const questionNumber = data !== undefined ? getUpdatedData().questions?.length : 0

  // Add each questions as a step
  for (let index = 0; index < questionNumber; index++) {
    tempSteps.push({ name: `Q${index + 1}`, component: QuestionEditor })
  }

  setSteps(tempSteps)
}

/**
 * Handles the deletion of a question from the tool.
 * @param {*} index The index of the question to delete.
 * @param {*} getUpdatedData Function to get the updated data object.
 * @param {*} syncedStateUpdate Function to update the state of the editor.
 * @param {*} setActiveIndexState Function to set the active index of the editor.
 * @param {*} data The current data object.
 * @param {*} setSteps Function to set the steps of the editor.
 * @param {*} QuestionEditor The editor component for the questions.
 */
export function handleDeleteQuestion(
  index,
  getUpdatedData,
  syncedStateUpdate,
  setActiveIndexState,
  data,
  setSteps,
  QuestionEditor
) {
  // Confirm delete action
  if (
    window.confirm(
      'Are you sure you want to delete this question? This action will delete all data within this question.'
    )
  ) {
    const updatedQuestions = []
    getUpdatedData().questions.map((question, questionIndex) => {
      if (questionIndex !== index) {
        updatedQuestions.push(question)
      }
    })

    syncedStateUpdate({
      ...getUpdatedData(),
      questions: updatedQuestions,
    })
    setActiveIndexState({
      activeIndex: index === 0 ? 0 : index - 1,
      previousActiveIndex: undefined,
    })

    refreshSteps(data, getUpdatedData, setSteps, QuestionEditor)
  }
}

export function parseQuestions(questions) {
  let parsedQuestions = []

  questions?.map((question) => {
    const blocks = question.blocks
    const heading = blocks[0]
    const textBlocks = blocks.slice(1)
    parsedQuestions.push({ heading, textBlocks })
  })

  return parsedQuestions
}
