import { useState } from 'react'
import CarouselWrapper from '../../common/CarouselWrapper'

export const TabsPreview = ({ componentHeight, overlay, items }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const TabSection = ({ tab, index }) => (
    <div style={{ width: `${90 / items.length}%` }}>
      <div className="w-100 mb-1">{tab}</div>
      <div
        className={`w-100 bg-${activeIndex === index ? 'primary' : 'light'}`}
        style={{ height: 3 }}
      ></div>
    </div>
  )

  const HeadingSection = ({ heading, subheading, index }) => (
    <div className={`d-${activeIndex === index ? 'flex' : 'none'} flex-column`}>
      <p className="mb-0">{subheading}</p>
      <h3 className="mb-0">{heading}</h3>
    </div>
  )

  const DescriptionSection = ({ description, index }) => (
    <div className={`d-${activeIndex === index ? 'flex' : 'none'}`}>
      <p className="mb-0">{description}</p>
    </div>
  )

  return (
    <CarouselWrapper
      activeIndex={activeIndex}
      setActiveIndex={setActiveIndex}
      max={items.length - 1}
    >
      <div
        className="col-12 d-flex flex-column justify-content-between align-items-center relative text-white"
        style={{ height: componentHeight }}
      >
        {items[activeIndex]?.image?.url && (
          <div
            className="overflow-hidden"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundImage: `url(${items[activeIndex]?.image?.url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
            }}
          ></div>
        )}
        {overlay && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }}
          ></div>
        )}
        <div className="row w-100 pt-4 px-4">
          <div className="col-12 d-flex flex-row justify-content-between">
            {items.map((item, index) => (
              <TabSection key={item.tab + index} tab={item.tab} index={index} />
            ))}
          </div>
        </div>
        <div className="row w-100 align-items-end pb-4 px-4">
          <div className="col-5">
            {items.map((item, index) => (
              <HeadingSection
                key={item.heading + index}
                heading={item.heading}
                subheading={item.subheading}
                index={index}
              />
            ))}
          </div>
          <div className="col-7">
            {items.map((item, index) => (
              <DescriptionSection
                key={item.tab + 'Description' + index}
                description={item.description}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </CarouselWrapper>
  )
}
