import * as Routes from '../../../../routes'

import { PageDataProps, type WebsiteDetailsProps } from '../../types'

/**
 * Checks if an About Us page already exists, fetches it, and returns the blocks as an array
 *
 * @param websiteDetails - The website details object
 * @returns A promise that resolves to an array of blocks, or undefined if there are none
 */
export async function getAboutUsBlocks(websiteDetails: WebsiteDetailsProps) {
  try {
    // Fetch the page slugs
    const pagesData = await fetch(
      `${Routes.manufacturer_pages_path(websiteDetails.manufacturer_id)}.json`
    ).then((res) => res.json())

    // Get the about us page
    const aboutPage = pagesData.filter((page: PageDataProps) => page.slug === 'about-us')[0]

    // If the about us page doesn't exist, return undefined
    if (!aboutPage) return undefined

    // Get the blocks from the about us page
    const blocks = aboutPage.blocks_json

    // Return the blocks, or undefined if there are none
    return blocks ? JSON.parse(blocks) : undefined
  } catch (error) {
    console.error(error)
  }
}
