import FullFormPreview from '../../../tools/FormBuilderTool/FormBuilderPreview'
import Container from './Container'

const FormBuilderBlock = ({ block, locationOptions }) => {
  const { type, heading, description, fields, formSteps } = block?.data

  return (
    <Container tunes={block?.tunes} isInRow>
      <FullFormPreview
        type={type}
        heading={heading}
        description={description}
        fields={fields}
        steps={formSteps}
        locationOptions={locationOptions ?? []}
      />
    </Container>
  )
}

export default FormBuilderBlock
