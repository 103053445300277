import { default as React, useCallback, useState } from 'react'
import { createRoot } from 'react-dom/client'
import {
  ItemOption,
  LabeledInput,
  LabeledSelect,
  LabeledCheckbox,
  useActions,
  EmptyPlaceHolder,
  LabeledFileInput,
  ToolHeader,
} from '../../common'
import { renderEditSettingsButton, renderHiddenModalButton } from '../../common/Utils'

import Dialog from '../../common/Dialog'
import { unique } from '../../../entries/utils'

import { BoxesPreview, CardsPreview } from './Preview'

const initialState = (data) => {
  return {
    brands: data.brands || [],
    preview: true,
    heading: data.heading || undefined, // ! Deprecated in favour of using Heading components
    subheading: data.subheading || undefined, // ! Deprecated in favour of using Heading components
    variant: data.variant || 'card', // 'card' | 'box'
    theme: data.theme || undefined, // ! Deprecated in favour of using Tunes
  }
}

const defaultItem = () => {
  return {
    id: unique(),
    name: 'My Brand',
    isCustom: true,
    useCustomLogo: true,
    url: '',
  }
}

export const BrandEditor = ({
  index,
  item,
  variant,
  updateItem,
  deleteItem,
  moveUp,
  imageEndpoint,
}) => {
  const [edit, setEdit] = useState(true)

  const editable = updateItem && edit

  const showMissingLogoWarning = item.useCustomLogo && (!item.logo || !item.logo.url)

  return (
    <ItemOption
      edit={edit}
      index={index}
      item={item}
      title="Brand"
      hideBtnText
      setEdit={setEdit}
      moveUp={moveUp}
      deleteItem={deleteItem}
    >
      {editable ? (
        // Form field Inputs
        <div className="row">
          <div className="col-12 mt-2">
            {!item.isCustom && (
              <>
                <p className="text-danger">
                  If you want your logo to stay up-to-date with our updates, please leave this
                  unchecked.
                </p>
                <div className="pl-4">
                  <LabeledCheckbox
                    item={item}
                    itemName="useCustomLogo"
                    label="Use Custom Logo"
                    updateItem={updateItem}
                  />
                </div>
              </>
            )}
            {item.useCustomLogo && (
              <>
                <LabeledFileInput
                  item={item}
                  itemName="logo"
                  file={item.logo}
                  updateItem={updateItem}
                  imageEndpoint={imageEndpoint}
                  maxImageWidth={'200px'}
                  maxImageHeight={'200px'}
                />
                {showMissingLogoWarning && (
                  <p className="text-danger">
                    A custom logo must be provided for this brand to be shown.
                  </p>
                )}
              </>
            )}
          </div>
          <div className="col-12 mt-2">
            <LabeledInput item={item} itemName="name" label="Brand Name" updateItem={updateItem} />
          </div>
          <div className="col-12 mt-2">
            <LabeledInput item={item} itemName="url" label="Brand URL" updateItem={updateItem} />
          </div>
        </div>
      ) : (
        <div className="row justify-content-center brand-banner mb-2">
          {variant === 'card' ? (
            <CardsPreview key={item.id} item={item} isModalPreview={true} />
          ) : (
            <BoxesPreview key={item.id} item={item} />
          )}
        </div>
      )}
    </ItemOption>
  )
}

const RenderedBrandsTool = ({ data, toolInfo, onDataChange, imageEndpoint, uniqueId }) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState(initialState(data))
  const [items, setItems] = useState(data.brands || [])

  /**
   * Update the items that are added/removed
   */
  const syncedItemsUpdate = useCallback(
    (updatedItems) => {
      setItems([...updatedItems])
      onDataChange({
        brands: [...updatedItems],
      })
    },
    [onDataChange]
  )

  /**
   * Update the common fields such as section heading or subheading
   */
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({
        ...item,
      })
      onDataChange(item)
    },
    [onDataChange]
  )

  const actions = useActions({
    items,
    defaultItem,
    syncedUpdate: syncedItemsUpdate,
  })

  const { heading, subheading, variant, theme } = state

  return (
    <>
      <div className={theme === 'dark' ? 'bg-dark' : ''}>
        <div className={`container ${heading && subheading && theme ? ' my-4' : ''}`}>
          {heading && (
            <h2 className={`text-center ${subheading ? ' mb-2' : ' mb-4'}`}>{heading}</h2>
          )}
          {subheading && <h6 className="text-center text-muted mb-4">{subheading}</h6>}
          <div className={`row justify-content-center px-3 px-md-0 brand-banner`}>
            {items?.map((item) => {
              // Don't render if custom logo is missing
              if (item.useCustomLogo && (!item.logo || !item.logo.url)) return

              return variant === 'card' ? (
                <CardsPreview key={item.id} item={item} />
              ) : (
                <BoxesPreview key={item.id} item={item} />
              )
            })}
          </div>
        </div>
      </div>
      <Dialog title="Brands" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} addItem={actions.addItem} />
        <div className="pt-3 border-top">
          <div className="row">
            {/* Deprecated in favour of using Heading components */}
            {heading && (
              <div className="col-12 col-md-6">
                <LabeledInput
                  controlled={false}
                  item={state}
                  itemName="heading"
                  label="Section Heading"
                  updateItem={syncedStateUpdate}
                />
              </div>
            )}
            {/* Deprecated in favour of using Heading components */}
            {subheading && (
              <div className="col-12 col-md-6">
                <LabeledInput
                  controlled={false}
                  item={state}
                  itemName="subheading"
                  label="Section Subheading"
                  updateItem={syncedStateUpdate}
                />
              </div>
            )}
            <div className="col-12 col-md-6">
              <LabeledSelect
                controlled={false}
                item={state}
                itemName="variant"
                label="Brand Display"
                updateItem={syncedStateUpdate}
                options={['card', 'box']}
              />
            </div>
            {/* Deprecated in favour of using Tunes */}
            {theme && (
              <div className="col-12 col-md-6">
                <LabeledSelect
                  controlled={false}
                  item={state}
                  itemName="theme"
                  label="Background Theme"
                  updateItem={syncedStateUpdate}
                  options={['light', 'dark', 'coloured']}
                />
              </div>
            )}
          </div>
          <div className="row">
            {items?.length > 0 ? (
              items.map((item, id) => (
                <div
                  className={
                    'col-lg-6 ' + (id !== items.length - 1 && id !== items.length - 2 ? 'mb-3' : '')
                  }
                  key={item.id}
                >
                  <BrandEditor
                    index={id}
                    item={item}
                    imageEndpoint={imageEndpoint}
                    variant={variant}
                    {...actions}
                  />
                </div>
              ))
            ) : (
              <EmptyPlaceHolder itemName="brand" />
            )}
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class BrandsTool {
  constructor({ data, api, config, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    const defaultData = {
      brands: config.brands,
      heading: undefined, // ! Deprecated in favour of using Heading components
      subheading: undefined, // ! Deprecated in favour of using Heading components
      variant: 'card',
      theme: undefined, // ! Deprecated in favour of using Tunes
    }

    this.data = Object.keys(data).length ? data : defaultData

    // Update brands that aren't using custom logos
    this.data.brands.map((brand) => {
      if (!brand.useCustomLogo) {
        const updatedLogo = config.brands.filter((updatedBrand) => updatedBrand.id === brand.id)[0]
          ?.logo

        brand.logo = updatedLogo
      }
    })

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText:
        'Show the Brands associated with your dealership. This includes manufacturers and additional websites. The default brands are based on your websites in our system, but you can fully customise this as much as you like.',
      itemName: 'Brand',
      hideToggle: true,
    }
  }

  static get toolbox() {
    return {
      title: 'Brands',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288" />
      </svg>      
      `,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedBrandsTool
        onDataChange={onDataChange}
        data={this.data}
        toolInfo={this.toolInfo}
        imageEndpoint={this.config.imageUrl}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  async save() {
    return this.data
  }
}

export default BrandsTool
