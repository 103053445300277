import Cleave from 'cleave.js'
import "cleave.js/dist/addons/cleave-phone.au";
import { ready } from './utils'

function initCleave() {
  $('.cleave-currency').toArray().forEach(function (field) {
    new Cleave(field, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      prefix: '$',
      swapHiddenInput: true,
      rawValueTrimPrefix: true
    });
  })

  $('.cleave-kms').toArray().forEach(function (field) {
    new Cleave(field, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      prefix: ' kms',
      tailPrefix: true,
      swapHiddenInput: true,
      rawValueTrimPrefix: true
    });
  })

  $('.cleave-phone').toArray().forEach(function (field) {
    new Cleave(field, {
      phone: true,
      phoneRegionCode: 'AU',
      swapHiddenInput: true
    });
  })
}

ready(function () {
  initCleave()
})

window.initCleave = initCleave