import { MIN_ROWS } from './utils'

const AddRowTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">Use this to add a new row.</p>
    </div>
  ),
}

const DeleteRowTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">Use this to delete a row. The minimum number of rows is {MIN_ROWS}.</p>
    </div>
  ),
}

const ReverseTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">This will reverse the ordering of the alternating columns.</p>
    </div>
  ),
}

const VariantTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        This will change your alternating columns to the variant version. With this version, each
        row is wrapped in an alternating background (i.e. grey, white, grey, white, and so on).
      </p>
    </div>
  ),
}

export { AddRowTooltip, DeleteRowTooltip, ReverseTooltip, VariantTooltip }
