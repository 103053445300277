export type CustomComponentDataProps = {
  formData: {
    uniqueComponentId?: string
  }
}

const defaultComponentOptions = [] // Example: { label: 'Test', value: 'unique-component-id' }

export function fetchComponentOptions(templateType: string) {
  const componentOptions = [...defaultComponentOptions]

  // ? Eventually want to add logic to fetch component options for a dealership & website
  // ? This would require the addition of a new DB table where we can store their unqiue component IDs

  if (templateType === 'careers') {
    componentOptions.push({
      label: 'Careers - Positions Available',
      value: 'careers-positions-available-default',
    })
  }

  return componentOptions
}

import { useEffect, useRef, useState } from 'react'

export function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState('')
  const timerRef = useRef<undefined | NodeJS.Timeout>()

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [value, delay])

  return debouncedValue
}
