import { useEffect, useState } from 'react'
import { LabeledNumberInput } from '../../../common'
import ImageBannerPreview from '../ImageBannerPreview'
import InputLabel from '../../../common/InputLabel'
import { BannerLayoutTypeTooltip, ImageLayoutTypeTooltip } from '../ImageBannerTooltips'

// Layout type options
const layoutTypeOptions = [
  { value: 'default', label: 'Default' },
  { value: 'fullSize', label: 'Full-Size' },
  { value: 'customHeight', label: 'Custom Height' },
  { value: 'customAspectRatio', label: 'Custom Aspect Ratio' },
]

// Image layout options
const imageLayoutOptions = [
  { value: 'cover', label: 'Cover' },
  { value: 'contain', label: 'Contain' },
]

/**
 * The BannerLayoutStep component controls the layout of the Banner and the Image.
 * @param {*} stepIndex the index of this step
 * @param {*} isActive whether this step is active
 * @param {*} isModalOpen whether the modal is open
 * @param {*} activeIndexState an object tracking the previous and current active indexes
 * @param {*} state the current state of the ImageBanner instance
 * @param {*} syncedStateUpdate function to sync the state of the ImageBanner instance with the saved data
 * @param {*} onDataChange function to handle saving data changes
 * @param {*} getUpdatedData function to get the updated saved data
 * @returns The Banner Layout step with all required fields and previews
 */
const BannerLayoutStep = ({
  stepIndex,
  isActive,
  isModalOpen,
  activeIndexState,
  state,
  syncedStateUpdate,
  onDataChange,
  getUpdatedData,
}) => {
  // Handle component states
  const [layoutState, setLayoutState] = useState(state?.layout)
  const [customHeightState, setCustomHeightState] = useState(
    state?.layout?.customHeight ?? undefined
  )
  const [aspectRatioState, setAspectRatioState] = useState(
    state?.layout?.customAspectRatio ?? undefined
  )

  // Save state updates when changing away from this step or closing the modal
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()
      syncedStateUpdate({ ...updatedData, layout: { ...updatedData?.layout, ...layoutState } })
    }
  }, [isActive, isModalOpen])

  // Save data when layout state is updated
  useEffect(() => {
    onDataChange({
      ...state,
      layout: layoutState,
    })
  }, [layoutState])

  const handleLayoutTypeChange = (e) => {
    setLayoutState({
      ...layoutState,
      type: e.target.value,
    })
  }

  const handleImageLayoutChange = (e) => {
    setLayoutState({
      ...layoutState,
      imageLayout: e.target.value,
    })
  }

  // Handle custom height changes
  useEffect(() => {
    setLayoutState({
      ...layoutState,
      customHeight: customHeightState,
    })
  }, [customHeightState])

  // Handle custom aspect ratio changes
  useEffect(() => {
    setLayoutState({
      ...layoutState,
      customAspectRatio: aspectRatioState,
    })
  }, [aspectRatioState])

  return (
    <div className={isActive ? 'd-block' : 'd-none'}>
      <h6>Banner Layout</h6>
      <div className="row">
        {/* Layout Type Step */}
        <div className="col-12 mb-3">
          <InputLabel
            label="Select a banner layout type"
            htmlFor="bannerLayout-select"
            tooltip={BannerLayoutTypeTooltip}
          />
          <select
            className="form-control d-block"
            id="bannerLayout-select"
            defaultValue={layoutState?.type ?? ''}
            onChange={(e) => handleLayoutTypeChange(e)}
          >
            <option value="">Select an option</option>
            {layoutTypeOptions?.map((type, index) => (
              <option key={`${type?.value}-${index}`} value={type?.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
        {/* Custom Height Step */}
        {layoutState?.type && layoutState?.type === 'customHeight' && (
          <>
            <div className="col-6 mb-3">
              <LabeledNumberInput
                item={layoutState?.customHeight}
                itemName="mobileHeight"
                label="Please set a height value for the image banner on mobile"
                placeholder="Mobile Height Value"
                updateItem={setCustomHeightState}
                min={1}
              />
            </div>
            <div className="col-6 mb-3">
              <LabeledNumberInput
                item={layoutState?.customHeight}
                itemName="desktopHeight"
                label="Please set a height value for the image banner on desktop"
                placeholder="Desktop Height Value"
                updateItem={setCustomHeightState}
                min={1}
              />
            </div>
          </>
        )}
        {/* Custom Aspect Ratio Step */}
        {layoutState?.type && layoutState?.type === 'customAspectRatio' && (
          <>
            <div className="col-6 mb-3">
              <LabeledNumberInput
                item={layoutState?.customAspectRatio}
                itemName="height"
                label="Please set a height value for the image banner aspect ratio"
                placeholder="Height Value"
                updateItem={setAspectRatioState}
              />
            </div>
            <div className="col-6 mb-3">
              <LabeledNumberInput
                item={layoutState?.customAspectRatio}
                itemName="width"
                label="Please set a width value for the image banner aspect ratio"
                placeholder="Width Value"
                updateItem={setAspectRatioState}
              />
            </div>
          </>
        )}
        {/* Image Layout Step */}
        {layoutState?.type &&
          layoutState?.type !== 'fullSize' &&
          (layoutState?.type === 'customAspectRatio'
            ? layoutState?.customAspectRatio !== undefined
            : true) && (
            <div className="col-12 mb-3">
              <InputLabel
                label="Select an image layout option"
                htmlFor="imageLayoutType-select"
                tooltip={ImageLayoutTypeTooltip}
              />
              <select
                className="form-control d-block"
                id="imageLayoutType-select"
                defaultValue={layoutState?.imageLayout ?? ''}
                onChange={(e) => handleImageLayoutChange(e)}
              >
                <option value="">Select an option</option>
                {imageLayoutOptions?.map((type, index) => (
                  <option key={`${type?.value}-${index}`} value={type?.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        {/* Image Preview */}
        <div className="col-12 mt-2 mb-1">
          <ImageBannerPreview
            state={state}
            customLayoutState={layoutState}
            showOverlay={false}
            showContent={false}
          />
        </div>
      </div>
    </div>
  )
}

export default BannerLayoutStep
