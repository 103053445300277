import { MIN_FAQS } from './utils'

const AddQuestionTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">Use this to add a new question.</p>
    </div>
  ),
}

const DeleteQuestionTooltip = {
  content: (
    <div className="small">
      <p className="mb-0">
        Use this to delete a question. The minimum number of questions is {MIN_FAQS}.
      </p>
    </div>
  ),
}

export { AddQuestionTooltip, DeleteQuestionTooltip }
