import React, { useState } from 'react'
import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'
import { Cross } from './Icons'

export const LabeledMultipleSelectInput = ({
  item,
  itemName,
  label,
  updateItem,
  customAdd,
  customDelete,
  selectOptions,
  minOptions = undefined,
  tooltip,
}) => {
  const [selectedOption, setSelectedOption] = useState('')
  const [options, setOptions] = useState(item[itemName] || [])

  const add = () => {
    if (customAdd) {
      customAdd(options, itemName, selectedOption, setSelectedOption, setOptions)
      return
    }
    if (options.includes(selectedOption)) {
      alert('You have already added ' + selectedOption)
    } else if (selectedOption.length > 0) {
      setOptions([...options, selectedOption])
      updateItem({ ...item, [itemName]: [...options, selectedOption] })
      setSelectedOption('')
    }
  }

  const handleDelete = (option) => {
    if (customDelete) {
      customDelete(options, itemName, option, setOptions)
      return
    }
    const filteredOptions = options.filter((o) => o !== option)
    setOptions(filteredOptions)
    updateItem({ ...item, [itemName]: filteredOptions })
  }

  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <div className="d-flex">
        <select
          id={itemName}
          className="form-control"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="" disabled>
            Select an option
          </option>
          {selectOptions?.map((option, index) => (
            <option key={`${option.label} ${index}`} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <button onClick={add} className="btn btn-sm btn-primary mx-2">
          Add
        </button>
      </div>
      <div className="d-flex align-items-center mt-3 flex-wrap">
        {options?.map((option, id) => (
          <span
            key={`input-item-${id}`}
            className={`small bg-light rounded d-flex align-items-center mb-1 py-1 px-2 mr-1`}
          >
            {selectOptions.filter((select) => `${select.value}` === option)[0]?.label}{' '}
            <div
              className={`btn btn-sm btn-outline-danger align-items-center ml-1 ${
                minOptions && options.length <= minOptions ? 'd-none' : 'd-flex'
              }`}
              onClick={() => handleDelete(option)}
            >
              <Cross size="15px" />
            </div>
          </span>
        ))}
      </div>
    </FormGroup>
  )
}
