import { default as React, useCallback, useState } from 'react'
import { createRoot } from 'react-dom/client'
import {
  ItemOption,
  LabeledInput,
  LabeledNumberInput,
  useActions,
  EmptyPlaceHolder,
  LabeledFileInput,
  ToolHeader,
} from '../common'
import { renderEditSettingsButton, renderHiddenModalButton } from '../common/Utils'
import Dialog from '../common/Dialog'

import { capitalise, unique } from '../../entries/utils'

const starters = {
  empty: {
    slides: [],
  },
}

const initialState = (data) => {
  return {
    slides: data.slides || [],
    preview: false, // switch to true if preview is created
    imageHeight: data.imageHeight,
    imageWidth: data.imageWidth,
    activated: !!data.activated || data.slides.length > 0,
    starter: 'empty', // we could have starters based on OEM promotions
  }
}

const defaultItem = () => {
  return {
    id: unique(),
    alt: '',
    linkText: '',
    href: '',
  }
}

const ButtonPreview = ({ type, colour, children }) => {
  const className = `btn btn-${type === 'outline' ? 'outline-' : ''}${colour}`
  return <button className={className}>{children}</button>
}

/**
 * ! TODO - make a preview
 **/
const ImageSlidePreview = ({ item, type, rounded, btnType, btnColour }) => {
  const containerClass = `m-2 bg-white ${
    type === 'border' ? 'border' : type === 'shadow' ? 'shadow-lg' : ''
  } ${rounded ? 'rounded' : ''}`
  return (
    <div className={containerClass}>
      {item?.image?.url ? (
        <img className="w-100" src={item?.image?.url} alt={item.heading} />
      ) : (
        <span className="text-danger">Please upload an image...</span>
      )}
      <div className="p-3">
        <h4>{item.heading}</h4>
        <p className="text-muted">{item.description}</p>
        {item.linkText && (
          <ButtonPreview type={btnType ?? 'fill'} colour={btnColour ?? 'primary'}>
            {item.linkText}
          </ButtonPreview>
        )}
      </div>
    </div>
  )
}

export const ImageSlideEditor = ({
  index,
  item,
  type,
  rounded,
  updateItem,
  deleteItem,
  moveUp,
  imageEndpoint,
}) => {
  const [edit, setEdit] = useState(true)

  const editable = updateItem && edit

  return (
    <ItemOption
      edit={edit}
      index={index}
      item={item}
      title="Image Slide"
      hideBtnText
      setEdit={setEdit}
      moveUp={moveUp}
      deleteItem={deleteItem}
      hidePreview
    >
      {editable ? (
        // Form field Inputs
        <div className="row">
          <div className="col-12 mt-2">
            <LabeledFileInput
              item={item}
              itemName="image"
              file={item.image}
              updateItem={updateItem}
              imageEndpoint={imageEndpoint}
            />
          </div>
          <div className="col-12 mt-2">
            <LabeledInput
              item={item}
              itemName="alt"
              label="Alt Text"
              placeholder="Image Alt Text..."
              updateItem={updateItem}
            />
          </div>
          <div className="col-12 mt-2">
            <LabeledInput item={item} itemName="href" label="Link URL" updateItem={updateItem} />
          </div>
          <div className="col-12 mt-2">
            <LabeledInput
              item={item}
              itemName="linkText"
              label="Link Text"
              updateItem={updateItem}
            />
          </div>
        </div>
      ) : (
        <ImageSlidePreview
          item={item}
          rounded={rounded}
          type={type}
          btnColour={btnColour}
          btnType={btnType}
        />
      )}
    </ItemOption>
  )
}

const RenderedImageSliderComponent = ({
  data,
  toolInfo,
  onDataChange,
  imageEndpoint,
  uniqueId,
}) => {
  const [show, setShow] = useState(data.slides.length > 0 ? false : true)
  const [state, setState] = useState(initialState(data))
  const [items, setItems] = useState(data.slides || [])
  const [starter, setStarter] = useState('empty')

  /**
   * Update the items that are added/removed
   */
  const syncedItemsUpdate = useCallback(
    (updatedItems) => {
      setItems([...updatedItems])
      onDataChange({
        slides: [...updatedItems],
      })
    },
    [onDataChange]
  )

  /**
   * Update the common fields such as section heading or subheading
   */
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({
        ...item,
        preview: state.preview,
        starter: state.starter,
      })
      onDataChange(item)
    },
    [state.preview, state.starter, onDataChange]
  )

  const actions = useActions({
    items,
    defaultItem,
    syncedUpdate: syncedItemsUpdate,
  })

  const addStarterContent = () => {
    const initialBlocks = starters[starter]
    onDataChange({ ...initialBlocks, activated: true })
    setItems(initialBlocks.slides)
    syncedStateUpdate({ ...initialBlocks, activated: true })
  }

  const { heading, subheading, rounded, type, activated, btnColour, btnType } = state

  return (
    <>
      {activated ? (
        items.length > 0 ? (
          items?.map((item) => (
            <div className="col-lg-6" key={item.id + 'preview'}>
              <ImageSlidePreview
                key={item.id}
                item={item}
                type={type}
                rounded={rounded}
                btnColour={btnColour}
                btnType={btnType}
              />
            </div>
          ))
        ) : (
          <span className="text-danger">Please add some slides...</span>
        )
      ) : (
        <span className="text-danger">Please select a started option...</span>
      )}
      <Dialog title="Image Slider" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} addItem={!activated ? undefined : actions.addItem} />
        <div className="pt-3 border-top">
          <div className="row">
            {activated ? (
              <>
                <div className="col-12 col-md-6 mb-3">
                  <LabeledNumberInput
                    controlled={false}
                    item={state}
                    itemName="imageHeight"
                    label={
                      <>
                        Image Height (px)
                        <br />
                        <small className="text-muted">
                          *Images should all have the same aspect ratio
                        </small>
                      </>
                    }
                    placeholder="auto*"
                    updateItem={syncedStateUpdate}
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <LabeledNumberInput
                    controlled={false}
                    item={state}
                    itemName="imageWidth"
                    label={
                      <>
                        Image Width (px)
                        <br />
                        <small className="text-muted">
                          *Images should all have the same aspect ratio
                        </small>
                      </>
                    }
                    placeholder="100%*"
                    updateItem={syncedStateUpdate}
                  />
                </div>

                {items?.length > 0 ? (
                  items.map((item, id) => (
                    <div
                      className={`col-lg-6 ${id === items.length - 1 ? '' : 'mb-3'}`}
                      key={item.id}
                    >
                      <ImageSlideEditor
                        index={id}
                        item={item}
                        rounded={rounded}
                        type={type}
                        imageEndpoint={imageEndpoint}
                        {...actions}
                      />
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    <EmptyPlaceHolder itemName="image slide" />
                  </div>
                )}
              </>
            ) : (
              <div className="col-12 col-md-6 col-lg-4">
                <div className="form-group">
                  <label htmlFor="starter" className="form-label">
                    Select a starter option
                  </label>
                  <select
                    id={'starter'}
                    className="form-control"
                    value={starter}
                    onChange={(e) => setStarter(e.target.value)}
                  >
                    {Object.keys(starters).map((option) => (
                      <option key={option} value={option}>
                        {capitalise(option)}
                      </option>
                    ))}
                  </select>
                </div>
                <button className="btn btn-primary" onClick={addStarterContent}>
                  Get Started
                </button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ImageSliderTool {
  constructor({ data, api, config, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    const defaultData = {
      activated: false,
      slides: [],
      imageHeight: null,
      imageWidth: null,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText: `Display a series of promotional images in a carousel.`,
      itemName: 'Image Slide',
      hideToggle: true,
    }
  }

  static get toolbox() {
    return {
      title: 'Image Slider',
      icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="M262.3 199.2c-1.6-2.8-5.6-3.2-7.7-.7l-91.9 122.2c-2.5 2.9-.6 7.4 3.2 7.7l161.1 14c3.8.3 6.4-3.8 4.5-7.1l-69.2-136.1zM367.2 264.1c-1.6-2.8-5.6-3.2-7.7-.7l-24.8 25.1a4.68 4.68 0 0 0-.5 5.4l25.4 49.5c.8 1.3 2.1 2.2 3.7 2.3l44.9 3.9c3.8.3 6.4-3.8 4.5-7.1l-45.5-78.4zM378.1 224.4c11.2-.1 20.9-8.3 23-19.2 2.8-14.8-8.6-28.3-23.7-28.1-11.2.1-20.9 8.3-23 19.2-2.8 14.8 8.6 28.3 23.7 28.1z"></path>
        <path d="M455.2 129.3l-65.8-5.7-6.1-67c-1.3-14.9-14.5-25.9-29.5-24.5L56.7 58.9c-14.9 1.3-25.9 14.5-24.6 29.4l26.8 296.5c1.3 14.9 14.5 25.9 29.5 24.5l15.7-1.4-1.5 16.7c-1.3 14.9 9.7 28 24.7 29.3l297.3 25.9c14.9 1.3 28.1-9.7 29.4-24.6l26-296.6c1.2-14.8-9.8-28-24.8-29.3zM87.6 300.7c-3.7.3-7-2.4-7.4-6.1l-18-200c-.3-3.7 2.4-7 6.1-7.3l279.2-25.1c3.7-.3 7 2.4 7.4 6.1l4.8 52.8L158 103.4c-14.9-1.3-28.1 9.7-29.4 24.6l-14.9 170.3-26.1 2.4zm362.2-135.6l-17.5 200c-.3 3.7-3.6 6.5-7.3 6.2l-18.6-1.6L145.7 347c-3.7-.3-6.5-3.6-6.2-7.3l3.8-43.9L157 139.7c.3-3.7 3.6-6.5 7.3-6.2l198 17.3 29.7 2.6 51.6 4.5c3.8.2 6.6 3.5 6.2 7.2z"></path>
      </svg>`,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedImageSliderComponent
        onDataChange={onDataChange}
        data={this.data}
        toolInfo={this.toolInfo}
        imageEndpoint={this.config.imageUrl}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  async save() {
    return this.data
  }
}

export default ImageSliderTool
