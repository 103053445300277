// Determine what steps have been completed and which are available
export function handleActiveSteps(data) {
  let activeIndex = 0
  activeIndex = data.image?.image?.url ? 1 : 0 // Check if image url is set
  // Check if layout type and image layout are set
  if (activeIndex > 0 && data.layout.type && data.layout.imageLayout) {
    if (data.layout.type === 'customAspectRatio') {
      activeIndex = data.layout.customAspectRatio ? 2 : 1
    } else {
      activeIndex = 2
    }
  }
  // Check if overlay is set
  if (activeIndex > 1 && data.overlay.active !== undefined) {
    activeIndex = 3
  }
  return activeIndex
}

// Provide an array of available steps based on the data
export function getAvailableSteps(data) {
  let activeIndex = handleActiveSteps(data)
  let availableSteps = [0]

  for (let i = 1; i <= activeIndex; i++) {
    availableSteps.push(i)
  }

  return availableSteps
}
