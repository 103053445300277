import { default as React, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { ToolHeader } from '../common'
import { capitalize, renderEditSettingsButton, renderHiddenModalButton } from '../common/Utils'
import Dialog from '../common/Dialog'

import { capitalise, unique } from '../../entries/utils'
import PreviewWarning from '../common/PreviewWarning'
import {
  BrandsDataProps,
  DefaultConfigProps,
  NodesProps,
  ToolboxProps,
  ToolInfoProps,
} from '../types'
import { BlockAPI } from '@editorjs/editorjs'

const RenderedModelsComponent = ({
  onDataChange,
  data,
  brands,
  toolInfo,
  uniqueId,
  config,
}: {
  onDataChange: ({ brand }: { brand: string }) => void
  data: { brand: string }
  brands: BrandsDataProps[]
  toolInfo: ToolInfoProps
  uniqueId: string
  config: DefaultConfigProps
}) => {
  const [show, setShow] = useState(data.brand === '' || !data.brand ? true : false)
  const [selectedBrand, setSelectedBrand] = useState(data.brand)

  return (
    <>
      <PreviewWarning
        type={selectedBrand === '' || !selectedBrand ? 'danger' : 'warning'}
        message={
          selectedBrand === '' || !selectedBrand
            ? 'Please select a manufacturer'
            : `${capitalize(selectedBrand)} Models section preview is not yet available.`
        }
      />
      <Dialog title="Models Showcase" show={show} closeClickHandler={() => setShow(false)}>
        <>
          {/* @ts-expect-error */}
          <ToolHeader {...toolInfo} />
          <div className="pt-3 border-top">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label htmlFor="brand-select">Brand Option</label>
                  <select
                    className="form-control"
                    id="brand-select"
                    value={selectedBrand}
                    onChange={(e) => {
                      setSelectedBrand(e.target.value)
                      onDataChange({ brand: e.target.value })
                    }}
                  >
                    <option value="">Select a brand</option>
                    {brands?.map((brand) => (
                      <option key={brand.id} value={`${brand.slug}`}>
                        {brand.name}
                      </option>
                    ))}
                    {brands?.length === 0 && config?.ownerManufacturerId && (
                      <option value={`${config.ownerManufacturerId}`}>
                        {capitalise(config.ownerManufacturerId)}
                      </option>
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ModelsTool {
  private config: DefaultConfigProps
  private blockAPI: BlockAPI
  private uniqueId: string
  private data: {
    brand: string
  }
  private nodes: NodesProps
  private toolInfo: ToolInfoProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Models',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
      </svg>`,
    }
  }

  constructor({ data, config, block }) {
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    const defaultValue = {
      brand: '',
    }

    this.data = Object.keys(data).length ? data : defaultValue

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText:
        'Select a brand and show all the models associated with that manufacturer. The available options are based on OEM websites under your dealership.',
      hideToggle: true,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData: { brand: string }) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedModelsComponent
        onDataChange={onDataChange}
        data={this.data}
        brands={this.config?.brands}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
        config={this.config}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default ModelsTool
