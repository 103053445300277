import { useState } from 'react'
import CarouselWrapper from '../../common/CarouselWrapper'
import { ModelProps, ModelVariantProps } from '../../types'
import PreviewWarning from '../../common/PreviewWarning'

const ModelVariantColPreview = ({
  modelVariant,
  manufacturer,
  index,
  activeIndex,
}: {
  modelVariant: ModelVariantProps
  manufacturer: string
  index: number
  activeIndex: number
}) => (
  <div
    className={`col-4 ${
      index > activeIndex - 1 && index < activeIndex + 3 ? 'd-flex' : 'd-none'
    } flex-column`}
  >
    <img src={modelVariant.image?.url} />
    <h5 className="mt-2">
      {manufacturer} {modelVariant.full_name}
    </h5>
    <ul className="small mb-0">
      {modelVariant.features?.slice(0, 3).map((feature, index) => (
        <li key={`${modelVariant.name}-Feature-${index}`}>{feature}</li>
      ))}
      {modelVariant.features?.length > 4 && <span className="text-primary">View more</span>}
    </ul>
    <button className="btn btn-outline-primary btn-block mt-2">Get a Quote</button>
  </div>
)

const ModelVariantsPreview = ({
  selectedModelData,
  selectedModel,
  generatedPreview,
}: {
  selectedModelData: ModelProps | undefined
  selectedModel: string
  generatedPreview: boolean
}) => {
  const [activeIndex, setActiveIndex] = useState(0)

  return selectedModelData && selectedModelData.model_variants.length > 0 ? (
    <div className="mt-3 container">
      <h2 className="text-center">
        Which {selectedModelData.manufacturer_name} {selectedModelData.name} will you choose?
      </h2>
      <CarouselWrapper
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        hideButtons={selectedModelData?.model_variants?.length < 4}
        max={selectedModelData?.model_variants?.length - 3}
        loop={false}
      >
        <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="row justify-content-center">
              {selectedModelData?.model_variants?.map((modelVariant, index) => (
                <ModelVariantColPreview
                  key={`ModelVariantPreview-${index}`}
                  modelVariant={modelVariant}
                  manufacturer={selectedModelData.manufacturer_name}
                  index={index}
                  activeIndex={activeIndex}
                />
              ))}
            </div>
          </div>
        </div>
      </CarouselWrapper>
    </div>
  ) : (
    <PreviewWarning
      type={!selectedModel ? 'danger' : 'warning'}
      message={
        !selectedModel
          ? 'Please select a model and generate a preview.'
          : generatedPreview
          ? 'There is no variants data for this model.'
          : 'Your selections have changed, please generate a new preview.'
      }
    />
  )
}

export default ModelVariantsPreview
