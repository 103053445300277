import { default as React, useCallback, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { useActions } from '../../common'
import { capitalise, unique } from '../../../entries/utils'
import { renderEditSettingsButton, renderHiddenModalButton, renderTabs } from '../../common/Utils'
import Dialog from '../../common/Dialog'
import GridCardSection from './components/GridCardSection'
import CardsPreview from './components/CardsPreview'
import { tabs, starters, defaultItem } from './cardSettingsConfig'
import { getColClassName, getColPerRow } from './utils'
import { Add } from '../../common/Icons'
import Tooltip from '../../common/Tooltip'

const initialState = (data) => {
  return {
    cards: data.cards || [],
    isCarousel: data.isCarousel || false,
    preview: true,
    heading: data.heading,
    subheading: data.subheading,
    type: data.type,
    rounded: data.rounded,
    btnType: data?.btnType || 'fill',
    btnColour: data?.btnColour || 'primary',
    imageHeight: data.imageHeight,
    imageWidth: data.imageWidth,
    activated: !!data.activated || data.cards.length > 0,
    cardCols: data.cardCols || '',
    starter: 'empty',
    layoutType: data.layoutType ?? 'default',
    objectFit: data.objectFit ?? 'cover',
    aspectRatioHeight: data.aspectRatioHeight,
    aspectRatioWidth: data.aspectRatioWidth,
  }
}

const Cards = ({ data, onDataChange, imageEndpoint, uniqueId }) => {
  const [show, setShow] = useState(data.activated ? false : true)
  const [state, setState] = useState(initialState(data))
  const [items, setItems] = useState(data.cards || [])
  const [starter, setStarter] = useState('empty')
  const [activeIndexState, setActiveIndexState] = useState({
    activeIndex: 0,
    previousActiveIndex: undefined,
  })

  /**
   * Update the items that are added/removed
   */
  const syncedItemsUpdate = useCallback(
    (updatedItems) => {
      setItems([...updatedItems])
      setState({ ...state, cards: [...updatedItems] })
      onDataChange({
        ...state,
        cards: [...updatedItems],
      })
    },
    [onDataChange, state]
  )

  // Handle conditional state updates based on layoutType selection
  const updateStateBasedOnLayoutType = (currentItemState, updatedValues) => {
    const baseUpdatedState = { ...currentItemState, ...updatedValues }

    // Reset previous set properties based on the selected layoutType
    if (updatedValues.layoutType) {
      switch (updatedValues.layoutType) {
        case 'default':
          return {
            ...baseUpdatedState,
            imageHeight: null,
            imageWidth: null,
            aspectRatioHeight: null,
            aspectRatioWidth: null,
          }
        case 'customAspectRatio':
          return {
            ...baseUpdatedState,
            imageHeight: null,
            imageWidth: null,
          }
        case 'customSize':
          return {
            ...baseUpdatedState,
            aspectRatioHeight: null,
            aspectRatioWidth: null,
          }
        default:
          break
      }
    }

    return baseUpdatedState
  }

  /**
   * Update the common fields such as section heading or subheading
   */
  const syncedStateUpdate = useCallback(
    (updatedItemValues) => {
      const newState = updateStateBasedOnLayoutType(state, updatedItemValues)

      setState((prevState) => ({
        ...newState,
        preview: updatedItemValues.preview ?? prevState.preview,
        starter: updatedItemValues.starter ?? prevState.starter,
      }))
      onDataChange(newState)
    },
    [state, onDataChange]
  )

  const actions = useActions({
    items,
    defaultItem,
    syncedUpdate: syncedItemsUpdate,
  })

  const addStarterContent = () => {
    const initialBlocks = starters[starter]
    onDataChange({ ...initialBlocks, activated: true })
    setItems(initialBlocks.cards)
    syncedStateUpdate({ ...initialBlocks, activated: true })
  }

  const { heading, subheading, activated, cardCols } = state

  const colClassName =
    cardCols && typeof cardCols === 'string'
      ? `col-sm-6 col-lg-${cardCols}`
      : getColClassName(items?.length)

  const colPerRow = Number(cardCols) ? 12 / Number(cardCols) : getColPerRow(items?.length)

  const ActiveTabComponent = tabs[activeIndexState.activeIndex]?.component

  return (
    <>
      {activated ? (
        <>
          {items.length > 0 ? (
            <GridCardSection
              heading={heading}
              description={subheading}
              className="container"
              rowClass="row d-flex align-items-stretch justify-content-center"
            >
              {items?.map((item, i) => {
                return (
                  <div key={`${item.heading}-${i}`} className={`mb-4 col-12 ${colClassName}`}>
                    <CardsPreview colPerRow={colPerRow} item={item} state={state} {...item} />
                  </div>
                )
              })}
            </GridCardSection>
          ) : (
            <div className="col-12 mb-2">
              <span className="badge badge-warning">Warning</span>{' '}
              <span className="small">
                No cards component will appear on your page because there are no cards added.
              </span>
            </div>
          )}
        </>
      ) : (
        <div className="col-12 mb-2">
          <span className="badge badge-warning">Warning</span>{' '}
          <span className="small">Please select a starter option.</span>
        </div>
      )}
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
      <Dialog title="Cards" show={show} closeClickHandler={() => setShow(false)}>
        {activated ? (
          <>
            {renderTabs(
              tabs,
              activeIndexState,
              setActiveIndexState,
              undefined,
              <Tooltip
                tooltipTrigger={
                  <button
                    className="btn btn-small btn-success d-flex align-items-center ml-2"
                    onClick={() => syncedItemsUpdate([...items, defaultItem()])}
                  >
                    <Add size={25} />
                  </button>
                }
                tooltipContent="Add Card"
                width={200}
              />
            )}
            <ActiveTabComponent
              state={state}
              syncedStateUpdate={syncedStateUpdate}
              items={items}
              imageEndpoint={imageEndpoint}
              actions={actions}
              colPerRow={colPerRow}
            />
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-md-8 col-lg-6 col-xl-4">
                <div className="form-group">
                  <label htmlFor="starter" className="form-label">
                    Select a starter option
                  </label>
                  <select
                    id={'starter'}
                    className="form-control"
                    value={starter}
                    onChange={(e) => setStarter(e.target.value)}
                  >
                    {Object.keys(starters).map((option) => (
                      <option key={option} value={option}>
                        {capitalise(option)}
                      </option>
                    ))}
                  </select>
                </div>
                <button className="btn btn-primary" onClick={addStarterContent}>
                  Get Started
                </button>
              </div>
              {/* Preview starter options */}
              {starters[starter]?.cards?.length > 0 && (
                <div className="col-12 mt-4">
                  <h3>Starter Preview</h3>
                  <div className="row d-flex align-items-stretch justify-content-center">
                    {starters[starter]?.cards?.map((item, i) => (
                      <div key={`${item.heading}-${i}`} className={`mb-4 col-12 col-sm-6`}>
                        <CardsPreview key={i} item={item} state={starters[starter]} colPerRow="2" />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </Dialog>
    </>
  )
}

class CardsTool {
  constructor({ data, api, config, block }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.uniqueId = unique()

    const defaultData = {
      activated: false,
      isCarousel: false,
      cards: [],
      heading: undefined, // ! Deprecated
      subheading: undefined, // ! Deprecated
      type: 'plain',
      rounded: false,
      imageHeight: null,
      imageWidth: null,
      cardCols: '',
      layoutType: 'default',
      objectFit: 'cover',
      aspectRatioHeight: null,
      aspectRatioWidth: null,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.nodes = {
      holder: null,
    }
  }

  static get toolbox() {
    return {
      title: 'Cards',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
      </svg>`,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <Cards
        onDataChange={onDataChange}
        data={this.data}
        imageEndpoint={this.config.imageUrl}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  async save() {
    return this.data
  }
}

export default CardsTool
