import React from 'react';

const NumericMenu = ({ dateOptions, dateFilter, setDateFilter }) => (
  <select
    onChange={event => setDateFilter(event.currentTarget.value)}
    className="ais-HitsPerPage-select"
    value={dateFilter}
  >
    {dateOptions.map(item => (
      <option 
        key={item.start}
        className="ais-HitsPerPage-option"
        value={item.label}
      >
        {item?.label}
      </option>
    ))}
  </select>
);

export default NumericMenu