import EditorJS from '@editorjs/editorjs'
import { unique } from '../entries/utils'
import { generateRandomId } from './common/Utils'

class ImageBackgroundTool {
  constructor({ data, config }) {
    this.config = config
    this.editors = { image: null, content: null }

    const defaultData = {
      image: {
        blocks: [
          {
            id: generateRandomId(10),
            type: 'image',
            data: {
              file: {
                url: 'https://res.cloudinary.com/total-dealer/image/upload/v1/production/shzdc6si4ly2co8y8e3a4atn2i8a.webp',
                height: 504,
                width: 1512,
              },
              caption: 'Buying a car on a laptop from the comfort of your home',
              withBorder: false,
              stretched: false,
              withBackground: false,
            },
          },
        ],
      },
      content: { blocks: [] },
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.editorsWrapper = undefined

    this.toolInfo = {
      heading: 'Image Background Section',
      helpText: 'Add a background image to be displayed behind the content below.',
      itemName: 'Image',
    }

    this.nodes = {
      holder: null,
    }
  }

  static get toolbox() {
    return {
      title: 'Image Background',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6" /></svg>',
    }
  }

  onDataChange(newData) {
    this.data = {
      ...this.data,
      ...newData,
    }
    this.config.save()
  }

  /** UI component for editing the content*/
  ContentEditor = (index) => {
    const container = document.createElement('div')
    const id = unique() + index
    container.id = id
    container.className = 'p-2 mt-3 rounded border bg-white p-3'
    return container
  }

  /** UI component for the tool container and tool header */
  ToolContainer() {
    const Container = document.createElement('div')
    Container.className = 'bg-light my-3 rounded border p-3 shadow-sm'
    const Header = document.createElement('div')
    Header.className = 'd-flex justify-content-between flex-wrap'
    Header.innerHTML = `<div>
      <h4>${this.toolInfo.heading}</h4>
      <p class="text-muted">${this.toolInfo.helpText}</p>
    </div>`
    Container.appendChild(Header)
    return Container
  }

  /** Empty the array of editors */
  clearEditors() {
    this.editors.content.destroy()
    this.editors.image.destroy()
    this.editors = { content: null, image: null }
  }

  /** Create an Editor.js instance for the image and the  question and append it to the `editorsWrapper` */
  generateEditors() {
    // Container for both editors
    const row = document.createElement('div')

    // Image editor container
    const heading1 = document.createElement('h6')
    heading1.className = 'text-muted my-2'
    heading1.innerText = `BACKGROUND IMAGE`
    const imgEditorDiv = document.createElement('div')
    imgEditorDiv.className = 'border my-1 col-12'
    const img_id = unique()
    imgEditorDiv.id = img_id
    row.appendChild(heading1)
    row.appendChild(imgEditorDiv)

    // Content editor container
    const heading2 = document.createElement('h6')
    heading2.className = 'text-muted my-2'
    heading2.innerText = `CONTENT`
    const contentEditorDiv = document.createElement('div')
    contentEditorDiv.className = 'border my-1 col-12'
    const content_id = unique()
    contentEditorDiv.id = content_id
    row.appendChild(heading2)
    row.appendChild(contentEditorDiv)

    const columnEditor = this.ContentEditor(1)
    columnEditor.appendChild(row)

    this.editorsWrapper.appendChild(columnEditor)

    // Removes duplication paste issue
    this.editorsWrapper.addEventListener(
      'paste',
      (event) => {
        event.stopPropagation()
      },
      true
    )

    // default column settings start with text on left
    const imageHolder = img_id
    const textHolder = content_id

    const imageData = this.data.image || { blocks: [] }
    const contentData = this.data.content || { blocks: [] }

    const { image, ...restOfTools } = this.config.tools

    const imageEditorjsInstance = new EditorJS({
      defaultBlock: 'image',
      placeholder: 'Add your image...',
      holder: imageHolder,
      tools: { image },
      data: imageData,
      minHeight: 50,
    })

    const tools = this.config.tools

    const contentEditorjsInstance = new EditorJS({
      defaultBlock: 'paragraph',
      placeholder: 'Add your content...',
      holder: textHolder,
      tools: restOfTools,
      data: contentData,
      minHeight: 50,
      onChange: () => {
        this.config.save()
      },
    })

    this.editors = {
      image: imageEditorjsInstance,
      content: contentEditorjsInstance,
    }
  }

  render() {
    // create and scaffold the container elements
    const rootNode = document.createElement('div')
    rootNode.className = 'image-background-editor'
    const EditorsContainer = this.ToolContainer()
    rootNode.appendChild(EditorsContainer)
    this.editorsWrapper = document.createElement('div')
    this.nodes.holder = rootNode

    // add the two editors
    this.generateEditors()

    EditorsContainer.appendChild(this.editorsWrapper)

    // Block event propagation
    document.addEventListener(
      'keydown',
      function (event) {
        // If the event occurred inside the columns section editor, then apply the logic
        if (document.activeElement.closest('.image-background-editor')) {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.stopImmediatePropagation()
            console.log('ENTER Captured')
          }
          if (event.key === 'Tab') {
            event.stopImmediatePropagation()
            console.log('TAB Captured')
          }
          // Seems impossible to fix the duplicate paste issue do to Editor.js using custom paste processing:
          // https://github.com/codex-team/editor.js/blob/next/src/components/modules/paste.ts
          if (event.ctrlKey && (event.key === 'v' || event.key === 'V')) {
            //event.preventDefault()
            event.stopImmediatePropagation()
            console.log('CTRL + V Captured')
          }
          if (event.metaKey && (event.key === 'v' || event.key === 'V')) {
            //event.preventDefault()
            event.stopImmediatePropagation()
            console.log('CMD + V Captured')
          }
        }
      },
      true
    )

    return this.nodes.holder
  }

  async save() {
    // Save each Editor.js instance
    try {
      const contentData = await this.editors.content.save()
      this.data.content = contentData
      const imageData = await this.editors.image.save()
      this.data.image = imageData
    } catch (error) {
      console.log('Possible saving error: ', error)
    }

    return this.data
  }
}

export default ImageBackgroundTool
