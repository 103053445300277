import { generateRandomId } from '../../common/Utils'

function getCareersFormTemplate() {
  return {
    type: 'single',
    category: 'General Enquiry',
    heading: 'Apply Now!',
    description: '',
    formSteps: [
      {
        key: 0,
        heading: '',
        description: '',
        id: generateRandomId(10),
      },
    ],
    fields: [
      {
        id: generateRandomId(10),
        name: 'name',
        type: 'text',
        label: 'Full Name',
        placeholder: 'Full Name',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'phone',
        type: 'phone',
        label: 'Mobile Number',
        placeholder: 'Mobile',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'email',
        type: 'email',
        label: 'Email Address',
        placeholder: 'Email',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'message',
        name: 'message',
        label: 'Message',
        placeholder: 'Message',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'select',
        name: 'position',
        label: 'Position of Interest',
        placeholder: 'Position of Interest',
        required: true,
        options: ['Managerial', 'Administration', 'Sales', 'Service', 'Parts', 'Other'],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'file',
        name: 'fileField',
        label: 'Resume',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'check',
        name: 'opt_in',
        label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 0,
        defaultChecked: true,
      },
      {
        id: generateRandomId(10),
        type: 'hidden',
        name: 'subcategory',
        label: 'Example',
        placeholder: 'Enter your details...',
        required: true,
        options: [],
        specialValue: 'Career Enquiry',
        stepKey: 0,
      },
    ],
  }
}

function getContactFormTemplate() {
  return {
    type: 'single',
    category: 'General Enquiry',
    heading: 'Contact Us',
    description: '',
    formSteps: [
      {
        key: 0,
        heading: 'Contact Us',
        description: '',
        id: generateRandomId(10),
      },
    ],
    fields: [
      {
        id: generateRandomId(10),
        name: 'name',
        type: 'text',
        label: 'Full Name',
        placeholder: 'Full Name',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'phone',
        type: 'phone',
        label: 'Mobile Number',
        placeholder: 'Mobile',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'email',
        type: 'email',
        label: 'Email Address',
        placeholder: 'Email',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'message',
        name: 'Comments',
        label: 'Comments',
        placeholder: 'Comments',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'check',
        name: 'opt_in',
        label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 0,
        defaultChecked: true,
      },
    ],
  }
}

function getFinanceFormTemplate() {
  return {
    type: 'single',
    category: 'Finance Enquiry',
    heading: '',
    description: '',
    formSteps: [
      {
        key: 1,
        heading: '',
        description: '',
        id: generateRandomId(10),
      },
      {
        key: 0,
        heading: '',
        description: '',
        id: generateRandomId(10),
      },
    ],
    fields: [
      {
        id: generateRandomId(10),
        type: 'currency',
        name: 'vehiclePriceBudget',
        label: 'Budget',
        placeholder: 'Vehicle Budget',
        required: true,
        options: [
          '$5,000',
          '$10,000',
          '$15,000',
          '$20,000',
          '$25,000',
          '$30,000',
          '$40,000',
          '$50,000',
          '$70,000',
        ],
        specialValue: '',
        stepKey: 1,
        help: 'You can borrow from $5,000 up to $150,000.',
      },
      {
        id: generateRandomId(10),
        name: 'name',
        type: 'text',
        label: 'Full Name',
        placeholder: 'Full Name',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'phone',
        type: 'phone',
        label: 'Mobile Number',
        placeholder: 'Mobile',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'email',
        type: 'email',
        label: 'Email Address',
        placeholder: 'Email',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'dob',
        name: 'dateOfBirth',
        label: 'Date of Birth',
        placeholder: 'Date of Birth',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'number',
        name: 'driverLicenceNumber',
        label: 'Driver Licence Number',
        placeholder: 'Driver Licence',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'address',
        name: 'Your Current Address',
        label: 'Your Current Address',
        placeholder: 'Your Current Address',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'check',
        name: 'opt_in',
        label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 0,
        defaultChecked: false,
      },
    ],
  }
}

function getFleetFormTemplate() {
  return {
    type: 'single',
    category: 'Fleet Enquiry',
    heading: 'Get In Touch',
    description: '',
    formSteps: [
      {
        key: 0,
        heading: 'Get In Touch',
        description: '',
        id: generateRandomId(10),
      },
    ],
    fields: [
      {
        id: generateRandomId(10),
        name: 'name',
        type: 'text',
        label: 'Full Name',
        placeholder: 'Full Name',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'phone',
        type: 'phone',
        label: 'Mobile Number',
        placeholder: 'Mobile',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'email',
        type: 'email',
        label: 'Email Address',
        placeholder: 'Email',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'message',
        name: 'Comments',
        label: 'Comments',
        placeholder: 'Comments',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'check',
        name: 'opt_in',
        label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 0,
        defaultChecked: true,
      },
    ],
  }
}

function getPartsFormTemplate() {
  return {
    type: 'multi',
    category: 'Parts Enquiry',
    heading: 'Make an enquiry',
    description: '',
    formSteps: [
      {
        key: 0,
        heading: 'Contact Details',
        description: '',
        id: generateRandomId(10),
      },
      {
        key: 1,
        heading: 'Vehicle Details',
        description: '',
        id: generateRandomId(10),
      },
      {
        key: 2,
        heading: 'Order Details',
        description: '',
        id: generateRandomId(10),
      },
    ],
    fields: [
      {
        id: generateRandomId(10),
        name: 'name',
        type: 'text',
        label: 'Full Name',
        placeholder: 'Full Name',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'phone',
        type: 'phone',
        label: 'Mobile Number',
        placeholder: 'Mobile',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'email',
        type: 'email',
        label: 'Email Address',
        placeholder: 'Email',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'make',
        name: 'Make',
        label: 'Make',
        placeholder: 'Make',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'text',
        name: 'Model',
        label: 'Model',
        placeholder: 'Model',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'year',
        name: 'Year',
        label: 'Year',
        placeholder: 'Year',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'text',
        name: 'Rego or VIN',
        label: 'Rego or VIN',
        placeholder: 'Rego or VIN',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'message',
        name: 'Order Detail',
        label: 'Order Detail',
        placeholder: 'Enter your order details...',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 2,
      },
      {
        id: generateRandomId(10),
        type: 'check',
        name: 'opt_in',
        label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 2,
        defaultChecked: true,
      },
    ],
  }
}

function getDefaultServiceFormTemplate() {
  return {
    type: 'multi',
    category: 'Service Enquiry',
    heading: 'Book Your Service',
    description: '',
    formSteps: [
      {
        key: 0,
        heading: 'Contact Details',
        description: '',
        id: generateRandomId(10),
      },
      {
        key: 1,
        heading: 'Vehicle Details',
        description: '',
        id: generateRandomId(10),
      },
      {
        key: 2,
        heading: 'Preferred Date and Time',
        description: '',
        id: generateRandomId(10),
      },
    ],
    fields: [
      {
        id: generateRandomId(10),
        name: 'name',
        type: 'text',
        label: 'Full Name',
        placeholder: 'Full Name',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'phone',
        type: 'phone',
        label: 'Mobile Number',
        placeholder: 'Mobile',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'email',
        type: 'email',
        label: 'Email Address',
        placeholder: 'Email',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'make',
        name: 'Make',
        label: 'Make',
        placeholder: 'Make',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'text',
        name: 'Model',
        label: 'Model',
        placeholder: 'Model',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'year',
        name: 'Year',
        label: 'Year',
        placeholder: 'Year',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'text',
        name: 'Vehicle Rego',
        label: 'Vehicle Rego',
        placeholder: 'Vehicle Rego',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'serviceInterval',
        name: 'Service Interval',
        label: 'Service Interval',
        placeholder: 'Please enter service interval in Kms or Months/Years',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'dateTime',
        name: 'Service Date',
        dateInputName: 'Preferred Service Date',
        timeInputName: 'Preferred Time',
        label: 'Service Date',
        placeholder: 'Service Date',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 2,
      },
      {
        id: generateRandomId(10),
        type: 'message',
        name: 'Comments',
        label: 'Comments',
        placeholder: 'Comments',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 2,
      },
      {
        id: generateRandomId(10),
        type: 'check',
        name: 'opt_in',
        label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 2,
        defaultChecked: true,
      },
    ],
  }
}

function getNevdisServiceFormTemplate() {
  return {
    type: 'multi',
    category: 'Service Enquiry',
    heading: 'Book Your Service',
    description: '',
    formSteps: [
      {
        key: 1,
        heading: 'Vehicle Details',
        description: '',
        id: generateRandomId(10),
      },
      {
        key: 0,
        heading: 'Contact Details',
        description: '',
        id: generateRandomId(10),
      },
      {
        key: 2,
        heading: 'Preferred Date and Time',
        description: '',
        id: generateRandomId(10),
      },
    ],
    fields: [
      {
        id: generateRandomId(10),
        type: 'rego',
        name: 'Vehicle Rego',
        label: 'Vehicle Rego',
        placeholder: 'Vehicle Rego',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'serviceInterval',
        name: 'Service Interval',
        label: 'Service Interval',
        placeholder: 'Please enter service interval in Kms or Months/Years',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'name',
        type: 'text',
        label: 'Full Name',
        placeholder: 'Full Name',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'phone',
        type: 'phone',
        label: 'Mobile Number',
        placeholder: 'Mobile',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'email',
        type: 'email',
        label: 'Email Address',
        placeholder: 'Email',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'dateTime',
        name: 'Service Date',
        label: 'Service Date',
        placeholder: 'Service Date',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 2,
      },
      {
        id: generateRandomId(10),
        type: 'message',
        name: 'Comments',
        label: 'Comments',
        placeholder: 'Comments',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 2,
      },
      {
        id: generateRandomId(10),
        type: 'check',
        name: 'opt_in',
        label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 2,
        defaultChecked: true,
      },
    ],
  }
}

function getDefaultTradeInFormTemplate() {
  return {
    type: 'multi',
    category: 'Trade-in Enquiry',
    heading: 'Make an enquiry',
    description: '',
    formSteps: [
      {
        key: 0,
        heading: 'Contact Details',
        description: '',
        id: generateRandomId(10),
      },
      {
        key: 1,
        heading: 'Vehicle Details',
        description: '',
        id: generateRandomId(10),
      },
    ],
    fields: [
      {
        id: generateRandomId(10),
        name: 'name',
        type: 'text',
        label: 'Full Name',
        placeholder: 'Full Name',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'phone',
        type: 'phone',
        label: 'Mobile Number',
        placeholder: 'Mobile',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        name: 'email',
        type: 'email',
        label: 'Email Address',
        placeholder: 'Email',
        required: true,
        stepKey: 0,
      },
      {
        id: generateRandomId(10),
        type: 'make',
        name: 'Make',
        label: 'Make',
        placeholder: 'Make',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'text',
        name: 'Model',
        label: 'Model',
        placeholder: 'Model',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'year',
        name: 'Year',
        label: 'Year',
        placeholder: 'Year',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'text',
        name: 'Kms',
        label: 'Kms',
        placeholder: 'Kms',
        required: true,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'message',
        name: 'Comments',
        label: 'Comments',
        placeholder: 'Comments',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 1,
      },
      {
        id: generateRandomId(10),
        type: 'check',
        name: 'opt_in',
        label: 'Yes, I would like to subscribe to receive latest offers & product updates.',
        placeholder: 'Enter your details...',
        required: false,
        options: [],
        specialValue: '',
        stepKey: 1,
        defaultChecked: true,
      },
    ],
  }
}

// Available Form Templates
export const formTemplates = [
  { name: 'General', value: 'contact' },
  { name: 'Finance', value: 'finance' },
  { name: 'Default Service', value: 'service-default' },
  { name: 'Nevdis Service', value: 'service-nevdis' },
  { name: 'Default Trade In', value: 'trade-in-default' },
  { name: 'Parts', value: 'parts' },
  { name: 'Careers', value: 'careers' },
  { name: 'Fleet', value: 'fleet' },
]

/**
 * Get the form template data for the requested type
 * @param type - The type of form template being requested
 * @returns The form template data for the requested type
 */
export function getFormTemplate(type: string) {
  switch (type) {
    case 'careers':
      return getCareersFormTemplate()
    case 'contact':
      return getContactFormTemplate()
    case 'finance':
      return getFinanceFormTemplate()
    case 'fleet':
      return getFleetFormTemplate()
    case 'parts':
      return getPartsFormTemplate()
    case 'service-default':
      return getDefaultServiceFormTemplate()
    case 'service-nevdis':
      return getNevdisServiceFormTemplate()
    case 'trade-in-default':
      return getDefaultTradeInFormTemplate()
    default:
      return undefined
  }
}
