import { handleColorType } from '../../FinanceCalculatorTool/utils'
import ButtonPreview from './ButtonPreview'
import CardsBase from './CardsBase'

const CardsPreview = ({ item, colPerRow, state }) => {
  const {
    rounded,
    type,
    btnColour,
    btnType,
    imageHeight,
    imageWidth,
    objectFit,
    aspectRatioHeight,
    aspectRatioWidth,
  } = state

  const roundedStyle = rounded
    ? {
        borderTopRightRadius: 'var(--card-radius)',
        borderTopLeftRadius: 'var(--card-radius)',
      }
    : {}

  const isSmallImage = imageHeight && imageWidth && imageHeight < 200 && imageWidth < 200

  const additionalImgStyles = {
    width: imageWidth ?? '100%',
    height: imageHeight ?? '100%',
    objectFit: objectFit,
    overflow: 'hidden',
    margin: isSmallImage ? '1rem auto' : '0 auto',
  }

  const style = {
    ...(item.image?.style ?? roundedStyle),
    ...additionalImgStyles,
  }

  // Calculate the default aspect ratio if specific image height is set.
  const defaultAspectRatio = imageHeight
    ? // Max container width = 1440px. Divide by columns and account for padding (30px per card)
      `${(1440 - 30 * colPerRow) / colPerRow} / ${imageHeight}`
    : undefined

  // Determine the final aspect ratio, using custom dimensions if available (set through ImageLayoutSettingsTab)
  const aspectRatio =
    aspectRatioWidth && aspectRatioHeight
      ? `${aspectRatioWidth} / ${aspectRatioHeight}`
      : defaultAspectRatio

  // Conditionally render text container
  const hasTextContent = item.heading || item.description || item.linkText

  return (
    <CardsBase type={type} rounded={rounded}>
      <div
        className="position-relative overflow-hidden"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          aspectRatio: aspectRatio,
        }}
      >
        {' '}
        {item?.image?.url && (
          <img
            style={style}
            src={item?.image.url}
            alt={`${item.image.heading} 
      Image`}
          />
        )}
      </div>
      {hasTextContent && (
        <div
          style={{
            position: 'relative',
            padding: '1rem 1.5rem',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            backgroundColor: item.backgroundColor && handleColorType(item.backgroundColor),
          }}
        >
          <h4
            style={{
              textTransform: 'none',
              color: item.headingColor && handleColorType(item.headingColor),
            }}
          >
            {item.heading}
          </h4>
          <p
            className="mt-2"
            style={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              color: item.descriptionColor && handleColorType(item.descriptionColor),
            }}
          >
            {item.description}
          </p>
          {item.linkText && (
            <div className="mt-2">
              <ButtonPreview type={btnType ?? 'fill'} colour={btnColour ?? 'primary'}>
                {item.linkText}
              </ButtonPreview>
            </div>
          )}
        </div>
      )}
    </CardsBase>
  )
}

export default CardsPreview
