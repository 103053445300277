import EditorJsRenderer from '../EditorJsRenderer'
import { generateRandomId } from '../../Utils'
import { COL_KEYS } from '../../../tools/AlternatingColumnsTool/utils'
import { getColor } from '../utils'

function getCustomStyles(customisations) {
  return customisations
    ? {
        padding: `${customisations.topPadding / 2}rem ${customisations.rightPadding / 2}rem ${
          customisations.bottomPadding / 2
        }rem ${customisations.leftPadding / 2}rem`,
        backgroundColor: getColor(
          customisations.backgroundColor.colorType,
          `#${customisations.backgroundColor.customColor}`
        ),
        color: customisations?.textColor
          ? getColor(customisations.textColor.colorType, `#${customisations.textColor.customColor}`)
          : 'inherit',
        justifyContent: customisations.verticalAlignment ?? undefined,
      }
    : {}
}

const AlternatingColumnsBlock = ({ block }) => {
  return (
    <div className="container-fluid">
      {block?.columns?.map((row, index) => {
        // Handles image customisations
        row[COL_KEYS[1]]?.blocks
          .filter((block) => block.type === 'image')
          .forEach((block) => {
            block.data.imageLayout = row[COL_KEYS[1]]?.customisations?.imageLayout ?? 'contain'
          })

        // Handles reverse logic
        const textData = block.reverse ? row[COL_KEYS[1]] : row[COL_KEYS[0]]
        const imgData = block.reverse ? row[COL_KEYS[0]] : row[COL_KEYS[1]]

        return (
          <div
            key={`alternating-columns-row-${index}-${generateRandomId(5)}`}
            className={
              block?.variant ? (index % 2 === 0 ? 'bg-light' : 'bg-white') + ' row py-4' : 'row'
            }
          >
            <div className="col-12">
              <div className="container">
                <div className={`row ${block?.variant ? 'bg-white border border-light' : ''}`}>
                  <div
                    className={`col-12 mb-3 col-md-6 mb-md-0 ${
                      (index % 2 === 0 && !block.reverse) || (index % 2 === 1 && block.reverse)
                        ? 'd-flex flex-column align-items-center order-2 order-md-1'
                        : 'order-1'
                    } ${
                      ((index % 2 === 1 && !block.reverse) || (index % 2 === 0 && block.reverse)) &&
                      block?.variant
                        ? 'px-0'
                        : ''
                    }
                  `}
                    style={getCustomStyles(
                      index % 2 === 0 ? textData.customisations : imgData.customisations
                    )}
                  >
                    <EditorJsRenderer
                      data={{
                        blocks: index % 2 === 0 ? textData.blocks : imgData.blocks,
                      }}
                      isAlternatingColumns
                    />
                  </div>
                  <div
                    className={`col-12 mb-3 col-md-6 mb-md-0 ${
                      (index % 2 === 1 && !block.reverse) || (index % 2 === 0 && block.reverse)
                        ? 'd-flex flex-column align-items-center order-2 order-md-1'
                        : 'order-1 order-md-2'
                    } ${
                      ((index % 2 === 0 && !block.reverse) || (index % 2 === 1 && block.reverse)) &&
                      block?.variant
                        ? 'px-0'
                        : ''
                    }`}
                    style={getCustomStyles(
                      index % 2 === 0 ? imgData.customisations : textData.customisations
                    )}
                  >
                    <EditorJsRenderer
                      data={{
                        blocks: index % 2 === 0 ? imgData.blocks : textData.blocks,
                      }}
                      isAlternatingColumns
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AlternatingColumnsBlock
