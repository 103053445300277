import { generateRandomId } from '../../common/Utils'
import { ManufacturerProps } from '../../types'

/**
 * Generates a banner configuration based on the first promotion
 * in the manufacturer's object, prioritizing video over image.
 * Returns null if no valid banner is found.
 *
 * @param {*} manufacturer - The manufacturer object with promotions.
 * @returns {Array} - Array containing config for video or image banner.
 */
function handleBanner(manufacturer: ManufacturerProps) {
  const bannerImage = manufacturer.promotions[0]?.image?.url
    ? manufacturer.promotions[0]?.image
    : null
  const bannerVideo =
    manufacturer.promotions[0]?.video?.url &&
    manufacturer.promotions[0]?.video?.url !==
      'https://res.cloudinary.com/total-dealer/video/upload/v1/development/' &&
    !bannerImage
      ? manufacturer.promotions[0]?.video
      : null

  return bannerVideo
    ? [
        {
          id: generateRandomId(10),
          type: 'video',
          data: {
            useCustom: false,
            isManufacturerPage: manufacturer.name,
            url: bannerVideo?.url,
          },
          tunes: {
            container: {
              contain: false,
            },
            margin: {
              spacingTop: 0,
              spacingBottom: 0,
            },
            screensize: {
              screenSize: 'all',
            },
            classname: {
              className: '',
            },
          },
        },
      ]
    : bannerImage
    ? [
        {
          id: generateRandomId(10),
          type: 'imageBanner',
          data: {
            image: {
              type: 'default',
              existingCategory: 'manufacturer',
              existingFilters: {
                manufacturer: manufacturer.id,
              },
              image: {
                url: 'default',
                previewUrl: bannerImage?.url,
              },
            },
            layout: {
              type: 'fullSize',
              imageLayout: 'cover',
              customAspectRatio: {
                height: 1,
                width: 4,
              },
              customHeight: {
                mobileHeight: 250,
                desktopHeight: 450,
              },
            },
            overlay: {
              active: 'false',
              color: '#000000',
              opacity: 50,
            },
            content: {
              active: 'false',
              blocks: [
                {
                  id: generateRandomId(10),
                  type: 'header',
                  data: {
                    text: 'Image Banner',
                    level: 2,
                  },
                  tunes: {
                    alignment: {
                      alignment: 'center',
                    },
                    container: {
                      contain: true,
                    },
                    textColor: {
                      textColor: 'light',
                      customTextColor: '#000000',
                    },
                    margin: {
                      spacingTop: 5,
                      spacingBottom: 5,
                    },
                    padding: {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                    classname: {
                      className: '',
                    },
                  },
                },
              ],
            },
          },
          tunes: {
            margin: {
              spacingTop: 0,
              spacingBottom: 0,
            },
            classname: {
              className: '',
            },
          },
        },
      ]
    : []
}

export { handleBanner }
