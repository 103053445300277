import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'

export const LabeledTextarea = ({
  controlled = true,
  item,
  itemName,
  placeholder,
  label,
  rows,
  updateItem,
  customOnChange,
  tooltip,
}) => {
  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <textarea
        id={itemName}
        rows={rows || 3}
        placeholder={placeholder || `Enter ${itemName}...`}
        className="form-control"
        value={controlled ? item[itemName] : undefined}
        defaultValue={!controlled ? item[itemName] : undefined}
        onChange={(e) =>
          customOnChange ? customOnChange(e) : updateItem({ ...item, [itemName]: e.target.value })
        }
      />
    </FormGroup>
  )
}
