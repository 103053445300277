import EditorJS from '@editorjs/editorjs'
import { unique } from '../../../entries/utils'
import { useState, useCallback, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { bootstrapColours, LabeledCheckbox, LabeledSelect, ToolHeader } from '../../common'
import Dialog from '../../common/Dialog'
import {
  renderHiddenModalButton,
  renderTabs,
  generateRandomId,
  handleEmbeddedEditorActions,
  renderEditSettingsButton,
} from '../../common/Utils'
import { initialiseStyling } from '../../common/Utils'
import Tooltip from '../../common/Tooltip'
import { Add } from '../../common/Icons'

import {
  MIN_ROWS,
  COL_KEYS,
  defaultImageBlocks,
  defaultTextBlocks,
  refreshSteps,
  handleDeleteRow,
  handleAddRow,
  handleUpdateColumn,
  DEFAULT_IMAGE_CUSTOMISATIONS,
  DEFAULT_TEXT_CUSTOMISATIONS,
} from './utils'
import {
  AddRowTooltip,
  DeleteRowTooltip,
  ReverseTooltip,
  VariantTooltip,
} from './AlternatingColumnsTooltips'

import AlternatingColumnsBlock from '../../common/EditorJsRenderer/blocks/AlternatingColumnsBlock'
import { getTunes } from '../../editorConfig'
import { Slider } from 'primereact/slider'
import { TabView, TabPanel } from 'primereact/tabview'
import { ColorPicker } from 'primereact/colorpicker'

const ColumnEditor = ({
  colKey,
  stepIndex,
  isActive,
  isModalOpen,
  activeColState,
  onDataChange,
  getUpdatedData,
  syncedStateUpdate,
  config,
}) => {
  // ! Embedded edtior components can't have any internal state management
  const editorJsID = generateRandomId(5)
  const colState = getUpdatedData()?.columns[stepIndex][colKey]

  // Div that holds the embedded editor instance
  const ContentEditorHolder = () => (
    <div
      className="w-100 rounded border position-relative modal-embedded-editor-js-instance"
      id={editorJsID}
    ></div>
  )

  const contentEditorjsInstance = new EditorJS({
    defaultBlock: colKey === 'textCol' ? 'paragraph' : 'image',
    placeholder: 'Add your content...',
    holder: editorJsID,
    tools:
      colKey === 'textCol' ? config.tools : { image: config.tools.image, ...getTunes(config.save) },
    data: { blocks: colState?.blocks ?? [] },
    minHeight: 50,
    onReady: () => {
      if (colState?.blocks) {
        colState?.blocks?.map((block) => {
          const div = document.querySelector(`[data-id='${block.id}']`)
          // Add custom event listeners to the embedded editor blocks
          handleEmbeddedEditorActions(div, contentEditorjsInstance)
          // Initialise styling for blocks
          initialiseStyling(div, block.tunes)
        })
      }
    },
    onChange: async () => {
      // ! Custom tunes work correctly
      // ! in-built tunes (.e.g alignement) are not working
      const saveData = await contentEditorjsInstance.save()
      const tempRowsData = []

      // Loop through all rows, only updating the active row
      for (let rowIndex = 0; rowIndex < getUpdatedData()?.columns?.length; rowIndex++) {
        // Only update the active row
        if (rowIndex === stepIndex) {
          // Start with current state data
          const tempColsData = {
            textCol: getUpdatedData()?.columns[rowIndex]['textCol'] ?? { blocks: [] },
            imgCol: getUpdatedData()?.columns[rowIndex]['imgCol'] ?? { blocks: [] },
          }

          // Loop through both columns in the active row, only updating the active column
          for (let colIndex = 0; colIndex < 2; colIndex++) {
            if (colIndex === COL_KEYS.indexOf(colKey)) {
              tempColsData[COL_KEYS[colIndex]]['blocks'] = saveData.blocks
            }
          }

          tempRowsData.push(tempColsData)
        } else {
          // Maintain current state for all other rows
          tempRowsData.push(getUpdatedData()?.columns[rowIndex])
        }
      }

      // Save the changes
      onDataChange({
        columns: tempRowsData,
      })

      // Add event listeners to the new blocks
      if (saveData?.blocks) {
        saveData?.blocks?.map((block) => {
          const div = document.querySelector(`[data-id='${block.id}']`)
          // Add custom event listeners to the embedded editor blocks
          handleEmbeddedEditorActions(div, contentEditorjsInstance)
        })
      }
    },
  })

  // Save state updates when changing away from this column
  useEffect(() => {
    if (
      (!isActive && activeColState.previousActiveIndex === COL_KEYS.indexOf(colKey)) ||
      (!isModalOpen && activeColState.activeIndex === COL_KEYS.indexOf(colKey))
    ) {
      const updatedData = getUpdatedData()
      syncedStateUpdate({ ...updatedData })
    }
  }, [isActive, isModalOpen])

  return (
    <div className={isActive ? 'd-block' : 'd-none'}>
      <div className="row">
        <div className="col-12 z-2 mb-2" style={{ zIndex: 2 }}>
          <ContentEditorHolder />
        </div>
      </div>
    </div>
  )
}

const CustomSlider = ({ label, state, attribute, customOnChange }) => (
  <div className="col-12 col-md-6">
    <h6>{label}</h6>
    <div className="d-flex flex-row mb-3">
      <div className="ml-2 mr-3 flex-grow-1 align-self-center">
        <Slider
          value={state[attribute] ?? 2}
          onChange={(e) => customOnChange(e.value)}
          step={1}
          min={0}
          max={10}
        />
      </div>
      <div className="d-flex align-items-center small font-weight-bold">
        {state[attribute] ?? 2}
      </div>
    </div>
  </div>
)

const ColumnCustomisations = ({
  type = 'default',
  activeColCustomisationState,
  activeColState,
  activeColKey,
  rowCustomisationsState,
  setRowCustomisationsState,
}) => {
  const handleCustomisationUpdate = useCallback(
    (value, attribute) => {
      const updatedValues =
        type === 'default'
          ? {
              ...activeColState,
              [attribute]: value,
            }
          : {
              ...activeColState,
              [type]: {
                ...activeColCustomisationState,
                [attribute]: value,
              },
            }
      setRowCustomisationsState({ ...rowCustomisationsState, [activeColKey]: updatedValues })
    },
    [type, activeColCustomisationState, activeColState, activeColKey, rowCustomisationsState]
  )

  return (
    <div className="row">
      {/* On/off switch for mobile & tablet */}
      {type !== 'default' && (
        <div className="col-12">
          <div className="pl-4">
            <LabeledCheckbox
              item={activeColCustomisationState}
              itemName="active"
              label={`Use different customisations for ${type}`}
              customOnChange={(e) => handleCustomisationUpdate(e.target.checked, 'active')}
            />
          </div>
        </div>
      )}
      {/* Shared Customisations */}
      {(type === 'default' || activeColCustomisationState?.active) && (
        <>
          <CustomSlider
            label="Top Padding"
            state={activeColCustomisationState}
            attribute="topPadding"
            customOnChange={(value) => handleCustomisationUpdate(value, 'topPadding')}
          />
          <CustomSlider
            label="Bottom Padding"
            state={activeColCustomisationState}
            attribute="bottomPadding"
            customOnChange={(value) => handleCustomisationUpdate(value, 'bottomPadding')}
          />
          <CustomSlider
            label="Left Padding"
            state={activeColCustomisationState}
            attribute="leftPadding"
            customOnChange={(value) => handleCustomisationUpdate(value, 'leftPadding')}
          />
          <CustomSlider
            label="Right Padding"
            state={activeColCustomisationState}
            attribute="rightPadding"
            customOnChange={(value) => handleCustomisationUpdate(value, 'rightPadding')}
          />
          {/* Background colour */}
          <div className="col-12 col-md-6" key={`${activeColKey}-colorType`}>
            <LabeledSelect
              item={activeColCustomisationState?.backgroundColor}
              itemName="colorType"
              label="Background Colour"
              customOnChange={(e) =>
                handleCustomisationUpdate(
                  {
                    ...activeColCustomisationState.backgroundColor,
                    colorType: e.target.value,
                  },
                  'backgroundColor'
                )
              }
              options={[
                { label: 'None', value: undefined },
                ...Array.from(bootstrapColours),
                'custom',
              ]}
            />
          </div>
          {activeColCustomisationState?.backgroundColor?.colorType === 'custom' && (
            <div
              className="col-12 col-md-6 d-flex align-items-end mb-3"
              key={`${activeColKey}-${type}-customColor`}
            >
              <ColorPicker
                value={activeColCustomisationState.backgroundColor.customColor}
                onChange={(e) =>
                  handleCustomisationUpdate(
                    {
                      ...activeColCustomisationState.backgroundColor,
                      customColor: e.value,
                    },
                    'backgroundColor'
                  )
                }
                appendTo="self"
                pt={{
                  input: () => ({
                    style: {
                      height: 38,
                      width: 38,
                    },
                  }),
                }}
              />
              <input
                placeholder="Enter a color hexcode"
                className="form-control ml-1"
                value={activeColCustomisationState?.backgroundColor.customColor}
                onChange={(e) =>
                  handleCustomisationUpdate(
                    {
                      ...activeColCustomisationState.backgroundColor,
                      customColor: e.target.value,
                    },
                    'backgroundColor'
                  )
                }
              />
            </div>
          )}
          {/* Different customisations for Text & Image cols */}
          {activeColKey === 'textCol' ? (
            <>
              <div className="col-12 col-md-6">
                <LabeledSelect
                  item={activeColCustomisationState?.textColor}
                  itemName="colorType"
                  label="Text Colour"
                  customOnChange={(e) =>
                    handleCustomisationUpdate(
                      {
                        ...activeColCustomisationState.textColor,
                        colorType: e.target.value,
                      },
                      'textColor'
                    )
                  }
                  options={[
                    { label: 'Default', value: 'inherit' },
                    ...Array.from(bootstrapColours),
                    'custom',
                  ]}
                />
              </div>
              {activeColCustomisationState?.textColor?.colorType === 'custom' && (
                <div className="col-12 col-md-6 d-flex align-items-end mb-3">
                  <ColorPicker
                    value={activeColCustomisationState.textColor.customColor}
                    onChange={(e) =>
                      handleCustomisationUpdate(
                        {
                          ...activeColCustomisationState.textColor,
                          customColor: e.value,
                        },
                        'textColor'
                      )
                    }
                    appendTo="self"
                    pt={{
                      input: () => ({
                        style: {
                          height: 38,
                          width: 38,
                        },
                      }),
                    }}
                  />
                  <input
                    placeholder={'Enter a color hexcode'}
                    className="form-control ml-1"
                    value={activeColCustomisationState.textColor.customColor}
                    onChange={(e) =>
                      handleCustomisationUpdate(
                        {
                          ...activeColCustomisationState.textColor,
                          customColor: e.target.value,
                        },
                        'textColor'
                      )
                    }
                  />
                </div>
              )}
              <div className="col-12 col-md-6">
                <LabeledSelect
                  item={activeColCustomisationState}
                  itemName="verticalAlignment"
                  label="Vertical Alignment"
                  customOnChange={(e) =>
                    handleCustomisationUpdate(e.target.value, 'verticalAlignment')
                  }
                  options={[
                    { label: 'Top', value: 'start' },
                    { label: 'Middle', value: 'center' },
                    { label: 'Bottom', value: 'end' },
                  ]}
                />
              </div>
            </>
          ) : (
            <div className="col-12 col-md-6" key={`${activeColKey}-${type}-imageLayout`}>
              <LabeledSelect
                item={activeColCustomisationState}
                itemName="imageLayout"
                label="Image Layout"
                customOnChange={(e) => handleCustomisationUpdate(e.target.value, 'imageLayout')}
                options={['contain', 'cover', 'fill']}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

const RowEditor = ({
  stepIndex,
  isActive,
  isModalOpen,
  numRows,
  activeIndexState,
  setActiveIndexState,
  state,
  onDataChange,
  getUpdatedData,
  syncedStateUpdate,
  setSteps,
  config,
}) => {
  // Track the active column
  const [activeColState, setActiveColState] = useState({
    activeIndex: 0,
    previousActiveIndex: undefined,
  })
  // Each row always has two columns
  const steps = [
    { name: 'Text Column', component: ColumnEditor },
    { name: 'Image Column', component: ColumnEditor },
  ]
  const [rowCustomisationsState, setRowCustomisationsState] = useState({
    [COL_KEYS[0]]: {
      ...getUpdatedData()?.columns[stepIndex][COL_KEYS[0]]?.customisations,
    },
    [COL_KEYS[1]]: {
      ...getUpdatedData()?.columns[stepIndex][COL_KEYS[1]]?.customisations,
    },
  })

  // Save state updates when changing away from this step
  useEffect(() => {
    if (
      (!isActive && activeIndexState.previousActiveIndex === stepIndex) ||
      (!isModalOpen && activeIndexState.activeIndex === stepIndex)
    ) {
      const updatedData = getUpdatedData()
      syncedStateUpdate({ ...updatedData })
    }
  }, [isActive, isModalOpen])

  // Save customisation changes
  useEffect(() => {
    handleUpdateColumn(
      { customisations: rowCustomisationsState[COL_KEYS[activeColState.activeIndex]] },
      stepIndex,
      COL_KEYS[activeColState.activeIndex],
      getUpdatedData,
      onDataChange
    )
  }, [rowCustomisationsState])

  return (
    <div className={isActive ? 'd-block' : 'd-none'}>
      <div className="row">
        {/* Column Tabs */}
        <div className="col-12 mb-3">{renderTabs(steps, activeColState, setActiveColState)}</div>
        <div className="col-12">
          <TabView panelContainerClassName="p-0">
            <TabPanel header="Content">
              <div className="row">
                <div className="col-12">
                  {getUpdatedData()?.columns[stepIndex] &&
                    COL_KEYS.map((key) => {
                      return (
                        <ColumnEditor
                          key={`${key}-${stepIndex}`}
                          colKey={key}
                          stepIndex={stepIndex}
                          isActive={activeColState.activeIndex === COL_KEYS.indexOf(key)}
                          isModalOpen={isModalOpen}
                          activeColState={activeColState}
                          state={state}
                          onDataChange={onDataChange}
                          getUpdatedData={getUpdatedData}
                          syncedStateUpdate={syncedStateUpdate}
                          config={config}
                        />
                      )
                    })}
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Desktop Customisations">
              <ColumnCustomisations
                activeColCustomisationState={
                  rowCustomisationsState[COL_KEYS[activeColState.activeIndex]]
                }
                activeColState={rowCustomisationsState[COL_KEYS[activeColState.activeIndex]]}
                activeColKey={COL_KEYS[activeColState.activeIndex]}
                rowCustomisationsState={rowCustomisationsState}
                setRowCustomisationsState={setRowCustomisationsState}
              />
            </TabPanel>
            <TabPanel header="Mobile Customisations">
              <ColumnCustomisations
                type="mobile"
                activeColCustomisationState={
                  rowCustomisationsState[COL_KEYS[activeColState.activeIndex]]['mobile']
                }
                activeColState={rowCustomisationsState[COL_KEYS[activeColState.activeIndex]]}
                activeColKey={COL_KEYS[activeColState.activeIndex]}
                rowCustomisationsState={rowCustomisationsState}
                setRowCustomisationsState={setRowCustomisationsState}
              />
            </TabPanel>
            <TabPanel header="Tablet Customisations">
              <ColumnCustomisations
                type="tablet"
                activeColCustomisationState={
                  rowCustomisationsState[COL_KEYS[activeColState.activeIndex]]['tablet']
                }
                activeColState={rowCustomisationsState[COL_KEYS[activeColState.activeIndex]]}
                activeColKey={COL_KEYS[activeColState.activeIndex]}
                rowCustomisationsState={rowCustomisationsState}
                setRowCustomisationsState={setRowCustomisationsState}
              />
            </TabPanel>
          </TabView>
        </div>
        <div className="col-12 d-flex justify-content-end">
          {/* Delete Row Button */}
          <Tooltip
            tooltipTrigger={
              <button
                className="btn btn-danger mb-3"
                onClick={() =>
                  handleDeleteRow(
                    stepIndex,
                    getUpdatedData,
                    syncedStateUpdate,
                    setActiveIndexState,
                    state,
                    setSteps,
                    RowEditor
                  )
                }
                disabled={numRows === MIN_ROWS}
              >
                Delete Row
              </button>
            }
            tooltipContent={DeleteRowTooltip.content}
            width={200}
          />
        </div>
      </div>
    </div>
  )
}

const RenderedAlternatingColumnsComponent = ({
  onDataChange,
  getUpdatedData,
  data,
  config,
  toolInfo,
  uniqueId,
}) => {
  const [state, setState] = useState(data) // Tool state
  const [show, setShow] = useState(false) // Modal state
  const [steps, setSteps] = useState([])
  // Track form state (active index, previous active index)
  const [activeIndexState, setActiveIndexState] = useState({
    activeIndex: 0,
    previousActiveIndex: undefined,
  })

  // Initialise steps data
  useEffect(() => {
    refreshSteps(data, getUpdatedData, setSteps, RowEditor)
  }, [])

  // Keep data and state synced
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({ ...item })
      onDataChange({
        ...item,
      })
    },
    [onDataChange]
  )

  return (
    <>
      {/* Render preview */}
      <AlternatingColumnsBlock block={state} />
      <Dialog title="Alternating Columns" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} hideToggle />
        <div className="pt-3 border-top">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="pl-4">
                <LabeledCheckbox
                  item={state}
                  itemName="reverse"
                  updateItem={syncedStateUpdate}
                  tooltip={ReverseTooltip}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="pl-4">
                <LabeledCheckbox
                  item={state}
                  itemName="variant"
                  updateItem={syncedStateUpdate}
                  tooltip={VariantTooltip}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-3 border-top">
          {renderTabs(
            steps,
            activeIndexState,
            setActiveIndexState,
            undefined,
            <Tooltip
              tooltipTrigger={
                <button
                  className="btn btn-small btn-success d-flex align-items-center ml-2"
                  onClick={() =>
                    handleAddRow(
                      getUpdatedData,
                      syncedStateUpdate,
                      activeIndexState,
                      setActiveIndexState,
                      setSteps,
                      RowEditor
                    )
                  }
                >
                  <Add size={25} />
                </button>
              }
              tooltipContent={AddRowTooltip.content}
              width={200}
            />
          )}
          <div className="row pt-3">
            <div className="col-12">
              <div className="form-group mb-0">
                {/* Render Steps */}
                {steps?.map((step, index) => {
                  const StepComponent = step.component

                  return (
                    <StepComponent
                      key={step.name + index}
                      stepIndex={index}
                      isActive={activeIndexState.activeIndex === index}
                      isModalOpen={show}
                      numRows={steps?.length}
                      activeIndexState={activeIndexState}
                      setActiveIndexState={setActiveIndexState}
                      state={state}
                      onDataChange={onDataChange}
                      getUpdatedData={getUpdatedData}
                      syncedStateUpdate={syncedStateUpdate}
                      setSteps={setSteps}
                      config={config}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class AlternatingColumnsTool {
  constructor({ data, config, block }) {
    this.blockAPI = block
    this.config = config
    this.uniqueId = unique()

    // * columns actually refers to the rows of alternating columns
    const defaultData = {
      columns: [
        {
          imgCol: {
            blocks: defaultImageBlocks,
            customisations: {
              ...DEFAULT_IMAGE_CUSTOMISATIONS,
              mobile: {
                ...DEFAULT_IMAGE_CUSTOMISATIONS,
              },
              tablet: {
                ...DEFAULT_IMAGE_CUSTOMISATIONS,
              },
            },
          },
          textCol: {
            blocks: defaultTextBlocks,
            customisations: {
              ...DEFAULT_TEXT_CUSTOMISATIONS,
              mobile: {
                ...DEFAULT_TEXT_CUSTOMISATIONS,
              },
              tablet: {
                ...DEFAULT_TEXT_CUSTOMISATIONS,
              },
            },
          },
        },
      ],
      reverse: false,
      variant: false,
    }

    this.data = Object.keys(data).length ? data : defaultData

    // This adds the variant attribute to alt. columns instances created before the option was added
    if (this.data.variant === undefined) {
      this.data.variant = false
    }

    // This adds the customisations attribute to alt. columns instances created before the option was added
    if (this.data.columns[0].imgCol.customisations === undefined) {
      this.data.columns.map((column) => {
        column.imgCol.customisations = {
          ...DEFAULT_IMAGE_CUSTOMISATIONS,
          mobile: {
            active: false,
            ...DEFAULT_IMAGE_CUSTOMISATIONS,
          },
          tablet: {
            active: false,
            ...DEFAULT_IMAGE_CUSTOMISATIONS,
          },
        }
        column.textCol.customisations = {
          ...DEFAULT_TEXT_CUSTOMISATIONS,
          mobile: {
            active: false,
            ...DEFAULT_TEXT_CUSTOMISATIONS,
          },
          tablet: {
            active: false,
            ...DEFAULT_TEXT_CUSTOMISATIONS,
          },
        }
      })
    } else if (this.data.columns[0].imgCol.customisations.mobile === undefined) {
      // Add responsive customisations to alt. columns instances created before the option was added
      this.data.columns.map((column) => {
        column.imgCol.customisations = {
          ...column.imgCol.customisations,
          mobile: {
            active: false,
            ...DEFAULT_IMAGE_CUSTOMISATIONS,
          },
          tablet: {
            active: false,
            ...DEFAULT_IMAGE_CUSTOMISATIONS,
          },
        }
        column.textCol.customisations = {
          ...column.textCol.customisations,
          mobile: {
            active: false,
            ...DEFAULT_TEXT_CUSTOMISATIONS,
          },
          tablet: {
            active: false,
            ...DEFAULT_TEXT_CUSTOMISATIONS,
          },
        }
      })
    }

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.toolInfo = {
      heading: undefined,
      helpText:
        'Create responsive columns with images and text blocks that look great on any device.',
      itemName: 'Row',
    }

    this.nodes = {
      holder: null,
    }
  }

  static get toolbox() {
    return {
      title: 'Alternating Columns',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 7.125C2.25 6.504 2.754 6 3.375 6h6c.621 0 1.125.504 1.125 1.125v3.75c0 .621-.504 1.125-1.125 1.125h-6a1.125 1.125 0 01-1.125-1.125v-3.75zM14.25 8.625c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v8.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-8.25zM3.75 16.125c0-.621.504-1.125 1.125-1.125h5.25c.621 0 1.125.504 1.125 1.125v2.25c0 .621-.504 1.125-1.125 1.125h-5.25a1.125 1.125 0 01-1.125-1.125v-2.25z" /></svg>',
    }
  }

  onDataChange(newData) {
    this.data = {
      ...this.data,
      ...newData,
    }
    this.config.save()
    // Force editor onChange event
    this.blockAPI.dispatchChange()
  }

  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }

      this.config.save()
    }

    const getUpdatedData = () => {
      return this.data
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedAlternatingColumnsComponent
        onDataChange={onDataChange}
        getUpdatedData={getUpdatedData}
        data={this.data}
        config={this.config}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default AlternatingColumnsTool
