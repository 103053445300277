/**
 * Get the manufacturer options from the location data
 * @param {*} locations the Website's locations data
 * @returns an array of manufacturer options
 */
export function getManufacturerLocationOptions(locations) {
  const locationManufacturerOptions = []

  locations.forEach((location) => {
    if (location.manufacturers.length > 0) {
      location.manufacturers.map((manufacturer) => {
        if (!locationManufacturerOptions.includes(manufacturer.id)) {
          locationManufacturerOptions.push(manufacturer.name)
        }
      })
    }
  })

  return locationManufacturerOptions
}

/**
 * Get the subtype options from the location data
 * @param {*} locations
 * @returns an array of subtype options
 */
export function getSubtypeOptions(locations) {
  const subtypeOptions = []
  const subtypeSet = new Set()

  locations.forEach((location) => {
    if (location?.location_subtype?.length > 0) {
      location.location_subtype.map((subtype) => {
        if (subtype && !subtypeSet.has(subtype)) {
          subtypeSet.add(subtype)
          subtypeOptions.push(subtype)
        }
      })
    }
  })

  return subtypeOptions
}

/**
 * Apply the location filter based on the selected value
 * @param {*} value the selected filter value
 * @param {*} locations the Website's locations data
 * @param {*} primaryLocationId the Website's primary location ID
 * @param {*} onDataChange the function to update the location data
 * @returns an array of filtered locations
 */
function applyLocationFilter(value, locations, primaryLocationId, onDataChange) {
  // Initialize filteredLocations with all locations
  let filteredLocations = locations

  // Switch based on the selected value
  switch (value) {
    case 'primary':
      onDataChange({ primaryLocationId: primaryLocationId })
      filteredLocations = locations.filter(
        (location) => location.id === parseInt(primaryLocationId)
      )
      break
    case 'all':
      // No filtering
      break
    case 'sales':
      filteredLocations = filteredLocations.filter(
        (location) => location.location_type === 'sales' || location.location_type === ''
      )
      break
    case 'service':
      filteredLocations = filteredLocations.filter(
        (location) => location.location_type === 'service'
      )
      break
    case 'parts':
      filteredLocations = filteredLocations.filter((location) => location.location_type === 'parts')
      break
    case 'service-and-parts':
      filteredLocations = filteredLocations.filter(
        (location) => location.location_type === 'service' || location.location_type === 'parts'
      )
      break
    default:
      // Filter by location ID
      filteredLocations = filteredLocations.filter((location) => `${location.id}` === value)
      break
  }

  return filteredLocations
}

/**
 * Apply the manufacturer filter based on the selected value
 * @param {*} locations the Website's locations data
 * @param {*} manufacturer the selected manufacturer
 * @returns an array of filtered locations
 */
function applyManufacturerFilter(locations, manufacturer) {
  let activeLocationsData = []

  if (!manufacturer || manufacturer === 'false') {
    activeLocationsData = locations
  } else {
    // Filter location data based on the existence of the selected manufacturer
    const tempLocationData = []
    locations.map((location) => {
      const matchedManufacturer = location.manufacturers.filter((man) => man.name === manufacturer)
      if (matchedManufacturer.length > 0) {
        tempLocationData.push(location)
      }
    })

    activeLocationsData = tempLocationData
  }

  return activeLocationsData
}

/**
 * Apply the fine-tuned location filters
 * @param {*} locations the Website's locations data
 * @param {*} filteredLocations an array of filtered location IDs
 * @returns an array of filtered locations
 */
export function applyFineTunedLocationFilters(locations, filteredLocations) {
  let tempLocations = locations

  if (filteredLocations.length > 0) {
    // Remove filtered locations from the active locations
    tempLocations = locations.filter((location) => !filteredLocations.includes(location.id))
  }

  return tempLocations
}

/**
 * Update the active locations based on the selected filter values
 * @param {*} values the selected filter values
 */
export function updateActiveLocations(
  values,
  filteredLocationData,
  primaryLocationId,
  onDataChange,
  setActiveLocations,
  setPreviewLocations
) {
  let tempLocations

  // Apply general location filter
  tempLocations = applyLocationFilter(
    values.filter,
    filteredLocationData,
    primaryLocationId,
    onDataChange
  )

  // Apply manufacturer filtering if specified
  tempLocations = applyManufacturerFilter(tempLocations, values.manufacturer)

  // Apply subtype filtering if specified
  if (values.subtype && values.subtype !== 'false') {
    tempLocations = tempLocations.filter((location) =>
      location.location_subtype.includes(values.subtype)
    )
  }

  // Update active and preview locations
  setActiveLocations(tempLocations)
  setPreviewLocations(applyFineTunedLocationFilters(tempLocations, values.filteredLocations))
}
