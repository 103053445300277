const BoxesPreview = ({ item, brandBoxBackground = 'white' }) => {
  const logoFallback =
    'https://res.cloudinary.com/total-dealer/image/upload/v1709174465/td_dashboard/editor-placeholder_bvllx4.png'

  const containerStyle = {
    outline: '0.5px solid #ccc',
    width: '130px',
    height: '135px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    position: 'relative',
    backgroundColor: brandBoxBackground,
    padding: '1rem',
  }

  const imageContainerStyle = {
    maxHeight: '100px',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }

  const imageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  }

  return (
    <div className="d-flex justify-content-center px-md-0 brand-banner">
      <div style={containerStyle}>
        <div style={imageContainerStyle}>
          <img src={item.logo?.url ?? logoFallback} alt={`${item.make} logo`} style={imageStyle} />
        </div>
      </div>
    </div>
  )
}

export default BoxesPreview
