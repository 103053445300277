const CardsBase = ({ type, rounded, className = '', children }) => {
  const baseStyle = {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    background: 'white',
  }

  const styles = {
    border: {
      border: 'solid #e5e7eb 1px',
    },
    shadow: {
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    },
    center: {
      textAlign: 'center',
    },
  }

  const roundedStyle = rounded ? { borderRadius: '0.25rem' } : {}

  const appliedStyles = {
    ...baseStyle,
    // Directly apply the style based on the type prop
    ...(styles[type] || {}),
    ...roundedStyle,
  }

  return (
    <div className={className} style={appliedStyles}>
      {children}
    </div>
  )
}

export default CardsBase
