import { createRoot } from 'react-dom/client'
import { LabeledCheckbox } from '../common'
import { useCallback, useState } from 'react'

const RenderedReviewsComponent = ({ onDataChange, data }) => {
  const [state, setState] = useState(data)

  const syncedStateUpdate = useCallback(
    (updatedData) => {
      setState({ ...state, ...updatedData })
      onDataChange({ ...state, ...updatedData })
    },
    [state]
  )

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="d-flex flex-column align-items-center bg-white text-dark border rounded shadow-sm pt-3">
            <h5 className="text-center">Reviews Section</h5>
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label htmlFor="variant-select">Display Option</label>
                    <select
                      className="form-control"
                      id="variant-select"
                      defaultValue={data.variant}
                      onChange={(e) => syncedStateUpdate({ variant: e.target.value })}
                    >
                      <option value="simple">Simple</option>
                      <option value="highlighted">Highlighted</option>
                      <option value="full">Full</option>
                      <option value="mini">Mini</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3 d-flex align-items-end">
                  <div className="pl-4">
                    <LabeledCheckbox
                      label="Hide Title"
                      item={state}
                      itemName="hideTitle"
                      updateItem={syncedStateUpdate}
                    />
                    <LabeledCheckbox
                      label="Hide Subtitle"
                      item={state}
                      itemName="hideSubtitle"
                      updateItem={syncedStateUpdate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

class ReviewsTool {
  static get toolbox() {
    return {
      title: 'Reviews',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-star">
          <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"/>
        </svg>`,
    }
  }

  constructor({ data, config, api, block, readOnly }) {
    this.api = api
    this.readOnly = readOnly
    this.config = config
    this.blockAPI = block

    let defaultValue = {
      variant: 'simple',
      hideTitle: undefined,
      hideSubtitle: undefined,
    }

    this.data = Object.keys(data).length ? data : defaultValue

    this.CSS = {
      wrapper: 'reviews-tool',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(<RenderedReviewsComponent onDataChange={onDataChange} data={this.data} />)

    return this.nodes.holder
  }

  save(blockContent) {
    return this.data
  }
}

export default ReviewsTool
