import { useCallback, useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import {
  EmptyPlaceHolder,
  ItemOption,
  LabeledCheckbox,
  LabeledInput,
  LabeledSelect,
  ToolHeader,
} from '../common'
import { useActions } from '../common/ActionButtons'
import { bootstrapColours, buttonOptions } from '../common/constants'
import Dialog from '../common/Dialog'
import {
  handleEmbeddedModal,
  renderEditSettingsButton,
  renderHiddenModalButton,
} from '../common/Utils'

import { Slider } from 'primereact/slider'
import { unique } from '../../entries/utils'

const defaultItem = () => {
  return {
    id: unique(),
    type: 'fill',
    text: 'Contact Us',
    colour: 'primary',
    href: '/contact',
    fullWidth: false,
    roundedButton: false,
    customClassname: '',
    customPadding: {
      useCustomPadding: false,
      paddingVertical: 0,
      paddingHorizontal: 0,
    },
  }
}

const ButtonPreview = ({ item, numItems, fullWidth, roundedButton }) => {
  const className = `btn my-1 mx-1 btn-${item.type === 'outline' ? 'outline-' : ''}${item.colour} ${
    roundedButton ? 'rounded-pill' : ''
  } ${item.customClassname ?? ''}`

  return (
    <button
      className={className}
      style={{
        width: fullWidth ? `calc(${100 / numItems}% - 8px)` : '',
        padding: item.customPadding?.useCustomPadding
          ? `${item.customPadding?.paddingVertical / 2}rem ${
              item.customPadding?.paddingHorizontal / 2
            }rem`
          : undefined,
      }}
    >
      {item.text}
    </button>
  )
}

export const ButtonEditor = ({
  index,
  item,
  updateItem,
  deleteItem,
  moveUp,
  fullWidth,
  numItems,
  roundedButton,
}) => {
  const [edit, setEdit] = useState(true)

  const editable = updateItem && edit
  return (
    <ItemOption
      edit={edit}
      index={index}
      item={item}
      title="Button"
      setEdit={setEdit}
      moveUp={moveUp}
      deleteItem={numItems > 1 ? deleteItem : undefined} // Don't allow users to have an instance of the tool with no buttons
    >
      {editable ? (
        // Form field Inputs
        <div className="row">
          <div className="col-12 col-md-6">
            <LabeledInput item={item} itemName="text" label="Button Text" updateItem={updateItem} />
          </div>
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={item}
              itemName="type"
              label="Button Style"
              updateItem={updateItem}
              options={Array.from(buttonOptions)}
            />
          </div>
          <div className="col-12 col-md-6">
            <LabeledSelect
              item={item}
              itemName="colour"
              options={Array.from(bootstrapColours)}
              updateItem={updateItem}
            />
          </div>
          <div className="col-12 col-md-6">
            <LabeledInput item={item} itemName="href" label="Link URL" updateItem={updateItem} />
          </div>
          <div className="col-12 col-md-6">
            <LabeledInput
              item={item}
              itemName="customClassname"
              label="Custom Classname"
              updateItem={updateItem}
            />
          </div>
          <div className="col-12">
            <div className="pl-4">
              <LabeledCheckbox
                item={item?.customPadding ?? { useCustomPadding: false }}
                itemName="useCustomPadding"
                label="Use Custom Padding"
                customOnChange={(e) =>
                  updateItem({
                    ...item,
                    customPadding: { ...item.customPadding, useCustomPadding: e.target.checked },
                  })
                }
              />
            </div>
          </div>
          {item.customPadding?.useCustomPadding && (
            <>
              <div className="col-12 col-md-6">
                <div className="form-label mb-2">Vertical Padding</div>
                <div className="d-flex flex-row mb-3">
                  <div className="mx-2 flex-grow-1 align-self-center">
                    <Slider
                      value={item?.customPadding?.paddingVertical ?? 0}
                      onChange={(e) =>
                        updateItem({
                          ...item,
                          customPadding: { ...item?.customPadding, paddingVertical: e.value },
                        })
                      }
                      step={1}
                      min={0}
                      max={10}
                    />
                  </div>
                  <div className="d-flex align-items-center small font-weight-bold">
                    {item.customPadding?.paddingVertical ?? 0}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-label mb-2">Horizontal Padding</div>
                <div className="d-flex flex-row mb-3">
                  <div className="mx-2 flex-grow-1 align-self-center">
                    <Slider
                      value={item.customPadding?.paddingHorizontal ?? 0}
                      onChange={(e) =>
                        updateItem({
                          ...item,
                          customPadding: { ...item?.customPadding, paddingHorizontal: e.value },
                        })
                      }
                      step={1}
                      min={0}
                      max={10}
                    />
                  </div>
                  <div className="d-flex align-items-center small font-weight-bold">
                    {item.customPadding?.paddingHorizontal ?? 0}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="pb-3">
          <ButtonPreview
            item={item}
            numItems={numItems}
            fullWidth={fullWidth}
            roundedButton={roundedButton}
          />
        </div>
      )}
    </ItemOption>
  )
}

const RenderedButtonsComponent = ({ data, toolInfo, onDataChange, uniqueId }) => {
  const [show, setShow] = useState(false)
  const [state, setState] = useState({
    fullWidth: !!data?.fullWidth,
    roundedButton: !!data?.roundedButton,
  })
  const [items, setItems] = useState(data.buttons || [])

  useEffect(() => {
    handleEmbeddedModal(uniqueId, show)
  }, [show])

  const syncedUpdate = useCallback(
    (updatedItems) => {
      setItems([...updatedItems])
      onDataChange({
        buttons: [...updatedItems],
      })
    },
    [onDataChange]
  )

  /**
   * Update the common fields such as fullWidth
   */
  const syncedStateUpdate = useCallback(
    (item) => {
      setState({
        ...item,
      })
      onDataChange(item)
    },
    [onDataChange]
  )

  const actions = useActions({
    items,
    defaultItem,
    syncedUpdate,
  })

  return (
    <>
      {items?.map((item) => (
        <ButtonPreview
          key={item.id}
          item={item}
          numItems={items.length}
          fullWidth={state.fullWidth}
          roundedButton={state.roundedButton}
        />
      ))}
      <Dialog title="CTA Buttons" show={show} closeClickHandler={() => setShow(false)}>
        <ToolHeader {...toolInfo} addItem={actions.addItem} />
        <div className="pt-3 border-top">
          <div className="row">
            <div className="col-12">
              <h6 className="text-muted">GENERAL</h6>
            </div>
            <div className="col-12 col-md-6">
              <div className="pl-4">
                <LabeledCheckbox
                  item={state}
                  itemName="fullWidth"
                  label="Full-Width Buttons"
                  updateItem={syncedStateUpdate}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="pl-4">
                <LabeledCheckbox
                  item={state}
                  itemName="roundedButton"
                  label="Rounded Buttons"
                  updateItem={syncedStateUpdate}
                />
              </div>
            </div>
          </div>
          {items.length > 0 ? (
            items.map((item, id) => (
              <ButtonEditor
                key={item.id}
                index={id}
                item={item}
                numItems={items.length}
                fullWidth={state.fullWidth}
                roundedButton={state.roundedButton}
                {...actions}
              />
            ))
          ) : (
            <EmptyPlaceHolder itemName="button" />
          )}
        </div>
      </Dialog>
      {/* Hidden button that handles opening the settings modal */}
      {renderHiddenModalButton(uniqueId, setShow)}
    </>
  )
}

class ButtonsTool {
  constructor({ config, data, api, block }) {
    this.blockAPI = block
    this.api = api
    this.config = config
    this.uniqueId = unique()

    const defaultData = {
      buttons: [defaultItem()], // Contain a button by default
      fullWidth: false,
      roundedButton: false,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.nodes = {
      holder: null,
    }

    this.toolInfo = {
      heading: undefined,
      helpText:
        'Buttons used as call-to-action links. *Note - Primary defaults to blue in our dashboard, but on your website it will be based on the primary colour of your theme.',
      itemName: 'Button',
      hideToggle: true,
    }
  }

  static get toolbox() {
    return {
      title: 'Buttons',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5" />
      </svg>
      `,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedButtonsComponent
        onDataChange={onDataChange}
        data={this.data}
        toolInfo={this.toolInfo}
        uniqueId={this.uniqueId}
      />
    )

    return this.nodes.holder
  }

  /** Create the settings panel for the block */
  renderSettings() {
    const wrapper = document.createElement('div')

    // Add edit button
    const editButton = renderEditSettingsButton(this.uniqueId)

    wrapper.appendChild(editButton)

    return wrapper
  }

  save() {
    return this.data
  }
}

export default ButtonsTool
