import Container from './Container'

// BasicButtons component for rendering buttons
export const BasicButtons = ({ buttons, blockId, rounded }) => {
  if (!buttons) {
    return null
  }

  return (
    <>
      {buttons?.map((button, id) => {
        const btnClassName =
          `btn mx-1 btn-${button.type === 'outline' ? 'outline-' : ''}${button.colour}` +
          (rounded ? ' rounded-pill' : '')

        return (
          <button key={`${blockId}-btn-${id}`} href={button?.href || ''} className={btnClassName}>
            {button.text}
          </button>
        )
      })}
    </>
  )
}

const ButtonsBlock = ({ block, isInRow }) => {
  return (
    <Container tunes={block?.tunes} isInRow={isInRow}>
      {block.data.fullWidth ? (
        <div className="row">
          {block?.data?.buttons?.map((button, id) => {
            const btnClassName =
              `btn btn-block mb-2 mb-lg-0 btn-${button.type === 'outline' ? 'outline-' : ''}${
                button.colour
              }` + (block.data.roundedButton ? ' rounded-pill' : '')

            return (
              <button
                key={`${block.id}-btn-${id}`}
                href={button?.href || ''}
                className={btnClassName}
              >
                {button.text}
              </button>
            )
          })}
        </div>
      ) : (
        <BasicButtons
          buttons={block.data?.buttons}
          rounded={block.data?.roundedButton}
          blockId={block.id}
        />
      )}
    </Container>
  )
}

export default ButtonsBlock
