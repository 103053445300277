import { default as React } from 'react'
import { createRoot } from 'react-dom/client'
import { categories } from './FormBuilderTool/formBuilderUtils'

const RenderedFormComponent = ({ data, onDataChange }) => (
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="border rounded shadow-sm p-3 bg-white">
          <h5 className="text-center">
            <i className="fa fa-envelope mr-2" />
            Form Container
          </h5>
          <div className="form-group">
            <label htmlFor="formHeading">Form Heading</label>
            <input
              id="formHeading"
              placeholder="Form Title"
              className="form-control"
              defaultValue={data.formData.heading}
              onChange={(e) => onDataChange({ heading: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              className="form-control"
              defaultValue={data.formData.category}
              onChange={(e) => onDataChange({ category: e.target.value })}
            >
              {categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
)

class FormTool {
  static get toolbox() {
    return {
      title: 'Form',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" /></svg>`,
    }
  }

  constructor({ data, config, api, block, readOnly }) {
    this.api = api
    this.config = config
    this.blockAPI = block
    this.readOnly = readOnly

    let defaultData = {
      heading: 'Enquiry Form',
      category: 'General Enquiry',
    }

    this.data = {
      formData: data.formData || defaultData,
    }

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        formData: {
          ...this.data.formData,
          ...newData,
        },
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(<RenderedFormComponent onDataChange={onDataChange} data={this.data} />)

    return this.nodes.holder
  }

  save(blockContent) {
    return this.data
  }
}

export default FormTool
