import { default as React } from 'react'
import { FormGroup } from './FormGroup'
import { generateRandomId } from './Utils'
import InputLabel from './InputLabel'

export const LabeledCheckbox = ({
  controlled = true,
  item,
  itemName,
  label,
  updateItem,
  customOnChange,
  tooltip,
  customValue,
  disabled = false,
}) => {
  // Give each instance a unique id
  const id = itemName + generateRandomId(3)

  return (
    <div>
      <FormGroup>
        <input
          id={id}
          type="checkbox"
          className="form-check-input"
          checked={
            customValue !== undefined ? customValue : controlled ? !!item[itemName] : undefined
          }
          defaultChecked={!controlled ? item[itemName] : undefined}
          onChange={(e) => {
            customOnChange
              ? customOnChange(e)
              : updateItem({ ...item, [itemName]: e.target.checked })
          }}
          disabled={disabled}
        />
        <InputLabel label={label} htmlFor={id} itemName={itemName} tooltip={tooltip} />
      </FormGroup>
    </div>
  )
}
