import { truncateString } from '../../../entries/utils'

/**
 * ButtonPreview - A React component for rendering a styled button preview.
 *
 * @param {Object} props - Component props.
 * @param {string} props.type - Type of the button ('fill' or 'outline').
 * @param {string} props.colour - Color of the button (e.g., 'primary', 'secondary').
 * @param {boolean} props.isLastButton - Indicates if the button is the last in a group.
 * @param {ReactNode} props.children - Content to be displayed inside the button.
 * @returns {JSX.Element} - The rendered button.
 */
export const ButtonPreview = ({ type, colour, isLastButton, children }) => {
  const className = `btn btn-${type === 'outline' ? 'outline-' : ''}${colour} ${
    isLastButton ? '' : 'mr-2'
  }`
  return <button className={className}>{children}</button>
}

// ! I'd like to give more control over the styling of this component (e.g. the background color, the border color, etc.)
const BrandDescriptionPreview = ({ state, heading, description, items }) => (
  <div className="bg-light border-top border-bottom py-1 mb-3">
    <div className="row py-4">
      {state?.logo?.url ? (
        <div className="col-12 col-md-3 d-flex align-items-center justify-content-center">
          <img src={state.logo.url} style={{ width: '100%', maxWidth: 200, height: 'auto' }} />
        </div>
      ) : (
        <div className="col-12 col-md-3 d-flex align-items-center justify-content-center text-center">
          <span>***Add a Logo***</span>
        </div>
      )}
      <div className="col-12 col-md-9">
        <h4>{heading}</h4>
        {typeof description === 'string' && (
          <p>
            {truncateString(description, {
              length: '500',
              omission: '...',
              separator: /,? +/,
            })}
          </p>
        )}
        {items?.map((item, index) => (
          <ButtonPreview
            key={`Preview-${index}`}
            type={item.btnType}
            colour={item.btnColour}
            isLastButton={index === items.length - 1}
          >
            {item.linkText}
          </ButtonPreview>
        ))}
      </div>
    </div>
  </div>
)

export default BrandDescriptionPreview
