export const getColClassName = (itemsLength, enableTextContainer = true) => {
  switch (itemsLength) {
    case 6:
      return `col-sm-6 col-lg-4`
    case 4:
      return `col-sm-6 col-lg-3`
    case 3:
      return `col-sm-6 col-md-4`
    case 2:
      return `col-sm-6 ${enableTextContainer ? 'col-lg-5 col-xl-4' : 'col-lg-6'}`
    default:
      return `col-sm-6 col-lg-4 col-xl-3`
  }
}

export const getColPerRow = (itemsLength) => {
  switch (itemsLength) {
    case 4:
      return 4
    case 3:
      return 3
    case 2:
      return 3
    default:
      return 4
  }
}
