import {
  LabeledCheckbox,
  LabeledInput,
  LabeledSelect,
  LabeledTextarea,
  EmptyPlaceHolder,
  cardOptions,
  bootstrapColours,
  buttonOptions,
} from '../../../../common'
import CardEditor from '../CardEditor'

const GeneralSettingsTab = ({ state, syncedStateUpdate, items, imageEndpoint, actions }) => {
  const { heading, subheading, rounded, type } = state
  return (
    <div className="row mt-2">
      {/* Deprecated */}
      {heading !== undefined && (
        <div className="col-12 col-md-6">
          <LabeledInput
            controlled={false}
            item={state}
            itemName="heading"
            label="Section Heading"
            updateItem={syncedStateUpdate}
          />
        </div>
      )}
      {/* Deprecated */}
      {subheading !== undefined && (
        <div className="col-12 col-md-6">
          <LabeledTextarea
            controlled={false}
            item={state}
            rows={2}
            label="Section Subheading"
            itemName="subheading"
            updateItem={syncedStateUpdate}
          />
        </div>
      )}
      <div className="col-12 col-md-6">
        <LabeledSelect
          controlled={false}
          item={state}
          itemName="type"
          label="Card Styles"
          updateItem={syncedStateUpdate}
          options={Array.from(cardOptions)}
        />
      </div>
      <div className="col-12 col-md-6">
        <LabeledSelect
          controlled={false}
          item={state}
          itemName="cardCols"
          label="Cards/Row"
          updateItem={syncedStateUpdate}
          options={[
            { label: 'Automatic', value: '' },
            { label: 1, value: 12 },
            { label: 2, value: 6 },
            { label: 3, value: 4 },
            { label: 4, value: 3 },
          ]}
        />
      </div>
      <div className="col-6">
        <div className="pl-4">
          <LabeledCheckbox
            controlled={false}
            item={state}
            itemName="rounded"
            updateItem={syncedStateUpdate}
          />
        </div>
      </div>
      <div className="col-6">
        <div className="pl-4">
          <LabeledCheckbox
            controlled={false}
            item={state}
            itemName="isCarousel"
            label="Carousel"
            updateItem={syncedStateUpdate}
          />
        </div>
      </div>
      <div className="col-12 col-md-6">
        <LabeledSelect
          controlled={false}
          item={state}
          itemName="btnType"
          label="Button Style"
          updateItem={syncedStateUpdate}
          options={Array.from(buttonOptions)}
        />
      </div>
      <div className="col-12 col-md-6">
        <LabeledSelect
          controlled={false}
          item={state}
          label="Button Colour"
          itemName="btnColour"
          updateItem={syncedStateUpdate}
          options={Array.from(bootstrapColours)}
        />
      </div>
      <div className="col-12">
        <div className="pt-3 border-top">
          <h6>Cards</h6>
          <div className="row">
            {items.length > 0 ? (
              items.map((item, id) => (
                <div
                  className={`col-12 col-lg-6 ${
                    id === items.length - 1 ? '' : id === items.length - 2 ? 'mb-3 mb-lg-0' : 'mb-3'
                  }`}
                  key={item.id}
                >
                  <CardEditor
                    index={id}
                    items={item}
                    item={item}
                    rounded={rounded}
                    type={type}
                    imageEndpoint={imageEndpoint}
                    state={state}
                    {...actions}
                  />
                </div>
              ))
            ) : (
              <div className="col-12">
                <EmptyPlaceHolder itemName="card" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GeneralSettingsTab
