import { useCallback, useEffect } from 'react'
import { useFieldArray } from 'react-hook-form'
import { unique } from '../../../entries/utils'
import { LabeledFileInput, LabeledInput, LabeledSelect, LabeledTextarea } from '../../common'
import { Add, ArrowUp, Cross } from '../../common/Icons'
import { generateRandomId } from '../../common/Utils'

export const FIELD_TYPE_INPUTS = {
  text: ['formDataKey', 'label', 'placeholder', 'help', 'required'],
  message: ['formDataKey', 'label', 'placeholder', 'help', 'required'],
  number: ['formDataKey', 'label', 'placeholder', 'help', 'required'],
  select: ['formDataKey', 'label', 'placeholder', 'options', 'help', 'required'],
  check: ['formDataKey', 'label', 'defaultChecked', 'help', 'required'],
  radio: ['formDataKey', 'label', 'defaultSelected', 'options', 'required'],
  radioCard: ['formDataKey', 'label', 'defaultSelected', 'cardOptions', 'required'],
  address: ['formDataKey', 'label', 'placeholder', 'help', 'required'],
  postcode: ['formDataKey', 'label', 'placeholder', 'help', 'required'],
  dob: ['formDataKey', 'label', 'placeholder', 'help', 'required'],
  year: ['formDataKey', 'label', 'placeholder', 'required'],
  currency: ['formDataKey', 'label', 'placeholder', 'help', 'options', 'required'],
  hidden: ['formDataKey', 'specialValue'],
  locationSelect: ['formDataKey', 'label', 'placeholder', 'locationOptions', 'help', 'required'],
  rego: ['formDataKey', 'label', 'placeholder', 'required'],
  make: ['formDataKey', 'label', 'placeholder', 'required'],
  serviceInterval: ['formDataKey', 'label', 'placeholder', 'required'],
  dateTime: ['dateFormDataKey', 'timeFormDataKey', 'bookingWindow', 'required'],
  file: ['formDataKey', 'label', 'required'],
}

export const inputOptions = [
  'text', // input
  'message', // textarea
  'number',
  'select',
  'check',
  'radio',
  'radioCard',
  'address',
  'postcode',
  'dob',
  'year',
  'currency',
  'hidden',
  'locationSelect',
  'rego',
  'make',
  'serviceInterval',
  'dateTime',
  'file',
]

export const categories = [
  'New Vehicle Enquiry',
  'Used Vehicle Enquiry',
  'Special Offer Enquiry',
  'Test Drive Booking',
  'Trade-in Enquiry',
  'Sell My Car Enquiry',
  'Fleet Enquiry',
  'Service Booking',
  'Service Enquiry',
  'Parts Enquiry',
  'General Enquiry',
  'Finance Enquiry',
  'Insurance Enquiry',
  'Team Member Enquiry',
  'Competition Enquiry',
  'Survey',
]

// Important fields that must be included in the form
export const coreFields = [
  {
    id: generateRandomId(10),
    name: 'name',
    type: 'text',
    label: 'Full Name',
    placeholder: 'Full Name',
    required: true,
    stepKey: 0,
  },
  {
    id: generateRandomId(10),
    name: 'phone',
    type: 'phone',
    label: 'Mobile Number',
    placeholder: 'Mobile',
    required: true,
    stepKey: 0,
  },
  {
    id: generateRandomId(10),
    name: 'email',
    type: 'email',
    label: 'Email Address',
    placeholder: 'Email',
    required: true,
    stepKey: 0,
  },
]

export const defaultStep = (steps) => {
  return { key: steps.length, customStepKey: undefined, heading: '', description: '' }
}

export const defaultField = (stepKey) => {
  return {
    id: unique(),
    type: 'text',
    name: 'example',
    label: 'Example',
    placeholder: 'Enter your details...',
    help: '',
    required: true,
    options: [],
    specialValue: '',
    stepKey: stepKey ?? 0, // Associate with active step in multi-step form
  }
}

/**
 * Checks for duplicate field names in the form and styles the input accordingly
 * @param {*} fields - Array of fields
 * @param {*} name - Field name
 * @returns A set of class names to apply to the input field if there is an error
 */
export function getInputErrorClassNames(fields, name) {
  return fields.filter((field) => field.name === name).length > 1
    ? 'text-danger border-danger'
    : undefined
}

/**
 * Checks if the input value is a reserved form data key and alerts the user if it is
 * @param {*} value - The input value
 * @returns A boolean indicating if the input value is a reserved form data key
 */
export function isReservedFormDataKey(value) {
  // Prevent reserved keys
  if (['name', 'email', 'phone'].includes(value)) {
    alert('That form data key is reserved, please choose another value.')
    return true
  } else {
    return false
  }
}

/**
 * Filters fields based on type and stepKey and removes core fields if not in preview mode.
 * @param {*} fields - Array of fields
 * @param {*} type - Form type (multi or single)
 * @param {*} stepKey - Active step key
 * @param {*} isPreview - Boolean to determine if core fields should be included for previews
 * @returns
 */
export const filterFields = (fields, type, stepKey, isPreview = false) => {
  const filteredFields = fields.filter((field) => {
    // Only render active step fields
    if (type === 'multi') {
      return field.stepKey === stepKey
    } else {
      return field
    }
  })

  return isPreview
    ? filteredFields
    : filteredFields.filter(
        (field) => field.name !== 'name' && field.name !== 'phone' && field.name !== 'email'
      )
}

// * Step Handler Functions

/**
 * Handles adding a new step to the form.
 * @param {*} currentSteps - Array of steps
 * @param {*} activeStep - The current active step
 * @param {*} insertStep - Function to insert a new step
 * @param {*} setActiveIndexState - Function to set the active index state
 */
export const handleAddStep = (currentSteps, activeStep, insertStep, setActiveIndexState) => {
  insertStep(currentSteps.length, defaultStep(currentSteps))
  setActiveIndexState({
    activeIndex: currentSteps.length,
    previousActiveIndex: activeStep,
  })
}

/**
 * Handles deleting a step from the form.
 * @param {*} activeStep - The current active step
 * @param {*} formSteps - Array of steps
 * @param {*} formFields - Array of fields
 * @param {*} removeField - Function to remove a field
 * @param {*} removeStep - Function to remove a step
 * @param {*} setActiveIndexState - Function to set the active index state
 */
export const handleDeleteStep = (
  activeStep,
  formSteps,
  formFields,
  removeField,
  removeStep,
  setActiveIndexState
) => {
  // Confirm delete action
  if (
    window.confirm(
      'Are you sure you want to delete this step? This action will delete all fields in this step.'
    )
  ) {
    // Remove fields associated with step
    const stepKey = formSteps[activeStep].key
    formFields
      .filter((field) => field.stepKey === stepKey)
      .map((field) => removeField(formFields.indexOf(field)))
    // Remove step
    removeStep(activeStep)
    // Update active index state
    setActiveIndexState({
      activeIndex: activeStep >= formSteps.length - 1 ? activeStep - 1 : activeStep,
      previousActiveIndex: undefined,
    })
  }
}

// *Field Handler Functions

/**
 * Handles adding a new field to the form. It groups fields by step and keeps them in order.
 * @param {*} activeStep - The current active step
 * @param {*} formSteps - Array of steps
 * @param {*} type - Form type (multi or single)
 * @param {*} formFields - Array of fields
 * @param {*} insertField - Function to insert a new field
 */
export const handleAddField = (activeStep, formSteps, type, formFields, insertField) => {
  // Generate an array of step keys that are before or equal to the active step
  const preSteps = formSteps.filter((step, index) => index <= activeStep).map((step) => step?.key)

  // Handle adding logic
  let preFields = []
  if (type === 'multi') {
    // For multistep get all fields that are in the current step or before
    preSteps.map((step) => {
      preFields = [...preFields, ...formFields.filter((field) => field.stepKey === step)]
    })
  } else {
    // For single step get all fields
    preFields = formFields
  }

  // Insert at the end of the current step fields
  insertField(
    preFields.length,
    defaultField(
      type === 'multi'
        ? formSteps[activeStep]?.key // Get the current step key
        : formSteps[formSteps.length - 1]?.key // Get the last step key
    )
  )
}

/**
 * Handles moving a field to a new step. It groups fields by step and keeps them in order.
 * @param {*} e - Input event
 * @param {*} moveField - Function to move a field
 * @param {*} fieldIndex - Index of the field being moved
 * @param {*} formSteps - Array of steps
 * @param {*} formFields - Array of fields
 */
export const handleMoveField = (e, moveField, fieldIndex, formSteps, formFields) => {
  // Remove the field being moved from the possible stepPreFields options
  const filteredFields = formFields.filter((field) => field.id !== formFields[fieldIndex].id)

  // Get last field of step being moved to
  const selectedStepIndex = formSteps.indexOf(
    formSteps.filter((step) => step.key === parseInt(e.target.value))[0]
  )
  let stepPreFields = filteredFields.filter((field) => field.stepKey === parseInt(e.target.value))

  if (stepPreFields?.length < 1) {
    for (let i = 1; i < formSteps.length; i++) {
      if (selectedStepIndex - i < 0) {
        break
      }
      stepPreFields = filteredFields.filter(
        (field) => field.stepKey === formSteps[selectedStepIndex - i]?.key
      )
      if (stepPreFields.length > 0) {
        break
      }
    }
  }

  // If no steps have fields, move to start of array
  if (stepPreFields.length < 1) {
    // Move to start of array
    moveField(fieldIndex, 0)
  } else {
    // Move to after last field in step before
    const lastPreField = stepPreFields[stepPreFields.length - 1]
    moveField(
      fieldIndex,
      formFields.indexOf(lastPreField) > fieldIndex
        ? formFields.indexOf(lastPreField)
        : formFields.indexOf(lastPreField) + 1
    )
  }
}

export function isValidSelectedValue(selectedValue, type, options) {
  // Only check if there are options
  if (options?.length < 1 || !selectedValue || selectedValue === '') {
    return true
  } else if (type === 'radio') {
    return options?.includes(selectedValue)
  } else if (type === 'radioCard') {
    return options?.map((option) => option.value).includes(selectedValue)
  }
  return null
}

export const CardOptionsEditor = ({
  fieldIndex,
  updateCardOptions,
  imageEndpoint,
  control,
  watch,
  setValue,
}) => {
  const { fields, insert, update, remove, move } = useFieldArray({
    control,
    name: `fields[${fieldIndex}].cardOptions`,
  })

  const handleAdd = useCallback(() => {
    insert(fields.length, {
      image: undefined,
      title: '',
      description: '',
      value: '',
    })
  }, [fields, insert])

  useEffect(() => {
    updateCardOptions(fields)
  }, [fields])

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12 d-flex justify-content-between mb-2">
          <span className="form-label">Options</span>
          <button
            className="btn btn-sm btn-primary d-flex align-items-center"
            onClick={() => handleAdd()}
          >
            <Add size="20px" color="white" /> <span className="ml-1">Option</span>
          </button>
        </div>
        {fields.map((cardOption, index) => (
          <div className="col-12 col-md-6 mb-3" key={`${fieldIndex}-${cardOption.id}`}>
            <div className="border rounded px-3 pt-3">
              <div className="row">
                <div className="col-12 d-flex justify-content-between">
                  <span className="form-label">Option {index + 1}</span>
                  <div className="d-flex">
                    {index > 0 && (
                      <button
                        className="d-flex align-items-center btn btn-sm btn-dark"
                        onClick={() => move(index, index - 1)}
                      >
                        <ArrowUp size="20px" color="white" />
                      </button>
                    )}
                    <button
                      className="d-flex align-items-center btn btn-sm btn-danger ml-1"
                      onClick={() => remove(index)}
                    >
                      <Cross size="20px" color="white" />
                    </button>
                  </div>
                </div>
                <div className="col-12">
                  <LabeledFileInput
                    item={cardOption}
                    itemName="image"
                    file={cardOption.image}
                    customOnChange={(data) => update(index, { ...cardOption, image: data })}
                    imageEndpoint={imageEndpoint}
                    accept="image/*,video/*"
                  />
                </div>
                <div className="col-12">
                  <LabeledInput
                    item={watch(`fields[${fieldIndex}].cardOptions[${index}]`)}
                    itemName="title"
                    customOnChange={(e) =>
                      setValue(`fields[${fieldIndex}].cardOptions[${index}].title`, e.target.value)
                    }
                  />
                </div>
                <div className="col-12">
                  <LabeledTextarea
                    item={watch(`fields[${fieldIndex}].cardOptions[${index}]`)}
                    itemName="description"
                    customOnChange={(e) =>
                      setValue(
                        `fields[${fieldIndex}].cardOptions[${index}].description`,
                        e.target.value
                      )
                    }
                  />
                </div>
                <div className="col-12">
                  <LabeledInput
                    item={watch(`fields[${fieldIndex}].cardOptions[${index}]`)}
                    itemName="value"
                    type="textarea"
                    customOnChange={(e) => {
                      if (
                        watch(`fields[${fieldIndex}].cardOptions`).filter(
                          (field) => field.value === e.target.value
                        ).length > 0
                      ) {
                        alert('Duplicate value detected. Please enter a unique value.')
                      } else {
                        setValue(
                          `fields[${fieldIndex}].cardOptions[${index}].value`,
                          e.target.value
                        )
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

/**
 * Returns the step options that are available for custom rules.
 * The options are steps that are at least 2 steps ahead of the active step AND
 * the step containing core fields cannot be skipped.
 */
export function getCustomRulesStepOptions(formSteps, activeIndex) {
  const stepOptions = []
  for (let i = activeIndex + 1; i < formSteps.length; i++) {
    const containsCoreFields = formSteps[i].key === 0

    if (containsCoreFields) {
      if (i - activeIndex > 1) {
        stepOptions.push(i)
      }
      break // Stop if the step contains core fields
    }

    if (i - activeIndex > 1) {
      stepOptions.push(i)
    }
  }

  return stepOptions
}

export const CustomRulesEditor = ({
  stepIndex,
  stepOptions,
  fieldOptions,
  updateCustomRules,
  control,
  setValue,
  watch,
}) => {
  const { fields, insert, update, remove, move } = useFieldArray({
    control,
    name: `formSteps[${stepIndex}].customRules`,
  })

  const handleAdd = useCallback(() => {
    insert(fields.length, {
      type: 'skip',
      to: '',
      key: '',
      value: undefined,
    })
  }, [fields, insert])

  useEffect(() => {
    updateCustomRules(fields)
  }, [fields])

  return stepOptions.length < 1 ? null : (
    <div className="col-12">
      <div className="border-top pt-3"></div>
      <div className="row">
        <div className="col-12 d-flex justify-content-between mb-3">
          <h5 className="mb-0">Custom Rules</h5>
          <button
            className="btn btn-sm btn-primary d-flex align-items-center"
            onClick={() => handleAdd()}
          >
            <Add size="20px" color="white" /> <span className="ml-1">Rule</span>
          </button>
        </div>
        {watch(`formSteps[${stepIndex}].customRules`)?.map((rule, index) => (
          <div className="col-12 mb-3" key={`${stepIndex}-${rule.id}`}>
            <div className="border rounded px-3 pt-3">
              <div className="row">
                <div className="col-12 d-flex justify-content-between">
                  <span className="form-label">Rule {index + 1}</span>
                  <div className="d-flex">
                    {index > 0 && (
                      <button
                        className="d-flex align-items-center btn btn-sm btn-dark"
                        onClick={() => move(index, index - 1)}
                      >
                        <ArrowUp size="20px" color="white" />
                      </button>
                    )}
                    <button
                      className="d-flex align-items-center btn btn-sm btn-danger ml-1"
                      onClick={() => remove(index)}
                    >
                      <Cross size="20px" color="white" />
                    </button>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <LabeledSelect
                    item={rule}
                    itemName="type"
                    options={['skip']}
                    customOnChange={(e) => update(index, { ...rule, type: e.target.value })}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <LabeledSelect
                    item={rule}
                    itemName="to"
                    options={[
                      {
                        label: 'Please select a step to skip to',
                        value: '',
                        disabled: true,
                      },
                      ...stepOptions.map((step) => {
                        return {
                          label: `Step ${step + 1}`,
                          value: step,
                        }
                      }),
                    ]}
                    customOnChange={(e) => update(index, { ...rule, to: e.target.value })}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <LabeledSelect
                    item={rule}
                    itemName="key"
                    options={[
                      {
                        label: 'Please select the field to check',
                        value: '',
                        disabled: true,
                      },
                      ...fieldOptions.map((option) => {
                        return {
                          label: option,
                          value: option,
                        }
                      }),
                    ]}
                    customOnChange={(e) => update(index, { ...rule, key: e.target.value })}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <LabeledInput
                    controlled={false}
                    item={rule}
                    itemName="value"
                    customOnChange={(e) =>
                      setValue(
                        `formSteps[${stepIndex}].customRules[${index}].value`,
                        e.target.value
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
