import { default as React } from 'react'
import { useState } from 'react'
import { FormGroup } from './FormGroup'
import InputLabel from './InputLabel'

export const LabeledMultipleInput = ({
  item,
  itemName,
  label,
  updateItem,
  customAdd,
  customDelete,
  tooltip,
}) => {
  const [currentInput, setCurrentInput] = useState('')
  const [options, setOptions] = useState(item[itemName] || [])

  const add = () => {
    if (customAdd) {
      customAdd(options, itemName, currentInput, setCurrentInput, setOptions)
      return
    }
    if (options.includes(currentInput)) {
      alert('You have already added ' + currentInput)
    } else if (currentInput.length > 0) {
      setOptions([...options, currentInput])
      updateItem({ ...item, [itemName]: [...options, currentInput] })
      setCurrentInput('')
    }
  }

  const handleDelete = (option) => {
    if (customDelete) {
      customDelete(options, itemName, option, setOptions)
      return
    }
    const filteredOptions = options.filter((o) => o !== option)
    setOptions(filteredOptions)
    updateItem({ ...item, [itemName]: filteredOptions })
  }

  return (
    <FormGroup>
      <InputLabel label={label} itemName={itemName} tooltip={tooltip} />
      <div className="d-flex">
        <input
          id={itemName}
          placeholder={`Enter ${itemName}...`}
          className="form-control"
          value={currentInput}
          onChange={(e) => setCurrentInput(e.target.value)}
        />
        <button onClick={add} className="btn btn-sm btn-primary mx-2">
          Add
        </button>
      </div>
      <div className="d-flex align-items-center mt-3 flex-wrap">
        {options?.map((option, id) => (
          <span
            key={`input-item-${id}`}
            className={`bg-light d-flex align-items-center mb-1 py-1 px-2 ${id > 0 ? 'mx-1' : ''}`}
            style={{ borderRadius: 24 }}
          >
            {option}{' '}
            <div
              className="btn btn-outline-danger"
              onClick={() => handleDelete(option)}
              style={{ border: 'none', borderRadius: '500px' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                style={{ height: 20, width: 20 }}
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </span>
        ))}
      </div>
    </FormGroup>
  )
}
