import Container from './Container'
import { cleanHtml } from '../utils'

/** Handle the 6 types of heading elements */
const HeadingTag = ({ data }) => {
  const inner = (
    <span
      dangerouslySetInnerHTML={{
        __html: cleanHtml(data?.text),
      }}
    />
  )

  switch (data?.level) {
    case 1:
      return <h1>{inner}</h1>
    case 2:
      return <h2>{inner}</h2>
    case 3:
      return <h3>{inner}</h3>
    case 4:
      return <h4>{inner}</h4>
    case 5:
      return <h5>{inner}</h5>
    case 6:
      return <h6>{inner}</h6>
    default:
      return null
  }
}

const Header = ({ block, isInRow }) => (
  <Container tunes={block?.tunes} isInRow={isInRow} isText>
    <HeadingTag data={block.data} />
  </Container>
)

const Paragraph = ({ block, isInRow }) => (
  <Container tunes={block?.tunes} isInRow={isInRow} isText>
    <p
      dangerouslySetInnerHTML={{
        __html: cleanHtml(block.data?.text),
      }}
      id={block.id}
    ></p>
  </Container>
)

const ListItems = ({ items, blockId }) => (
  <>
    {items.map((item, id) => (
      <li key={`${blockId}-list-item-${id}`} style={{ padding: '5.5px 0 5.5px 3px' }}>
        <span dangerouslySetInnerHTML={{ __html: cleanHtml(item) }} />
      </li>
    ))}
  </>
)

const List = ({ block, isInRow }) => {
  const { items, style } = block.data
  return (
    <Container tunes={block?.tunes} isInRow={isInRow} isText>
      {style === 'ordered' ? (
        <ol>
          <ListItems items={items} blockId={block.id} />
        </ol>
      ) : (
        <ul>
          <ListItems items={items} blockId={block.id} />
        </ul>
      )}
    </Container>
  )
}

const Image = ({ block, isInRow, isAlternatingColumns }) => {
  const { file, caption, imageLayout } = block.data

  const imageContainerStyle = {
    position: 'relative',
    width: '100%',
    aspectRatio: '2 / 1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  }

  const imageStyle = {
    width: '100%',
    maxWidth: isAlternatingColumns ? '100%' : '75%',
    height: '100%',
    objectFit: imageLayout ?? 'contain',
  }

  return (
    <Container tunes={block.tunes} isInRow={isInRow} isImage>
      <div style={imageContainerStyle}>
        <img src={file.url} alt={caption} style={imageStyle} />
      </div>
    </Container>
  )
}

const Table = ({ block }) => {
  const content = block.data?.content
  if (!content) {
    return null
  }
  if (block.data?.withHeadings) {
    const headings = content[0]
    const rows = content.slice(1)
    return (
      <Container tunes={block?.tunes} isInRow>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {headings.map((item) => (
                  <th key={item} scope="col">
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, i) => (
                <tr key={`${block.id}-tr-${i}`}>
                  {row.map((item, id) => (
                    <td key={`${block.id}-td-${i}-${id}`}>{item}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    )
  }
  return (
    <Container tunes={block?.tunes} isInRow>
      <div className="table-responsive">
        <table className="table">
          <tbody>
            {content.map((row, i) => (
              <tr key={`${block.id}-tr-${i}`}>
                {row.map((item, id) => (
                  <td key={`${block.id}-td-${i}-${id}`}>{item}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Container>
  )
}

const HTMLBlocks = {
  header: Header,
  paragraph: Paragraph,
  list: List,
  image: Image,
  table: Table,
}

export { HTMLBlocks }
