import { default as React } from 'react'
import { ActionButtons } from './ActionButtons'

export const ItemOption = ({
  index,
  item,
  title,
  edit,
  hideBtnText,
  children,
  deleteItem,
  moveUp,
  setEdit,
  hidePreview = false,
  className,
}) => {
  return (
    <div className={`border rounded bg-white pt-3 px-3 ${className}`}>
      <div className="row justify-content-center mb-2">
        <div className="col-sm-6 col-md-4 d-flex align-items-start">
          <h6 className="text-left text-muted">
            {title.toUpperCase()} {index + 1}
          </h6>
        </div>
        <div className="col-sm-6 col-md-8 d-flex justify-content-end">
          <ActionButtons
            index={index}
            item={item}
            edit={edit}
            hideBtnText={hideBtnText}
            deleteItem={deleteItem}
            moveUp={moveUp}
            setEdit={setEdit}
            hidePreview={hidePreview}
          />
        </div>
      </div>
      {children}
    </div>
  )
}
