
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { ready } from '../entries/utils'

const LoadingBoxes = () => {

  return (
    <div className="blink box p-3 mb-3">
      <img src="https://i.ibb.co/dG7bYq0/bar-skeleton.png" />
    </div>
  )
};

const addLoadingBoxes = () => {
  let loadingBoxes = document.querySelectorAll('.loadingBoxes')
  if (loadingBoxes) {
    loadingBoxes.forEach((loadingBox) => {
      const root = ReactDOM.createRoot(loadingBox)
      root.render(<LoadingBoxes />);
    })
  }
}

ready(function () {
  addLoadingBoxes()
})

window.addLoadingBoxes = addLoadingBoxes