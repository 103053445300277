const CardsPreview = ({ item, isModalPreview }) => {
  const logoFallback =
    'https://res.cloudinary.com/total-dealer/image/upload/v1709174465/td_dashboard/editor-placeholder_bvllx4.png'

  const brandCardStyle = {
    background: 'white',
    border: '1px solid #ccc',
    padding: '2rem 1rem',
    borderRadius: '0.5rem',
  }

  const imageContainerStyle = {
    position: 'relative',
    width: '100%',
    aspectRatio: '2 / 1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  }

  const imageStyle = {
    width: '100%',
    maxWidth: '75%',
    height: '100%',
    objectFit: 'contain',
  }

  return (
    <div className={isModalPreview ? 'w-50' : 'col-3 col-lg-2 mb-3 px-1 px-md-2'}>
      <div className="d-flex justify-content-center align-items-center" style={brandCardStyle}>
        <div style={imageContainerStyle}>
          <img src={item.logo?.url ?? logoFallback} alt={`${item.make} logo`} style={imageStyle} />
        </div>
      </div>
    </div>
  )
}

export default CardsPreview
