// EditorJS Components
import EditorJS from '@editorjs/editorjs'
import Embed from '@editorjs/embed'
import RawTool from '@editorjs/raw'

// Utils
import { ready } from '../entries/utils'
import { initialiseStyling } from './common/Utils'
import {
  getAlternatingColumnsConfig,
  getBrandDescriptionConfig,
  getBrandModelsConfig,
  getButtonsConfig,
  getCardsConfig,
  getCarsConfig,
  getColumnsConfig,
  getCustomComponentConfig,
  getFaqConfig,
  getFinanceCalculatorConfig,
  getFormBuilderConfig,
  getFormConfig,
  getHeaderConfig,
  getHorizontalRuleConfig,
  getIconButtonsToolConfig,
  getImageBackgroundConfig,
  getImageBannerConfig,
  getImageConfig,
  getImageSliderConfig,
  getInlineTools,
  getListConfig,
  getLocationsConfig,
  getModelColorsConfig,
  getModelFeaturesConfig,
  getModelVariantsConfig,
  // getNestedListConfig,
  getOffersConfig,
  getParagraphConfig,
  getReviewsConfig,
  getTableConfig,
  getTagConfig,
  getTeamConfig,
  getTunes,
  getVideoConfig,
  getWebsiteBrandsConfig,
} from './editorConfig'
import { handleTemplateRefresh } from './templates/Model/modelRefreshUtils'
import {
  getSearchTemplateEditorTools,
  handleSearchOnChangeEvents,
  handleSearchOnReadyEvents,
} from './templates/Search/searchUtils'
import { fetchData, getTemplate } from './templates/templateUtils'

ready(async function () {
  // Added a 10ms timeout to allow render time for any react component using jseditor
  setTimeout(async () => {
    let input = document.getElementById('draft-blocks-json')
    let editor = document.getElementById('editorjs')

    if (editor) {
      let imageUrl = editor.getAttribute('imageUrl')
      // Convert to api fetch
      // Need new API, data should be same as _new_form.html.haml
      // getEditorInitData()
      const locationJSON = editor.getAttribute('locations')
      const locations = JSON.parse(locationJSON)
      const brandJSON = editor.getAttribute('brands')
      const brands = JSON.parse(brandJSON)
      const manufacturerId = editor.getAttribute('manufacturer_id')
      const modelSlug = editor.getAttribute('model_slug')
      const primaryLocationId = editor.getAttribute('primary_location_id')
      const websiteId = editor.getAttribute('website_id')
      const ownerManufacturerId = editor.getAttribute('owner_manufacturer_id') // If the manufacturer is the owner of the website
      const templateType = editor.getAttribute('template_type')
      const isAdmin = editor.getAttribute('is_admin')

      // Set up template data object
      const templateData = {
        website_id: websiteId ?? undefined,
        type:
          // Handle existing model/manufacturer templates with default type
          templateType === 'default'
            ? modelSlug
              ? 'model'
              : manufacturerId
              ? 'manufacturer'
              : templateType // default
            : // Use template type if it is not default
              templateType,
        manufacturer: manufacturerId !== '' ? manufacturerId : ownerManufacturerId ?? undefined,
        model: modelSlug !== '' ? modelSlug : undefined,
      }

      // TODO: update all Model related tools to account for OwnerManufacturerId

      if (input) {
        let data = {}
        if (input.value) {
          data = JSON.parse(input.value)

          // Only runs if it is a template page and data is empty
          if (
            templateData.type !== 'default' &&
            (Object.keys(data).length < 1 || data?.blocks?.length < 1)
          ) {
            const template = await getTemplate(templateData, brands)
            data.blocks = template
            input.value = JSON.stringify(data)
          }
        }

        // The ImageBackgroundTool is deprecated as per 28/03/24, we only allow for existing instances
        const hasImageBackground =
          data?.blocks?.some((block) => block?.type === 'imageBackground') ?? false

        const editor = new EditorJS({
          holder: 'editorjs',
          placeholder: 'Type your content here!',
          tools:
            templateData.type === 'search'
              ? getSearchTemplateEditorTools(
                  saved,
                  imageUrl,
                  brands,
                  locations,
                  templateData,
                  isAdmin
                )
              : {
                  header: getHeaderConfig(),
                  paragraph: getParagraphConfig(),
                  image: getImageConfig(imageUrl),
                  list: getListConfig(),
                  // nestedList: getNestedListConfig(),
                  table: getTableConfig(),
                  horizontalRule: getHorizontalRuleConfig(saved),
                  embed: Embed,
                  cars: getCarsConfig(saved),
                  offers: getOffersConfig(saved),
                  form: getFormConfig(saved),
                  formBuilder: getFormBuilderConfig(locations, imageUrl),
                  buttonsTool: getButtonsConfig(saved),
                  tag: getTagConfig(saved),
                  cardsTool: getCardsConfig(imageUrl, saved),
                  faq: getFaqConfig(imageUrl, locations, saved, true),
                  locations: getLocationsConfig(locations, primaryLocationId, saved),
                  reviews: getReviewsConfig(saved),
                  team: getTeamConfig(saved),
                  raw: RawTool,
                  columns: getColumnsConfig(imageUrl, locations, saved, true),
                  alternatingColumns: getAlternatingColumnsConfig(imageUrl, locations, saved, true),
                  imageBanner: getImageBannerConfig(brands, templateData, imageUrl, saved),
                  imageSlider: getImageSliderConfig(imageUrl, saved),
                  websiteBrands: getWebsiteBrandsConfig(brands, imageUrl, saved),
                  brandModels: getBrandModelsConfig(brands, ownerManufacturerId, saved),
                  modelVariants: getModelVariantsConfig(brands, ownerManufacturerId, saved),
                  modelColors: getModelColorsConfig(brands, ownerManufacturerId, saved),
                  iconButtonsTool: getIconButtonsToolConfig(saved),
                  brandDescription: getBrandDescriptionConfig(brands, imageUrl, saved),
                  modelFeatures: getModelFeaturesConfig(imageUrl, saved),
                  financeCalculator: getFinanceCalculatorConfig(saved),
                  video: getVideoConfig(saved),
                  customComponent: getCustomComponentConfig(templateData, isAdmin, saved),
                  ...(hasImageBackground && {
                    imageBackground: getImageBackgroundConfig(imageUrl, saved),
                  }),
                  // Custom inline tools
                  ...getInlineTools(),
                  // Tunes
                  ...getTunes(saved),
                },
          autofocus: false,
          /** To make the Editor have more screen-space, close the sidebar automatically */
          onReady: async () => {
            const sidebar = document.getElementById('sidebar')
            if (sidebar && !sidebar.classList.contains('collapsed')) {
              sidebar.classList.toggle('collapsed')
            }

            editor.configuration.data.blocks.map((block) => {
              const div = document.querySelector(`[data-id='${block.id}']`)
              return initialiseStyling(div, block.tunes)
            })

            // Add onReady event listeners for Search pages
            if (templateData.type === 'search') {
              handleSearchOnReadyEvents(editor)
            } else if (templateData.type === 'model') {
              // Fetch the updated model data
              const updatedModelData = await fetchData('models', templateData.model).then(
                (data) => {
                  return data
                }
              )

              // Add template refresh logic for model pages
              if (updatedModelData) {
                handleTemplateRefresh(updatedModelData, editor)
              }
            }

            // Force editor to save its state data on ready
            // This is to ensure templates are loaded correctly
            saved()
          },
          onChange: (api, event) => {
            // Add onChange event listeners for Search pages
            if (templateData.type === 'search') {
              handleSearchOnChangeEvents(api, event)
            }
            saved()
          },
          data: data,
        })

        // Save Example
        async function saved() {
          // Wait for the editor to be ready before allowing save function to be called
          await editor.isReady
          editor
            .save()
            .then((savedData) => {
              const event = new CustomEvent('editorSave', { detail: JSON.stringify(savedData) })
              window.dispatchEvent(event)
              input.value = JSON.stringify(savedData)
            })
            .catch((error) => {
              console.error('Saving error', error)
            })
        }
      }
    }
  }, 10)
})
