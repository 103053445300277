import { generateRandomId } from '../../common/Utils'
import {
  LocationProps,
  ModelColourProps,
  ModelFeatureProps,
  ModelProps,
  ModelVariantProps,
} from '../../types'

/**
 * Creates a full-width section based on a feature.
 * @param feature - The feature data.
 * @returns An array of blocks representing the full-width section.
 */
function getFullWidthSection(model: ModelProps, feature: ModelFeatureProps) {
  return [
    {
      id: generateRandomId(10),
      type: 'imageBanner',
      data: {
        image: {
          type: 'custom',
          existingCategory: 'model',
          existingFilters: {
            manufacturer: model.manufacturer_id,
            model: model.slug,
          },
          image: {
            url: feature.image?.url,
            previewUrl: feature.image?.url,
          },
        },
        layout: {
          type: 'fullSize',
          imageLayout: 'cover',
          customAspectRatio: {
            height: 1,
            width: 4,
          },
          customHeight: {
            mobileHeight: 250,
            desktopHeight: 450,
          },
        },
        overlay: {
          active: 'false',
          color: '#000000',
          opacity: 50,
        },
        content: {
          active: 'false',
          blocks: [
            {
              id: generateRandomId(10),
              type: 'header',
              data: {
                text: 'Image Banner',
                level: 2,
              },
              tunes: {
                alignment: {
                  alignment: 'center',
                },
                container: {
                  contain: true,
                },
                textColor: {
                  textColor: 'light',
                  customTextColor: '#000000',
                },
                margin: {
                  spacingTop: 5,
                  spacingBottom: 5,
                },
                padding: {
                  paddingTop: 0,
                  paddingBottom: 0,
                },
                classname: {
                  className: '',
                },
              },
            },
          ],
        },
        featureId: feature.id,
      },
      tunes: {
        margin: {
          spacingTop: 0,
          spacingBottom: 0,
        },
        screensize: {
          screenSize: 'all',
        },
        classname: {
          className: '',
        },
      },
    },
    {
      id: generateRandomId(10),
      type: 'header',
      data: { text: feature.title, level: 3, featureId: feature.id },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 5, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
    {
      id: generateRandomId(10),
      type: 'paragraph',
      data: {
        text: feature.description,
        featureId: feature.id,
      },
      tunes: {
        alignment: { alignment: 'center' },
        container: { contain: false },
        backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
        textColor: { textColor: 'none', customTextColor: '#000000' },
        margin: { spacingTop: 0, spacingBottom: 0 },
        padding: { paddingTop: 0, paddingBottom: 0 },
        classname: { className: '' },
      },
    },
  ]
}

/**
 * Creates an alternating column structure based on a feature.
 * @param feature - The feature data.
 * @returns An object containing image and text columns.
 */
function getAlternatingColumn(feature: ModelFeatureProps) {
  return {
    imgCol: {
      time: 1695619369360,
      blocks: [
        {
          id: generateRandomId(10),
          type: 'image',
          data: {
            file: {
              url: feature.image.url,
            },
            caption: feature.title,
            withBorder: false,
            stretched: false,
            withBackground: false,
            featureId: feature.id,
          },
        },
      ],
      version: '2.26.4',
    },
    textCol: {
      time: 1695619369361,
      blocks: [
        {
          id: generateRandomId(10),
          type: 'header',
          data: { text: feature.title, level: 4, featureId: feature.id },
          tunes: { alignment: { alignment: 'center' }, classname: { className: '' } },
        },
        {
          id: generateRandomId(10),
          type: 'paragraph',
          data: {
            text: feature.description,
            featureId: feature.id,
          },
          tunes: { alignment: { alignment: 'center' }, classname: { className: '' } },
        },
      ],
      version: '2.26.4',
    },
  }
}

/**
 * Creates an alternating columns section based on an array of features.
 * @param features - An array of feature data.
 * @returns An object representing the alternating columns section.
 */
function getAlternatingColumnsSection(features: ModelFeatureProps[]) {
  const columns = []

  // Map to pages editor data structure
  features.map((feature) => columns.push(getAlternatingColumn(feature)))

  return {
    id: generateRandomId(10),
    type: 'alternatingColumns',
    data: {
      columns,
      reverse: true,
      variant: true,
      isTemplateFeatures: true,
    },
    tunes: { container: { contain: false }, classname: { className: '' } },
  }
}

/**
 * Creates a card based on a feature.
 * @param feature - The feature data.
 * @returns A card object.
 */
function getCard(feature: ModelFeatureProps) {
  return {
    id: generateRandomId(10),
    heading: feature.title,
    description: feature.description,
    linkText: '',
    href: '',
    image: {
      file: {},
      url: feature.image?.url,
    },
    featureId: feature.id,
  }
}

/**
 * Creates a cards section based on an array of features and card columns.
 * @param features - An array of feature data.
 * @param cardCols - Number of card columns.
 * @returns An object representing the cards section.
 */
function getCardsSection(features: ModelFeatureProps[], cardCols: number) {
  const cards = []

  // Map to pages editor data structure
  features.map((feature) => cards.push(getCard(feature)))

  return {
    id: generateRandomId(10),
    type: 'cardsTool',
    data: {
      activated: true,
      isCarousel: false,
      cards,
      heading: '',
      subheading: '',
      type: 'shadow',
      rounded: false,
      imageHeight: null,
      imageWidth: null,
      btnType: 'fill',
      btnColour: 'primary',
      preview: true,
      starter: 'empty',
      cardCols,
      isTemplateFeatures: true,
    },
    tunes: {
      alignment: { alignment: 'left' },
      container: { contain: false },
      margin: { spacingTop: 0, spacingBottom: 0 },
      padding: { paddingTop: 0, paddingBottom: 0 },
      classname: { className: '' },
    },
  }
}

/**
 * Handles features data and transforms it into a structured format for a page editor.
 * @param features - An array of feature data.
 * @returns - An array of structured data for the page editor.
 */
function handleFeatures(model: ModelProps, features: ModelFeatureProps[]) {
  const featuresData = []

  // Fullwidth Features
  const fullWidthFeatures = []
  let tempFeaturesHandler = []
  features
    .filter((f) => ['default', 'full-width'].includes(f.template))
    .slice(0, 12) // Limiting the length of features to 12
    .map((feature) => {
      if (feature.template === 'default') {
        tempFeaturesHandler.push(feature)
      } else {
        // Push the existing alt columns data and reset to empty array
        if (tempFeaturesHandler.length > 0) {
          fullWidthFeatures.push(tempFeaturesHandler)
          tempFeaturesHandler = []
        }
        // Add the full width data
        fullWidthFeatures.push(feature)
      }
    })

  // Add any left over alt columns data
  if (tempFeaturesHandler.length > 0) {
    fullWidthFeatures.push(tempFeaturesHandler)
    tempFeaturesHandler = []
  }

  // Convert fullwidth features to page editor data
  fullWidthFeatures.map((feature) => {
    if (Array.isArray(feature)) {
      featuresData.push(getAlternatingColumnsSection(feature))
    } else {
      featuresData.push(...getFullWidthSection(model, feature))
    }
  })

  // Grouped Features
  const addCardSectionIfNotEmpty = (template, columns) => {
    const filteredFeatures = features.filter((f) => [template].includes(f.template)).slice(0, 8)
    if (filteredFeatures.length > 0) {
      featuresData.push(getCardsSection(filteredFeatures, columns))
    }
  }

  // Add card sections for different column layouts
  addCardSectionIfNotEmpty('col-3', 3)
  addCardSectionIfNotEmpty('col-4', 4)
  addCardSectionIfNotEmpty('col-6', 6)
  addCardSectionIfNotEmpty('col-12', 12)

  return featuresData
}

/**
 * Handles the banner data and transforms it into a structured format for a page editor.
 * @param model - The model data containing banner information.
 * @returns Array containing config for video or image banner.
 */
function handleBanner(model: ModelProps) {
  const bannerImage = model.banner && model.banner.key !== null ? model.banner : null
  const bannerVideo = model.hero_video && model.hero_video.key !== null ? model.hero_video : null

  // Priority = Video > Image > null
  return bannerVideo
    ? [
        {
          id: generateRandomId(10),
          type: 'video',
          data: {
            useCustom: false,
            isManufacturerPage: model.name,
            url: `https://res.cloudinary.com/total-dealer/video/upload/v1644660263/production/${bannerVideo.key}`,
            isTemplateBanner: true,
          },
          tunes: {
            container: {
              contain: false,
            },
            margin: {
              spacingTop: 0,
              spacingBottom: 0,
            },
            screensize: {
              screenSize: 'all',
            },
            classname: {
              className: '',
            },
          },
        },
      ]
    : bannerImage
    ? [
        {
          id: generateRandomId(10),
          type: 'imageBanner',
          data: {
            image: {
              type: 'default',
              existingCategory: 'model',
              existingFilters: {
                manufacturer: model.manufacturer_id,
                model: model.slug,
              },
              image: {
                url: 'default',
                previewUrl: bannerImage.url,
              },
            },
            layout: {
              type: 'fullSize',
              imageLayout: 'cover',
              customAspectRatio: {
                height: 1,
                width: 4,
              },
              customHeight: {
                mobileHeight: 250,
                desktopHeight: 450,
              },
            },
            overlay: {
              active: 'false',
              color: '#000000',
              opacity: 50,
            },
            content: {
              active: 'false',
              blocks: [
                {
                  id: generateRandomId(10),
                  type: 'header',
                  data: {
                    text: 'Image Banner',
                    level: 2,
                  },
                  tunes: {
                    alignment: {
                      alignment: 'center',
                    },
                    container: {
                      contain: true,
                    },
                    textColor: {
                      textColor: 'light',
                      customTextColor: '#000000',
                    },
                    margin: {
                      spacingTop: 5,
                      spacingBottom: 5,
                    },
                    padding: {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                    classname: {
                      className: '',
                    },
                  },
                },
              ],
            },
            isTemplateBanner: true,
          },
          tunes: {
            margin: {
              spacingTop: 0,
              spacingBottom: 0,
            },
            classname: {
              className: '',
            },
          },
        },
      ]
    : []
}

/**
 * THandles model variants by checking if there are any variants available.
 * If there are variants, it generates a new object with a random ID, type 'modelVariants',
 * and data containing manufacturer and model information. If there are no variants, it returns an empty array.
 *
 * @param modelVariants - An array of model variants to be checked.
 * @param modelId - The ID of the model.
 * @param manufacturerId - The ID of the manufacturer.
 * @returns - An array containing new object with variant information or empty if no variants are available.
 */
function handleVariants(
  modelVariants: ModelVariantProps[],
  modelId: number,
  manufacturerId: number
) {
  return modelVariants?.length > 0
    ? [
        {
          id: generateRandomId(10),
          type: 'modelVariants',
          data: {
            manufacturer: manufacturerId,
            model: modelId,
          },
        },
      ]
    : []
}

/**
 * Handles model colors by checking if there are any colors available.
 * If there are colors, it generates a new object with a random ID, type 'modelColors',
 * and data containing manufacturer and model information. If there are no colors, it returns an empty array.
 *
 * @param modelColors - An array of model colors to be checked.
 * @param modelId - The ID of the model.
 * @param manufacturerId - The ID of the manufacturer.
 * @returns - An array containing a new object with color information or empty if no colors are available.
 */
function handleColors(modelColors: ModelColourProps[], modelId: number, manufacturerId: number) {
  return modelColors?.length > 0
    ? [
        {
          id: generateRandomId(10),
          type: 'modelColors',
          data: {
            manufacturer: manufacturerId,
            model: modelId,
          },
        },
      ]
    : []
}

/**
 * Handles the location select data based on unique locations.
 * @param uniqueLocations - An array of unique locations.
 * @returns The location select data object.
 */
function handleLocationSelectData(uniqueLocations: LocationProps[]) {
  const salesLocations = []

  // Filter and map unique locations to extract sales locations' IDs
  uniqueLocations
    .filter(
      (location) =>
        location.location_type === 'sales' ||
        location.location_type === '' ||
        location.location_type === null
    )
    .map((location) => salesLocations.push(`${location.id}`))

  // If there are multiple sales locations, return a location select object
  // If there's only one sales location or none, return a hidden field object
  return salesLocations.length > 1
    ? {
        id: generateRandomId(10),
        type: 'locationSelect',
        name: 'location_id',
        label: 'Dealership Location',
        placeholder: 'Select a dealership location...',
        options: salesLocations,
        specialValue: '',
        stepKey: 0,
      }
    : {
        id: generateRandomId(10),
        type: 'hidden',
        name: 'location_id',
        label: 'Example',
        placeholder: 'Enter your details..',
        options: [],
        specialValue: salesLocations[0],
        stepKey: 0,
      }
}

function parseDisclaimerHTMLString(inputHtml: string) {
  // Create a temporary div element to parse the HTML
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = inputHtml

  // Check if the input contains <ul> tags
  const ulElements = tempDiv.querySelectorAll('ul')

  // If there are <ul> tags, extract and return the <li> elements as an array of strings
  if (ulElements.length > 0) {
    const listItems = []

    ulElements.forEach((ulElement) => {
      ulElement.querySelectorAll('li').forEach((liElement) => {
        const liText = liElement.textContent.trim()

        // Remove any remaining HTML tags
        const liTextWithoutHTML = liText.replace(/<\/?[^>]+(>|$)/g, '')

        listItems.push(liTextWithoutHTML)
      })
    })

    return listItems
  } else {
    // If there are no <ul> tags, return the input string as a single string after removing HTML tags
    const inputTextWithoutHTML = inputHtml.replace(/<\/?[^>]+(>|$)/g, '')
    return inputTextWithoutHTML.trim()
  }
}

/**
 * Handles the disclaimer text and formats it for display.
 * @param disclaimer - The original disclaimer text.
 * @returns - An array of formatted disclaimer items.
 */
function handleDisclaimer(disclaimer: string) {
  const disclaimerContent = parseDisclaimerHTMLString(disclaimer)

  return Array.isArray(disclaimerContent)
    ? {
        id: generateRandomId(10),
        type: 'list',
        data: {
          style: 'unordered',
          items: disclaimerContent,
        },
        tunes: {
          container: { contain: false },
          margin: { spacingTop: 0, spacingBottom: 5 },
          padding: { paddingTop: 5, paddingBottom: 0 },
          classname: { className: 'small border-top disclaimer' },
        },
      }
    : {
        id: generateRandomId(10),
        type: 'paragraph',
        data: {
          text: disclaimerContent,
        },
        tunes: {
          alignment: { alignment: 'left' },
          container: { contain: false },
          backgroundColor: { backgroundColor: 'none', customBackgroundColor: '#000000' },
          textColor: { textColor: 'none', customTextColor: '#000000' },
          margin: { spacingTop: 0, spacingBottom: 5 },
          padding: { paddingTop: 5, paddingBottom: 0 },
          classname: { className: 'small border-top disclaimer' },
        },
      }
}

export {
  getFullWidthSection,
  getAlternatingColumn,
  getAlternatingColumnsSection,
  getCard,
  getCardsSection,
  handleFeatures,
  handleBanner,
  handleVariants,
  handleColors,
  handleLocationSelectData,
  handleDisclaimer,
}
