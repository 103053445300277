import { default as React } from 'react'
import { createRoot } from 'react-dom/client'

// ! Improve placeholder
const RenderedTeamComponent = () => (
  <div className="d-flex flex-column align-items-center border rounded shadow-sm p-3 mb-3">
    <h5 className="text-center">
      <i className="fa fa-star mr-2" aria-hidden="true"></i>
      Team Section
    </h5>
    {/* If we want to enable TeamVariants we can use this: 
    <div className="form-group">
      <label htmlFor="variant-select">Display Option</label>
      <select
        className="form-control"
        id="variant-select"
        defaultValue={data.variant}
        onChange={(e) => onDataChange({ variant: e.target.value })}
      >
        <option value="simple">Simple</option>
        <option value="highlighted">Highlighted</option>
        <option value="full">Full</option>
      </select>
    </div> */}
  </div>
)

class TeamTool {
  static get toolbox() {
    return {
      title: 'Team',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
    </svg>`,
    }
  }

  constructor({ data, config, api, block, readOnly }) {
    this.api = api
    this.readOnly = readOnly
    this.config = config
    this.blockAPI = block

    let defaultValue = {
      variant: 'simple',
    }

    this.data = data || defaultValue

    this.CSS = {
      wrapper: 'walkthrough-timeline',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData) => {
      this.data = {
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(<RenderedTeamComponent />)

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default TeamTool
