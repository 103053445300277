import { API, BlockAPI } from '@editorjs/editorjs'
import { useEffect, useState } from 'react'
import { createRoot } from 'react-dom/client'
import { Mode } from 'vanilla-jsoneditor'
import { LabeledInput } from '../../common'
import { FormGroup } from '../../common/FormGroup'
import InputLabel from '../../common/InputLabel'
import JSONEditorReact from '../../common/JsonEditor'
import type { DefaultConfigProps, NodesProps, ToolboxProps } from '../../types/toolData.type'
import {
  type CustomComponentDataProps,
  fetchComponentOptions,
  useDebounce,
} from './customComponentUtils'

const RenderedFormComponent = ({ data, onDataChange, isAdmin, templateType, editorApi }) => {
  const [state, setState] = useState(data)
  const [json, setJson] = useState(data.customComponentProps ?? { json: { default: 'Value' } })
  const debouncedJson = useDebounce(json)

  const updateState = (updatedValue: Object) => {
    const updatedValues = { ...state, ...updatedValue }
    setState(updatedValues)
    onDataChange(updatedValues)
  }

  function setUniqueComponentId(value: string) {
    updateState({ uniqueComponentId: value })
  }

  function setCustomComponentId(e: any) {
    updateState({ customComponentId: e.target.value })
  }

  useEffect(() => {
    updateState({ customComponentProps: json })
  }, [debouncedJson])

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="border rounded shadow-sm pt-3 px-3 bg-white">
            <div className="d-flex flex-column text-center mb-2 mx-0 mx-lg-5">
              <div>
                <p className="badge badge-warning mb-1">Warning</p>
              </div>
              <span className="small">
                This component is used to implement custom components that aren't customisable in
                the editor. All tune customisations are available, but each component may only be
                able to utilise specific ones. Please contact customer support if you are having any
                issues and need help.
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="unique-component-id-select">Component</label>
              <select
                id="unique-component-id-select"
                className="form-control"
                defaultValue={data.uniqueComponentId ?? 'placeholder'}
                onChange={(e) => setUniqueComponentId(e.target.value)}
              >
                <option disabled value="placeholder">
                  Select a component
                </option>
                {fetchComponentOptions(templateType).map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
                <option value="custom">Custom</option>
              </select>
            </div>
            {state?.uniqueComponentId === 'custom' && (
              // @ts-expect-error JS props
              <LabeledInput
                controlled={false}
                item={state}
                itemName="customComponentId"
                label="Custom Component"
                placeholder="Enter the custom component ID"
                customOnChange={setCustomComponentId}
              />
            )}
            {isAdmin && state?.uniqueComponentId === 'custom' && (
              <FormGroup>
                {/* @ts-expect-error JS props */}
                <InputLabel label="Custom Component Props" itemName="customComponentProps" />
                <JSONEditorReact
                  id="customComponentProps"
                  // Updating Mode causes an error within vanilla-jsoneditor
                  mainMenuBar={false}
                  mode={Mode.text}
                  content={json}
                  onChange={setJson}
                  editorApi={editorApi}
                />
              </FormGroup>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

class CustomComponentTool {
  private config: DefaultConfigProps & { isAdmin: boolean }
  private editorApi: API
  private blockAPI: BlockAPI
  private data: CustomComponentDataProps
  private CSS: {
    wrapper: string
  }
  private nodes: NodesProps

  static get toolbox(): ToolboxProps {
    return {
      title: 'Custom Component',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-alert">
          <circle cx="12" cy="12" r="10"/><line x1="12" x2="12" y1="8" y2="12"/>
          <line x1="12" x2="12.01" y1="16" y2="16"/>
        </svg>`,
    }
  }

  constructor({ data, config, api, block }) {
    this.config = config
    this.editorApi = api
    this.blockAPI = block

    let defaultData = {
      uniqueComponentId: undefined,
      customComponentId: undefined,
      // existing custom components will be undefined
      customComponentProps: undefined,
    }

    this.data = Object.keys(data).length ? data : defaultData

    this.CSS = {
      wrapper: 'custom-component-tool',
    }

    this.nodes = {
      holder: null,
    }
  }

  render() {
    const rootNode = document.createElement('div')
    rootNode.setAttribute('class', this.CSS.wrapper)
    this.nodes.holder = rootNode

    const onDataChange = (newData: object) => {
      this.data = {
        ...this.data,
        ...newData,
      }
      this.config.save()
      // Force editor onChange event
      this.blockAPI.dispatchChange()
    }

    const root = createRoot(rootNode)
    root.render(
      <RenderedFormComponent
        onDataChange={onDataChange}
        data={this.data}
        templateType={this.config.templateType}
        isAdmin={this.config.isAdmin}
        editorApi={this.editorApi}
      />
    )

    return this.nodes.holder
  }

  save() {
    return this.data
  }
}

export default CustomComponentTool
