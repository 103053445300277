export default class ScreensizeTune {
  static get isTune() {
    return true
  }

  constructor({ api, data, config, block }) {
    this.api = api
    this.block = block
    this.settings = config
    this.data = data || { screenSize: 'all' }
  }

  render() {
    const wrapper = document.createElement('div')
    wrapper.classList.add('form-group')
    wrapper.classList.add('mb-0')

    const input = document.createElement('select')

    input.setAttribute('id', 'code-input')
    input.setAttribute('placeholder', 'Code...')
    input.defaultValue = this.data.className
    input.className = 'my-1 form-control form-control-sm'

    const allOption = document.createElement('option')
    allOption.value = 'all'
    allOption.text = 'All Screens'

    const mobileOption = document.createElement('option')
    mobileOption.value = 'mobile'
    mobileOption.text = 'Mobile Only'

    const mobileTabletOption = document.createElement('option')
    mobileTabletOption.value = 'mobile-tablet'
    mobileTabletOption.text = 'Mobile & Tablets'

    const desktopOption = document.createElement('option')
    desktopOption.value = 'desktop'
    desktopOption.text = 'Desktop Only'

    input.appendChild(allOption)
    input.appendChild(mobileOption)
    input.appendChild(mobileTabletOption)
    input.appendChild(desktopOption)

    input.value = this.data.screenSize

    input.addEventListener('change', (e) => {
      const target = e.target
      this.data.screenSize = target.value
      this.settings.save()
      // Force trigger Block's onChange event
      this.block.dispatchChange()
    })

    wrapper.appendChild(input)

    return wrapper
  }

  save() {
    return this.data
  }
}
